const lgUp = "screen and (min-width: 1024px)";
export { lgUp };

const lgDown = "screen and (max-width: 1023.98px)";
export { lgDown };

const mdUp = "screen and (min-width: 768px)";
export { mdUp };

const mdDown = "screen and (max-width: 767.98px)";
export { mdDown };
