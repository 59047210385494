import React, { useEffect } from "react";
import useLayout from "../../contexts/layout/useLayout";

function OrdersPage({ header, footer, children }) {
    const { headerSet, footerSet } = useLayout();

    useEffect(
        () => headerSet(header),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [header]
    );
    useEffect(
        () => footerSet(footer),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [footer]
    );

    return (
        <div>
            <h1>Tus Pedidos</h1>
        </div>
    );
}

export default OrdersPage;
