import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import CurrencySelector from "../../components/concret/CurrencySelector/CurrencySelector";
import LanguageSelector from "../../components/concret/LanguageSelector/LanguageSelector";

import "./Navbar.scss";
import "./Header.scss";
import "./MainNavOffcanvas.scss";
import useShoppingCart from "../../contexts/shoppingCart/useShoppingCart";
import Button from "../../components/abstract/Button/Button";
import Counter from "../../components/abstract/Counter/Counter";
import { Link } from "react-router-dom";
import { IconMenu, IconSearch, IconShoppingCart, IconChevronDown, IconChevronUp } from "../../components/abstract/icons";
import Offcanvas from "../../components/abstract/Offcanvas/Offcanvas";
import HeaderMegaMenu from "../../components/concret/HeaderMegaMenu/HeaderMegaMenu";
import AccountSelector from "../../components/concret/AccountSelector/AccountSelector";
import SlideDown from "../../components/abstract/SlideDown/SlideDown";
import useClickOutside from "../../contexts/clickOutside/useClickOutside";
import useVirtualPageHandler from "../../contexts/virtualPageHandler/useVirtualPageHandler";
import LazyImg from "../../components/abstract/LazyImg/LazyImg";
import classNames from "classnames";
import useMainSearch from "../../contexts/mainSearch/useMainSearch";
import { getAssetUrl } from "../../helpers/misc";
import useLocalization from "../../contexts/localization/useLocalization";
import CardLathe from "../../components/abstract/CardLathe/CardLathe";

function Header({ langNav, homeUrl, myAccountUrl, megaMenu, adminUrl, categoriesNav = {} }) {
    const { currentLanguage } = useLocalization();

    return (
        <LocalizedHeader
            langNav={langNav}
            homeUrl={homeUrl}
            myAccountUrl={myAccountUrl}
            megaMenu={megaMenu}
            adminUrl={adminUrl}
            categoriesNav={categoriesNav}
            currentLanguage={currentLanguage}
        />
    );
}

function LocalizedHeader({ langNav, homeUrl, myAccountUrl, megaMenu, adminUrl, categoriesNav = {} }) {
    const searchWrapperRef = useRef(null);
    const [offcanvasShow, setOffcanvasShow] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const [mobileNavActiveCat, setMobileNavActiveCat] = useState(null);
    const toggleNavOffCanvas = () => {
        setOffcanvasShow((offcanvasShow) => !offcanvasShow);
    };

    const { toggleModal: toggleSearchModal, hideModal: searchModalHide } = useMainSearch();

    const { toggleCartOffCanvas, numItems, offCanvasOpen } = useShoppingCart();
    const [backdropStatus, backdropStatusSet] = useState("closed");

    const openBackdrop = () => {
        backdropStatusSet("opening");
        setTimeout(() => backdropStatusSet("open"), 10);
    };

    const closeBackdrop = () => {
        backdropStatusSet("closing");
        setTimeout(() => backdropStatusSet("closed"), 300);
    };

    useClickOutside(searchWrapperRef, () => setSearchActive(false));
    const { pageData } = useVirtualPageHandler();

    useEffect(() => {
        closeBackdrop();
        setSearchActive(false);
        setOffcanvasShow(false);
        searchModalHide();
        setMobileNavActiveCat(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageData]);

    useEffect(() => {
        if (offcanvasShow && offCanvasOpen) {
            setOffcanvasShow(false);
        }
    }, [offCanvasOpen]);

    const getImageProps = (sizes, alt) => ({ ...sizes.find((s) => s.name === "thumbnail__3x"), alt });

    return (
        <>
            <header className="header">
                {"closed" !== backdropStatus && <div className={["header-backdrop fade", ...("open" === backdropStatus ? ["show"] : [])].join(" ")}></div>}
                <div className="header-topbar">
                    <div className="container h-100 d-flex">
                        <div className="ms-auto d-flex align-items-center gap-3">
                            {adminUrl && (
                                <Button size="small" variant="ghost" href={adminUrl} target="_blank">
                                    Editar en CMS
                                </Button>
                            )}
                            {langNav && <LanguageSelector selectedLanguage={langNav.current} items={langNav.items} />}
                            <CurrencySelector />
                        </div>
                    </div>
                </div>
                <div className="header-main">
                    <div className="container">
                        {/* <Button onClick={toggleNavOffCanvas} className="btn-menu-toggle bg-transparent border-0 flex-column" iconBefore={<IconMenu />}></Button> */}

                        <Button
                            onClick={toggleNavOffCanvas}
                            className={["btn-menu-toggle bg-transparent btn--icon-only border-0 flex-column", ...(offcanvasShow ? ["is-open"] : [])].join(" ")}
                        >
                            <div className="icon-wrapper">
                                <span className="line"></span>
                                <span className="line"></span>
                                <span className="line"></span>
                            </div>
                        </Button>

                        <Button
                            className="btn-search-toggle bg-transparent border-0 flex-column"
                            iconBefore={<IconSearch />}
                            onClick={toggleSearchModal}
                        ></Button>

                        <Link to={homeUrl} className="header-logo">
                            <img src={getAssetUrl("/assets/img/logos/logo-cmz-store-w.svg")} className="header-logo-img" alt="Logo CMZ Store" width="124" />
                        </Link>

                        <HeaderMegaMenu items={megaMenu} openBackdrop={openBackdrop} closeBackdrop={closeBackdrop} />

                        <div className="header-main__nav ms-auto">
                            {!searchActive && (
                                <>
                                    {/* <Button
                                        className="btn-search-toggle bg-transparent border-0 flex-column"
                                        iconBefore={<IconSearch />}
                                        onClick={() => setSearchActive(true)}
                                    ></Button> */}
                                    <Button
                                        className="btn-search me-3"
                                        variant="outline"
                                        color="white"
                                        iconBefore={<IconSearch />}
                                        //onClick={() => setSearchActive(true)}
                                        onClick={toggleSearchModal}
                                    >
                                        <span className="label">{t("searchButtonLabel", "Buscar")}</span>
                                    </Button>
                                </>
                            )}

                            <AccountSelector myAccountUrl={myAccountUrl} />

                            <Button onClick={toggleCartOffCanvas} className="btn-shopping-cart bg-transparent border-0 flex-column">
                                <div className="cart-icon-wrapper">
                                    <Counter color="white" className="">
                                        {numItems}
                                    </Counter>
                                    <IconShoppingCart />
                                </div>
                                <span className="btn-label fs-xs opacity-50">{t("myCart", "Mi carrito")}</span>
                            </Button>
                        </div>
                    </div>
                </div>
            </header>

            <Offcanvas position="start" className="offcanvas-main-nav" show={offcanvasShow} onHide={toggleNavOffCanvas}>
                <Offcanvas.Body className="p-0">
                    <div className="d-flex gap-3 bg-gray-100 py-2 px-3">
                        <LanguageSelector selectedLanguage={langNav.current} items={langNav.items} />
                        <CurrencySelector />
                    </div>

                    <div className="sliding-panels">
                        <div className="sliding-panel sliding-panel--visible">
                            <nav className="offcanvas-navbar">
                                {(categoriesNav[1] && categoriesNav[1].children && Object.keys(categoriesNav[1].children).length && (
                                    <ul className="offcanvas-navbar-nav">
                                        {Object.keys(categoriesNav[1].children).map(
                                            (catIndex, cIndex) =>
                                                (Object.keys(categoriesNav[1].children[catIndex].children).length && (
                                                    <li
                                                        className={classNames(
                                                            "nav-item has-children",
                                                            categoriesNav[1].children[catIndex].active ? "nav-item-active" : null
                                                        )}
                                                        key={`offcanvas-cat--${cIndex}`}
                                                    >
                                                        <>
                                                            <Button
                                                                variant="link"
                                                                className="nav-link"
                                                                ripple={false}
                                                                onClick={() =>
                                                                    setMobileNavActiveCat(
                                                                        mobileNavActiveCat !== categoriesNav[1].children[catIndex].trId
                                                                            ? categoriesNav[1].children[catIndex].trId
                                                                            : null
                                                                    )
                                                                }
                                                            >
                                                                {categoriesNav[1].children[catIndex].image && (
                                                                    <LazyImg
                                                                        className="img-fluid"
                                                                        style={{ width: "40px" }}
                                                                        {...getImageProps(
                                                                            categoriesNav[1].children[catIndex].image.sizes,
                                                                            categoriesNav[1].children[catIndex].image.alt || ""
                                                                        )}
                                                                    />
                                                                )}
                                                                {categoriesNav[1].children[catIndex].name}
                                                                {(mobileNavActiveCat !== categoriesNav[1].children[catIndex].trId && (
                                                                    <IconChevronDown className="ms-auto flex-shrink-0" />
                                                                )) || <IconChevronUp className="ms-auto flex-shrink-0" />}
                                                            </Button>
                                                            <SlideDown closed={mobileNavActiveCat !== categoriesNav[1].children[catIndex].trId}>
                                                                <ul className="offcanvas-navbar-subnav">
                                                                    {Object.keys(categoriesNav[1].children[catIndex].children).map(
                                                                        (childCatIndex, childCIndex) => (
                                                                            <li
                                                                                className={classNames(
                                                                                    "nav-item",
                                                                                    categoriesNav[1].children[catIndex].children[childCatIndex].active
                                                                                        ? "nav-item-active"
                                                                                        : null
                                                                                )}
                                                                                key={`offcanvas-cat--${childCIndex}`}
                                                                            >
                                                                                <Link
                                                                                    className="nav-link"
                                                                                    to={categoriesNav[1].children[catIndex].children[childCatIndex].url}
                                                                                >
                                                                                    {categoriesNav[1].children[catIndex].children[childCatIndex].name}
                                                                                </Link>
                                                                            </li>
                                                                        )
                                                                    )}
                                                                </ul>
                                                            </SlideDown>
                                                        </>
                                                    </li>
                                                )) || (
                                                    <li
                                                        className={classNames(
                                                            "nav-item",
                                                            categoriesNav[1].children[catIndex].active ? "nav-item-active" : null
                                                        )}
                                                        key={`offcanvas-cat--${cIndex}`}
                                                    >
                                                        <Link className="nav-link" to={categoriesNav[1].children[catIndex].url}>
                                                            {/* Image thumbnail */}

                                                            {/* {categoriesNav[1].children[catIndex].image && (
                                                                <LazyImg
                                                                    className="img-fluid"
                                                                    style={{ width: "40px" }}
                                                                    {...getImageProps(
                                                                        categoriesNav[1].children[catIndex].image.sizes,
                                                                        categoriesNav[1].children[catIndex].image.alt || ""
                                                                    )}
                                                                />
                                                            )} */}
                                                            {categoriesNav[1].children[catIndex].name}
                                                        </Link>
                                                    </li>
                                                )
                                        )}
                                    </ul>
                                )) ||
                                    ""}
                                {/* {(megaMenu && megaMenu[0] && megaMenu[0].machines && megaMenu[0].machines.length && (
                                    <div className="bg-gray-800">
                                        <p className="h6 text-muted px-3 pt-4">{t("searchByLatheSeries", "Busca por tipo de torno")}</p>
                                        <ul className="offcanvas-navbar-nav offcanvas-navbar-nav--dark">
                                            {megaMenu[0].machines.map((mac, macIndex) => (
                                                <li className="nav-item" key={`mac-${macIndex}`}>
                                                    <Link className="nav-link" to={mac.url}>
                                                        {mac.title}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )) ||
                                    ""} */}

                                {(megaMenu && megaMenu[0] && megaMenu[0].machines && megaMenu[0].machines.length && (
                                    <div className="offcanvas-lathes-list border-top bg-gray-100 pb-3">
                                        <p className="fw-medium px-3 pt-4 pb-0 mb-0">{t("searchByLatheSeries", "Busca por tipo de torno")}</p>
                                        <div className="p-3 row row-cols-2 row-cols-sm-3 row-cols-md-4 g-2">
                                            {megaMenu[0].machines.map((mac, macIndex) => (
                                                <div className="col" key={`mac-${macIndex}`}>
                                                    <CardLathe {...mac} />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )) ||
                                    ""}
                            </nav>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default Header;
