import client from "./clients/app";

const get = async (uri, params, options) => {
    return client.get(uri, params, options);
};

const post = async (uri, params, options) => {
    return client.post(uri, params, options);
};

export { get, post };
