import { useQuery } from "react-query";
import { getProductStock } from "../../../api/products";

const useProductStock = (sku) => {
    return useQuery({
        queryKey: ["product-stock", sku],
        queryFn: () => getProductStock(sku).then((response) => response.data),
    });
};

export default useProductStock;
