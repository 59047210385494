import React, { useState } from "react";
import PropTypes from "prop-types";
import AnimateHeight from "react-animate-height";
import classNames from "classnames";
import "./Accordion.scss";

function Accordion(props) {
    const { icon, title, children, className, buttonClassName, bodyClassName, closed = false } = props;
    const [isClosed, setIsClosed] = useState(closed ? true : false);

    return (
        <div className={classNames("accordion", className)}>
            <h2 className="accordion-header">
                <button
                    onClick={() => setIsClosed(!isClosed)}
                    type="button"
                    // className={["accordion-button", ...(buttonClassName.length ? [buttonClassName] : []), ...(isClosed ? ["is-collapsed"] : [])].join(" ")}
                    className={classNames("accordion-button", buttonClassName, isClosed ? "is-collapsed" : null)}
                >
                    <div className="accordion-title">
                        {icon && <span className="icon opacity-50">{icon}</span>}
                        {title && <span className="title mb-0">{title}</span>}
                    </div>
                </button>
            </h2>
            <AnimateHeight className="" duration={300} height={isClosed ? 0 : "auto"}>
                <div className={classNames("accordion-body", bodyClassName)}>{children}</div>
            </AnimateHeight>
        </div>
    );
}

Accordion.propTypes = {
    icon: PropTypes.node,
    title: PropTypes.string,
    children: PropTypes.node,
    closed: PropTypes.bool,
};

export default Accordion;
