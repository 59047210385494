import React from "react";
import BaseIconSVG from "./BaseIconSVG";

function IconShoppingCartAdd(props) {
    return (
        <BaseIconSVG
            Icon={({ ...otherProps }) => (
                <svg {...otherProps}>
                    <path d="m15.8096 21.13c0-1.04.84-1.88 1.88-1.88s1.88.84 1.88 1.88-.84 1.88-1.87 1.88c-1.04 0-1.88-.84-1.88-1.88z" />
                    <path d="m5.97949 21.13c0-1.04.84-1.88 1.88-1.88s1.88.84 1.88 1.88-.84 1.88-1.88 1.88c-1.03 0-1.87-.84-1.87-1.88z" />
                    <path
                        clipRule="evenodd"
                        d="m19 2c.5523 0 1 .44772 1 1v3h3c.5523 0 1 .44772 1 1s-.4477 1-1 1h-3v3c0 .5523-.4477 1-1 1s-1-.4477-1-1v-3h-3c-.5523 0-1-.44772-1-1s.4477-1 1-1h3v-3c0-.55228.4477-1 1-1z"
                        fillRule="evenodd"
                    />
                    <path d="m11.0034 6.25h-5.7734l-.85-2.59c-.13-.39-.49-.65-.9-.66h-2.53c-.53 0-.95.43-.95.95s.43.95.95.95h1.84l.85 2.59 3.17 9.62c.13.39.49.65.9.65h10.37c.44 0 .83-.3.93-.73l.4739-2.0445c-.6497-.0368-1.281-.1424-1.8866-.3098l-.2773 1.1943h-8.93l-2.54-7.71h5.4109c-.1513-.61427-.2396-1.25335-.2575-1.91z" />
                </svg>
            )}
            {...props}
        />
    );
}

export default IconShoppingCartAdd;
