import React from "react";
import PropTypes from "prop-types";
import "./Skeleton.scss";

function Skeleton(props) {
    const {
        variant = "text",
        animation = "pulse",
        maxWidth = null,
        width = null,
        height = null,
        isOnDarkBackground = false,
        className = null,
        children,
    } = props;

    return (
        <div
            className={[
                "skeleton",
                ...(variant === "text" ? [""] : [""]),
                ...(variant === "circular" ? ["skeleton--circular"] : [""]),
                ...(variant === "square" ? ["skeleton--square"] : [""]),
                ...(variant === "rectangular" ? ["skeleton--rectangular"] : [""]),
                ...(animation === "pulse" ? [""] : [""]),
                ...(animation === "wave" ? ["skeleton--wave"] : [""]),
                ...(isOnDarkBackground ? ["skeleton--white"] : [""]),
                ...(className ? [className.trim()] : [""]),
            ].join(" ")}
            style={{ height: height, width: width, maxWidth: maxWidth }}
        >
            {children}
        </div>
    );
}

Skeleton.propTypes = {
    variant: PropTypes.oneOf(["text", "circular", "square", "rectangular"]),
    animation: PropTypes.oneOf(["pulse", "wave"]),
    isOnDarkBackground: PropTypes.bool,
    width: PropTypes.string,
    maxWidth: PropTypes.string,
    height: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
};

export default Skeleton;
