import React from "react";
import PropTypes from "prop-types";

import "./BaseIconSVG.scss";

function BaseIconSVG(props) {
    const { Icon, label, className, size, color, children, ...otherProps } = props;

    const getClassName = () => [
        "icon-svg",
        ...(size === "small" ? ["icon-svg--sm"] : []),
        ...(size === "large" ? ["icon-svg--lg"] : []),
        ...(className ? [className.trim()] : [])
    ].join(" ");

    const childrenWithProps = React.Children.map(children, child => {
        // Checking isValidElement is the safe way and avoids a typescript
        // error too.
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {
                enableBackground: "new 0 0 24 24", height: "24", viewBox: "0 0 24 24", width: "24", xmlns: "http://www.w3.org/2000/svg", className: getClassName(), "aria-label": label, ...otherProps
            });
        }
        return child;
    });

    return (
        (
            (Icon && (
                <Icon
                    enableBackground="new 0 0 24 24"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                    className={getClassName()}
                    aria-label={label}
                    {...otherProps}
                />
            )) || childrenWithProps
        )
    );
}

BaseIconSVG.propTypes = {
    size: PropTypes.oneOf(["small", "medium", "large"]),
    label: PropTypes.string,
    className: PropTypes.string,
}

export default BaseIconSVG;
