import React from "react";
import BaseIconSVG from "./BaseIconSVG";

function IconMinus(props) {
    return (
        <BaseIconSVG
            Icon={({ ...otherProps }) => (
                <svg {...otherProps}>
                    <path d="m0 0h24v24h-24z" fill="none" />
                    <path d="m18 13h-12c-.55 0-1-.45-1-1s.45-1 1-1h12c.55 0 1 .45 1 1s-.45 1-1 1z" />
                </svg>
            )}
            {...props}
        />
    );
}

export default IconMinus;
