import React, { useEffect, useState, useRef } from "react";
import { CancelToken } from "apisauce";
import * as urlsApi from "./api/urls";
import SplashScreen from "./components/abstract/SplashScreen/SplashScreen";

// import NewsArchiveLayout from "./layouts/news/NewsArchiveLayout";
// import PagesHome from "./pages/Home";
// import NewsSingle from "./content/news/NewsSingle";
//import NotFound from "./pages/NotFound";
import AppVirtualPage from "./AppVirtualPage";
import LoadingBar from "./components/abstract/LoadingBar/LoadingBar";
import { Navigate } from "react-router-dom";
import VirtualPageHandlerContext from "./contexts/virtualPageHandler/context";
import useAnalytics from "./contexts/analytics/useAnalytics";

export default function AppVirtualPageHandler({ path }) {
    const [isLoading, setIsLoading] = useState(false);
    const [redirectTo, setRedirectTo] = useState(null);
    const [pageData, setPageData] = useState(window["_hydratedComponent"] ? window["_hydratedComponent"] : null);
    const [scrollToTopOnPageLoad, setScrollToTopOnPageLoad] = useState(true);
    const loadingBarRef = useRef(null);
    const cancelTokenSource = useRef(null);
    const { init: initAnalytics, trackPageView } = useAnalytics();
    const [analyticsURI, setAnalyticsURI] = useState(null);

    useEffect(() => {
        initAnalytics();
        if (!cancelTokenSource.current) {
            cancelTokenSource.current = CancelToken.source();
        }
        return () => {
            if (cancelTokenSource.current) {
                cancelTokenSource.current.cancel();
            }
        };
    }, []);

    useEffect(() => {
        if (document.getElementById("root").getAttribute("data-hydrated")) {
            document.getElementById("root").removeAttribute("data-hydrated");
            if (window["_hydratedComponent"]) {
                setPageData(window["_hydratedComponent"]);
                window["_hydratedComponent"] = null;
            }
            return;
        }
        const refreshPageData = async () => {
            setIsLoading(true);
            setRedirectTo(null);
            loadingBarRef.current.reset();
            loadingBarRef.current.continuousStart(0, 300);
            cancelTokenSource.current.cancel();
            cancelTokenSource.current = CancelToken.source();
            const result = await urlsApi.get(
                path,
                {},
                {
                    cancelToken: cancelTokenSource.current.token,
                }
            );
            // console.log(result);
            if (result.ok) {
                if (result.data && result.data.redirect) {
                    setRedirectTo(result.data.redirect);
                } else {
                    setPageData(result.data);
                    if (scrollToTopOnPageLoad) {
                        // document.querySelector("#root").scrollTo(0, 0);
                        document.querySelector("html").scrollTo(0, 0);
                    } else {
                        setScrollToTopOnPageLoad(true);
                    }
                }
            } else if (result.problem && "CANCEL_ERROR" === result.problem) {
                return;
            } else {
                // setPageData(404);
                setPageData(result.data);
                if (scrollToTopOnPageLoad) {
                    // document.querySelector("#root").scrollTo(0, 0);
                    document.querySelector("html").scrollTo(0, 0);
                } else {
                    setScrollToTopOnPageLoad(true);
                }
            }
            loadingBarRef.current.complete();
            setIsLoading(false);
        };
        refreshPageData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path]);

    useEffect(() => {
        const observer = new MutationObserver(function (mutations) {
            if (document.querySelector("meta[property='wb:uri']")) {
                setAnalyticsURI(document.querySelector("meta[property='wb:uri']").getAttribute("content"));
            }
        });
        observer.observe(document.querySelector("head"), { childList: true });
        return () => {
            observer.disconnect();
        };
    }, []);

    useEffect(() => {
        if (analyticsURI) {
            trackPageView();
        }
    }, [analyticsURI]);

    return (
        <VirtualPageHandlerContext.Provider value={{ setScrollToTopOnPageLoad, pageData }}>
            <LoadingBar containerClassName="app-loading-bar" shadowClassName="app-loading-bar--shadow" ref={loadingBarRef} color="#003da5" waitingTime={300} />
            {(isLoading && 404 !== pageData && (!pageData || !pageData.components) && <SplashScreen />) ||
                (redirectTo && <Navigate to={redirectTo} />) ||
                (pageData && pageData.components && <AppVirtualPage components={pageData.components} helmet={pageData.helmet} />) || (
                    <AppVirtualPage notFound={true} />
                )}
        </VirtualPageHandlerContext.Provider>
    );
}
