import { useContext } from "react";
import MainSearchContext from "./context";

const useMainSearch = () => {
    const { modalOpen, modalOpenSet, machines, machinesSet } = useContext(MainSearchContext);

    const toggleModal = () => modalOpenSet(!modalOpen);
    const hideModal = () => modalOpenSet(false);
    const showModal = () => modalOpenSet(true);

    return {
        modalOpen,
        modalOpenSet,
        machines,
        machinesSet,
        toggleModal,
        hideModal,
        showModal,
    };
};

export default useMainSearch;
