import React, { useContext } from "react";
import ContentListContext from "../../context";
import Button from "../../../../abstract/Button/Button";
import { IconArrowBack, IconArrowForward } from "../../../../abstract/icons";

function ContentListPaginationServerSide() {
    const { paginationState } = useContext(ContentListContext);

    return (
        ((paginationState.nextPageLink || paginationState.previousPageLink) && paginationState.totalCount && paginationState.itemsPerPage && (
            <div className="mt-10">
                <hr />
                <div className="d-flex justify-content-center align-items-center">
                    <Button
                        iconBefore={<IconArrowBack />}
                        to={paginationState.previousPageLink ? paginationState.previousPageLink : null}
                        isDisabled={!paginationState.previousPageLink}
                    ></Button>
                    <div className="px-3">
                        Página {paginationState.page} / {Math.ceil(paginationState.totalCount / paginationState.itemsPerPage)}{" "}
                    </div>
                    <Button
                        iconAfter={<IconArrowForward />}
                        to={paginationState.nextPageLink ? paginationState.nextPageLink : null}
                        isDisabled={!paginationState.nextPageLink}
                    ></Button>
                </div>
            </div>
        )) ||
        ""
    );
}

export default ContentListPaginationServerSide;
