import React from "react";
import { t } from "i18next";
import classNames from "classnames";
import LazyImg from "../../abstract/LazyImg/LazyImg";
import CurrencyFormat from "../CurrencyFormat/CurrencyFormat";

import "./CartLineLite.scss";

function CartLineLite({ product, regularTaxBase = null, taxBase = null, quantity, currency = null }) {
    const getImageProps = (sizes, alt) => ({ ...sizes.find((s) => s.name === "thumbnail__2x"), alt });

    return (
        <div className="cart-line cart-line--lite">
            <div className={classNames("cart-line__img", product.hasOldMainImage ? "cart-line-old-main-image" : null)}>
                {product.image && <LazyImg className="img-fluid" {...getImageProps(product.image.sizes, product.image.alt || "")} />}
            </div>
            <div className="cart-line__info">
                <span className="cart-line-title">{product.title}</span>
                <span className="cart-line-sku">{product.sku}</span>
                <div className="d-flex align-items-center justify-content-between">
                    <span className="cart-line-quantity">
                        <span className="text-muted">{t("quantityPeriod", "Cantidad:")}</span> {quantity}
                    </span>
                    {(taxBase !== null && (
                        <span className="cart-line-price">
                            {(regularTaxBase !== taxBase && (
                                <>
                                    <del>
                                        <CurrencyFormat value={regularTaxBase} currency={currency} />
                                    </del>
                                    <ins>
                                        <CurrencyFormat value={taxBase} currency={currency} />
                                    </ins>
                                </>
                            )) || <CurrencyFormat value={taxBase} />}
                            {/* <CurrencyFormat value={taxBase} currency={currency} /> */}
                        </span>
                    )) ||
                        ""}
                </div>
            </div>
        </div>
    );
}

export default CartLineLite;
