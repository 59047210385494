import React, { useContext, useEffect, useMemo, useState } from "react";
import { t } from "i18next";
import { Trans } from "react-i18next";
import debounce from "lodash.debounce";
import useShoppingCart from "../../../../../contexts/shoppingCart/useShoppingCart";
import * as api from "../../../../../api/shippingServices";
import Button from "../../../../abstract/Button/Button";
import Modal from "../../../../abstract/Modal/Modal";
import FormRadio from "../../../../forms/FormRadio/FormRadio";
import CartLineLite from "../../../CartLineLite/CartLineLite";
import DateFormat from "../../../DateFormat/DateFormat";
import "./DeliveryPortlet.scss";
import CurrencyFormat from "../../../CurrencyFormat/CurrencyFormat";
import SlideDown from "../../../../abstract/SlideDown/SlideDown";
import FormCheckbox from "../../../../forms/FormCheckbox/FormCheckbox";
import CheckoutPortletContext from "../../context";
import Skeleton from "../../../../abstract/Skeleton/Skeleton";
import Alert from "../../../../abstract/Alert/Alert";
import Accordion from "../../../../abstract/Accordion/Accordion";
import { IconPackage } from "../../../../abstract/icons";

function DeliveryPortlet({ mode = "edit" }) {
    return mode === "view" ? <DeliveryPortletView /> : <DeliveryPortletEdit />;
}

function DeliveryPortletView() {
    const { isPartialShippingAllowed, getShippingTaxBase, getShippingService, getCustomOrderNumber, getShippingComments } = useShoppingCart();

    return (
        <p>
            {isPartialShippingAllowed() && (
                <>
                    {t("partialShippmentEnabled", "Envío Parcial Habilitado")}
                    <br />
                </>
            )}
            {getShippingService() && (
                <>
                    <strong>{getShippingService().name}:</strong> <CurrencyFormat value={getShippingTaxBase()} />
                    <br />
                </>
            )}
            {getCustomOrderNumber() && (
                <>
                    <strong>{t("customOrderNumberPeriod", "Número de Pedido Personalizado:")}</strong> {getCustomOrderNumber()}
                    <br />
                </>
            )}
            {getShippingComments() && (
                <>
                    <strong> {t("orderCommentsPeriod", "Observaciones:")} </strong>
                    <br />
                    {getShippingComments()}
                </>
            )}
        </p>
    );
}

function DeliveryPortletEdit() {
    const {
        enablePartialShipping,
        disablePartialShipping,
        getShippingService,
        setShippingService,
        getShippingComments,
        updateShippingComments,
        getCustomOrderNumber,
        updateCustomOrderNumber,
        isPartialShippingAllowed,
        isPartialShippingCandidate,
        getShippingPackages,
        getShippingTaxBase,
        getNumShippingPackages,
    } = useShoppingCart();

    const { setIsCorrect } = useContext(CheckoutPortletContext);

    const [isLoading, setIsLoading] = useState(true);
    const [shippingPackageModalOpen, setShippingPackageModalOpen] = useState(null);

    // const [isSelecting, setIsSelecting] = useState(false);
    const [shippingServices, setShippingServices] = useState([]);

    const [shippingComments, setShippingComments] = useState(getShippingComments());
    const [customOrderNumber, setCustomOrderNumber] = useState(getCustomOrderNumber());

    const loadShippingServices = async () => {
        setIsLoading(true);
        setIsCorrect(false);
        const response = await api.getAll();
        if (response.ok && response.data && response.data.length) {
            let newShippingServices = [];
            for (let add of response.data) {
                newShippingServices.push(add);
            }
            setShippingServices(newShippingServices);
        }
        setIsLoading(false);
    };

    const autoSelectShippingServices = async () => {
        if (!shippingServices || !shippingServices.length) {
            return;
        }

        const firstShippingService = shippingServices[0];
        if (firstShippingService) {
            updateShippingService(firstShippingService);
        }
        setIsCorrect(true);
    };

    const updateShippingService = async (service, skipIfNotEmpty = true) => {
        // setIsSelecting(true);
        if (!skipIfNotEmpty || !getShippingService()) {
            await setShippingService(service.slug);
        }
        // setIsSelecting(false);
    };

    const saveShippingComments = async (val) => {
        //setIsLoading(true);
        await updateShippingComments(val);
        //setIsLoading(false);
    };

    const saveCustomOrderNumber = async (val) => {
        //setIsLoading(true);
        await updateCustomOrderNumber(val);
        //setIsLoading(false);
    };

    const togglePartialShipping = async () => {
        if (isLoading) {
            return;
        }
        //setIsLoading(true);
        if (isPartialShippingAllowed()) {
            // const success = await disablePartialShipping();
            await disablePartialShipping();
        } else {
            // const success = await enablePartialShipping();
            await enablePartialShipping();
        }
        //setIsLoading(false);
    };

    const debouncedShippingComments = useMemo(() => {
        return debounce(saveShippingComments, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const debouncedCustomOrderNumber = useMemo(() => {
        return debounce(saveCustomOrderNumber, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        autoSelectShippingServices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shippingServices]);

    useEffect(() => {
        loadShippingServices();
        setShippingComments(getShippingComments());
        setCustomOrderNumber(getCustomOrderNumber());
        return () => {
            debouncedShippingComments.cancel();
            debouncedCustomOrderNumber.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        debouncedShippingComments(shippingComments);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shippingComments]);

    useEffect(() => {
        debouncedCustomOrderNumber(customOrderNumber);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customOrderNumber]);

    return (
        <div>
            {(isPartialShippingCandidate() && (
                <div className="partial-shipping-info">
                    <Alert color="warning">
                        <p className="fs-sm">
                            <Trans i18nKey="partialShippingDisclaimerText">
                                Hay productos en tu carrito de la compra que son susceptibles de ser enviados antes que otros. Si seleccionas{" "}
                                <strong>envío parcial</strong> se realizará un primer envío con la mercancía disponible y un segundo envío cuando el resto de
                                productos entren en stock. Ten en cuenta que de esta forma el coste del envío será mayor.
                            </Trans>
                        </p>
                        <FormCheckbox
                            className="fw-medium"
                            style={{ cursor: "pointer" }}
                            label={t("enablePartialShipping", "Habilitar envío parcial")}
                            checked={isPartialShippingAllowed()}
                            onClick={togglePartialShipping}
                        />
                    </Alert>
                </div>
            )) ||
                ""}
            <div className="shipping-packages d-flex flex-column mb-4">
                {getShippingPackages().map((pack, packIndex) => (
                    <React.Fragment key={`package-${packIndex}`}>
                        {(isPartialShippingCandidate() && isPartialShippingAllowed() && (
                            <Accordion
                                title={t("packageNumber", "Envío {{packageIndex}}/{{packagesCount}}", {
                                    packageIndex: packIndex + 1,
                                    packagesCount: getNumShippingPackages(),
                                })}
                                icon={<IconPackage />}
                                buttonClassName="ps-0"
                                className="shipping-package"
                                bodyClassName="pt-0"
                            >
                                {(pack.estimatedDeliveryDate && (
                                    <div className="estimated-delivery-date fs-sm gap-1 mb-3">
                                        {t("expectedDeliveryDate", "Fecha prevista de recepción:")}{" "}
                                        <strong>
                                            <DateFormat value={pack.estimatedDeliveryDate} />
                                        </strong>
                                    </div>
                                )) ||
                                    (pack.estimatedShippingDate && (
                                        <div className="estimated-shipping-date fs-sm gap-1 mb-3">
                                            {t("expectedShippingDate", "Fecha prevista de envío:")}{" "}
                                            <strong>
                                                <DateFormat value={pack.estimatedShippingDate} />
                                            </strong>
                                        </div>
                                    )) ||
                                    ""}
                                <div className="cart-list">
                                    {pack.lines.map((line, lineIndex) => (
                                        <CartLineLite {...line} taxBase={null} lineKey={line.key} key={`cart-line-${lineIndex}`} />
                                    ))}
                                </div>
                            </Accordion>
                        )) || (
                            <div className="my-3">
                                {(pack.estimatedDeliveryDate && (
                                    <div className="estimated-delivery-date fs-sm gap-1">
                                        {t("expectedDeliveryDate", "Fecha prevista de recepción:")}{" "}
                                        <strong>
                                            <DateFormat value={pack.estimatedDeliveryDate} />
                                        </strong>
                                    </div>
                                )) ||
                                    (pack.estimatedShippingDate && (
                                        <div className="estimated-shipping-date fs-sm gap-1">
                                            {t("expectedShippingDate", "Fecha prevista de envío:")}{" "}
                                            <strong>
                                                <DateFormat value={pack.estimatedShippingDate} />
                                            </strong>
                                        </div>
                                    )) ||
                                    ""}
                            </div>
                        )}

                        {/* <ShippingPackageModal
                            title={t("packageNumber", "Envío {{packageIndex}}/{{packagesCount}}", {
                                packageIndex: packIndex + 1,
                                packagesCount: getNumShippingPackages(),
                            })}
                            modalOpen={packIndex === shippingPackageModalOpen}
                            closeModal={() => setShippingPackageModalOpen(null)}
                        >
                            <div className="cart-list">
                                {pack.lines.map((line, lineIndex) => (
                                    <CartLineLite {...line} lineKey={line.key} key={`cart-line-${lineIndex}`} />
                                ))}
                            </div>
                        </ShippingPackageModal> */}
                    </React.Fragment>
                ))}
            </div>

            <div className="shipping-services mt-4 mb-6">
                {(shippingServices.length > 1 && (
                    <div className="mega-option__control">
                        <p>{t("selectShippingMethod", "Selecciona un método de envío.")}</p>
                    </div>
                )) ||
                    ""}
                <SlideDown>
                    {(isLoading && (
                        <ul className="list-unstyled">
                            <li>
                                <div className="mega-option is-selected">
                                    <div className="mega-option__control">
                                        <Skeleton variant="circular" height="1.25rem" width="1.25rem" className="mt-1" />
                                    </div>
                                    <label className="mega-option__label">
                                        <div className="mega-option__label__title">
                                            <Skeleton maxWidth="120px" height="1.875rem" />
                                            <span className="ms-auto">
                                                <Skeleton maxWidth="100px" />
                                            </span>
                                        </div>
                                        <div className="mega-option__description">
                                            <Skeleton height="0.75rem" />
                                        </div>
                                    </label>
                                </div>
                            </li>
                        </ul>
                    )) || (
                        <ul className="list-unstyled d-flex flex-column gap-3">
                            {shippingServices.map((service, serviceIndex) => (
                                <li key={`shipping-service-${serviceIndex}`}>
                                    <div className="mega-option is-selected">
                                        <div className="mega-option__control">
                                            <FormRadio />
                                        </div>
                                        <label className="mega-option__label">
                                            <div className="mega-option__label__title">
                                                {service.name}
                                                <span className="ms-auto">
                                                    <CurrencyFormat value={getShippingTaxBase()} />
                                                </span>
                                            </div>
                                            <div className="mega-option__description" dangerouslySetInnerHTML={{ __html: service.checkoutDescription }}></div>
                                        </label>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    )}
                </SlideDown>
            </div>

            <div className="form-group">
                <label htmlFor="test" className="form-label">
                    {t("customOrderNumber", "Número de Pedido Personalizado")} <span className="fw-normal">({t("optional", "opcional")})</span>
                </label>
                <input rows="3" className="form-control" autoComplete="off" onChange={(e) => setCustomOrderNumber(e.target.value)} value={customOrderNumber} />
                <div className="form-text text-muted">
                    <span className="help-text">{t("customOrderNumberPrompt", "Introduce un número de pedido personalizado.")}</span>
                </div>
            </div>

            <div className="form-group">
                <label htmlFor="test" className="form-label">
                    {t("orderComments", "Observaciones")} <span className="fw-normal">({t("optional", "opcional")})</span>
                </label>
                <textarea rows="3" className="form-control" autoComplete="off" onChange={(e) => setShippingComments(e.target.value)} value={shippingComments} />
                <div className="form-text text-muted">
                    <span className="help-text">
                        {t("orderCommentsPrompt", "¿Hay algún horario de entrega especial o algún otro detalle a tener en cuenta?")}
                    </span>
                </div>
            </div>
        </div>
    );
}

// function ShippingPackageModal({ modalOpen, closeModal, title, children }) {
//     return (
//         <Modal show={modalOpen} onHide={closeModal} className="modal-shopping-cart" size="medium" fullscreen="md-down">
//             <Modal.Header title={title} />
//             <Modal.Body>{children}</Modal.Body>
//             <Modal.Footer>
//                 <Button onClick={closeModal}>{t("close", "Cerrar")}</Button>
//             </Modal.Footer>
//         </Modal>
//     );
// }

export default DeliveryPortlet;
