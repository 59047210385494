import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import useMouseOut from "../../../contexts/mouseOut/useMouseOut";
import useVirtualPageHandler from "../../../contexts/virtualPageHandler/useVirtualPageHandler";
import CardLathe from "../../abstract/CardLathe/CardLathe";

function HeaderMegaMenuItem({ treeNode }) {
    const itemRef = useRef(null);
    const [showDropdown, setShowDropdown] = useState(false);

    const { pageData } = useVirtualPageHandler();

    const onMouseOver = () => {
        setShowDropdown(true);
    };

    const onMouseOut = () => {
        setShowDropdown(false);
    };

    useMouseOut(itemRef, onMouseOut);

    useEffect(() => {
        // console.log("Page Data Changed... closing Mega Menu");
        setShowDropdown(false);
    }, [pageData]);

    return (
        <li className="navbar-nav-item" onMouseOver={onMouseOver} ref={itemRef}>
            <Link to={treeNode.url} className="navbar-nav-link dropdown-toggle hidden-caret">
                {treeNode.name}
            </Link>
            {((treeNode.children.length || treeNode.machines.length) && (
                <div className={classNames("navbar-dropdown py-8", showDropdown ? "show" : null)}>
                    <div className="container">
                        <div className="row">
                            {(treeNode.children.length && (
                                <div className={`col-${treeNode.machines.length ? "4" : "12"}`}>
                                    <h2 className="navbar-submenu-title h6 fw-bold">
                                        <Link to={treeNode.url}>{treeNode.name}</Link>
                                    </h2>
                                    <ul className="navbar-submenu">
                                        {treeNode.children.map((child, childIndex) => (
                                            <li className="navbar-submenu-item" key={`megaMenuTreeNode-cat-${childIndex}`}>
                                                <Link className="navbar-submenu-link" to={child.url}>
                                                    {child.name}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )) ||
                                ""}
                            {(treeNode.machines.length && (
                                <div className={`col-${treeNode.children.length ? "8" : "12"}`}>
                                    <h2 className="navbar-submenu-title h6 fw-bold mb-4">{treeNode.machinesTitle}</h2>
                                    <div className="row row-cols-md-4 row-cols-xl-5 g-2">
                                        {treeNode.machines.map((child, childIndex) => (
                                            <div className="col" key={`megaMenuTreeNode-machine-${childIndex}`}>
                                                <CardLathe {...child} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )) ||
                                ""}
                        </div>
                    </div>
                </div>
            )) ||
                ""}
        </li>
    );
}

export default HeaderMegaMenuItem;
