import React, { useEffect, useState } from "react";
import { t } from "i18next";
import Button from "../../components/abstract/Button/Button";
import "./Card.scss";
import "./CheckoutPage.scss";
import useShoppingCart from "../../contexts/shoppingCart/useShoppingCart";
import CartLineLite from "../../components/concret/CartLineLite/CartLineLite";
import useLayout from "../../contexts/layout/useLayout";
import CurrencyFormat from "../../components/concret/CurrencyFormat/CurrencyFormat";
import CheckoutPortlets from "../../components/concret/CheckoutPortlets/CheckoutPortlets";
import AddressPortlet from "../../components/concret/CheckoutPortlets/Portlets/AddressPortlet/AddressPortlet";
import DeliveryPortlet from "../../components/concret/CheckoutPortlets/Portlets/DeliveryPortlet/DeliveryPortlet";
import PaymentPortlet from "../../components/concret/CheckoutPortlets/Portlets/PaymentPortlet/PaymentPortlet";
import useAuth from "../../contexts/auth/useAuth";
import IconLoading from "../../components/abstract/icons/IconLoading";
import IllustrationEmptyCart from "../../components/abstract/illustrations/IllustrationEmptyCart";
import IllustrationLogin from "../../components/abstract/illustrations/IllustrationLogin";
import useLocalization from "../../contexts/localization/useLocalization";
import FormikInput from "../../components/abstract/forms/formik/FormikInput";
import { Form } from "react-router-dom";
import { Formik } from "formik";
import { IconClose, IconPlus } from "../../components/abstract/icons";
import EmptyCart from "../../components/concret/EmptyCart/EmptyCart";

function CheckoutPage({ header, footer, needsLogin }) {
    const { headerSet, footerSet } = useLayout();

    useEffect(
        () => headerSet(header),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [header]
    );

    useEffect(
        () => footerSet(footer),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [footer]
    );

    return needsLogin ? <CheckoutPagePromptLogin /> : <CheckoutPageCheckout />;
}

function CheckoutPagePromptLogin() {
    const { getLoginUrl, getSignupUrl } = useAuth();

    return (
        <main className="main checkout-login-page d-flex flex-column">
            <div className="flex-grow-1 container d-flex flex-column align-items-center justify-content-center py-8">
                <IllustrationLogin />
                <p className="my-6 mt-3 mt-md-5 text-center" style={{ maxWidth: "600px" }}>
                    {t("needToLoginToCheckout", "Tienes que iniciar sesión / registrarte para poder finalizar tu compra")}
                </p>
                <div className="d-flex gap-3">
                    <Button href={getLoginUrl()} rel="nofollow" size="large">
                        {t("login", "Iniciar Sesión")}
                    </Button>
                    <Button href={getSignupUrl()} rel="nofollow" variant="outline" size="large">
                        {t("signup", "Nuevo Usuario")}
                    </Button>
                </div>
            </div>
        </main>
    );
}

function CheckoutPageCheckout() {
    const { isCartLoading, lines, numItems, getItemsTaxBase, getCartUrl, getShippingTaxBase, getShippingAddress, getTotal, getTotalTax, restoreCart } =
        useShoppingCart();

    const { currentLanguage } = useLocalization();

    const [showPromoCode, setShowPromoCode] = useState(false);
    const onClick = () => setShowPromoCode(true);

    useEffect(() => {
        restoreCart();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLanguage]);

    return (
        <main className="main checkout-page d-flex flex-column">
            <div className="container flex-grow-1 d-flex flex-column">
                {(isCartLoading && (
                    <div className="flex-grow-1 d-flex flex-column align-items-center justify-content-center py-6">
                        <IconLoading />
                    </div>
                )) ||
                    (numItems && (
                        <div className="shopping-cart-layout flex-grow-1">
                            <div className="shopping-cart-layout__main">
                                <CheckoutPortlets forceLinealFlow={true}>
                                    <CheckoutPortlets.Portlet id="billing" title={t("billingAddress", "Dirección de Facturación")}>
                                        <CheckoutPortlets.Portlet.Edit>
                                            <AddressPortlet type="billing" mode="edit" />
                                        </CheckoutPortlets.Portlet.Edit>
                                        <CheckoutPortlets.Portlet.Done>
                                            <AddressPortlet type="billing" mode="view" />
                                        </CheckoutPortlets.Portlet.Done>
                                    </CheckoutPortlets.Portlet>
                                    <CheckoutPortlets.Portlet
                                        id="shipping"
                                        title={t("shippingAddress", "Dirección de Envío")}
                                        nextStepButtonTitle={t("continueToShipping", "Continuar con el Transporte")}
                                    >
                                        <CheckoutPortlets.Portlet.Edit>
                                            <AddressPortlet type="shipping" mode="edit" />
                                        </CheckoutPortlets.Portlet.Edit>
                                        <CheckoutPortlets.Portlet.Done>
                                            <AddressPortlet type="shipping" mode="view" />
                                        </CheckoutPortlets.Portlet.Done>
                                    </CheckoutPortlets.Portlet>
                                    <CheckoutPortlets.Portlet
                                        id="delivery"
                                        title={t("shippingMethod", "Método de Transporte")}
                                        nextStepButtonTitle={t("continueToPayment", "Continuar con el Pago")}
                                    >
                                        <CheckoutPortlets.Portlet.Edit>
                                            <DeliveryPortlet mode="edit" />
                                        </CheckoutPortlets.Portlet.Edit>
                                        <CheckoutPortlets.Portlet.Done>
                                            <DeliveryPortlet mode="view" />
                                        </CheckoutPortlets.Portlet.Done>
                                    </CheckoutPortlets.Portlet>
                                    <CheckoutPortlets.Portlet id="payment" title={t("payment", "Pago")} showNextStepButton={false}>
                                        <CheckoutPortlets.Portlet.Edit>
                                            <PaymentPortlet />
                                        </CheckoutPortlets.Portlet.Edit>
                                        <CheckoutPortlets.Portlet.Done>...Done...</CheckoutPortlets.Portlet.Done>
                                    </CheckoutPortlets.Portlet>
                                </CheckoutPortlets>
                            </div>
                            <div className="shopping-cart-layout__aside">
                                <div className="shopping-cart-aside-wrapper">
                                    <section className="section-order-summary">
                                        <div className="d-flex">
                                            <h2 className="title">{t("orderSummary", "Resumen del pedido")}</h2>
                                            <Button to={getCartUrl()} variant="link" size="small" className="ms-auto">
                                                {t("edit", "Editar")}
                                            </Button>
                                        </div>

                                        <div className="cart-list">
                                            {lines.map((line, lineIndex) => (
                                                <CartLineLite {...line} lineKey={line.key} key={`cart-line-${lineIndex}`} />
                                            ))}
                                        </div>
                                    </section>
                                    <section className="section-cart-totals">
                                        <h2 className="title">{t("orderTotal", "Total Pedido")}</h2>
                                        <div className="cart-total mt-3">
                                            <div className="cart-total__line">
                                                <span className="label">{t("subtotal", "Subtotal")}</span>
                                                <span className="value">
                                                    <CurrencyFormat value={getItemsTaxBase()} />
                                                </span>
                                            </div>
                                            {(getShippingTaxBase() && (
                                                <div className="cart-total__line">
                                                    <span className="label">
                                                        {t("shippingCosts", "Gastos de envío")}
                                                        <span className="d-flex fs-xs text-muted fw-normal">
                                                            {t("shippingToCityPostalCodeCountry", "Envío a {{city}} {{postalCode}} {{countryCode}}", {
                                                                city: getShippingAddress().city,
                                                                postalCode: getShippingAddress().postalCode,
                                                                countryCode: getShippingAddress().country.code,
                                                            })}
                                                        </span>
                                                    </span>
                                                    <span className="value">
                                                        <CurrencyFormat value={getShippingTaxBase()} />
                                                    </span>
                                                </div>
                                            )) ||
                                                ""}
                                            <div className="cart-total__line">
                                                <span className="label">{t("taxes", "Impuestos")}</span>
                                                <span className="value">
                                                    <CurrencyFormat value={getTotalTax()} />
                                                </span>
                                            </div>

                                            {/* COUPON CODE */}

                                            {/* <div className="cart-total__line cart-total__line--coupon-code flex-column align-items-start">
                                                {!showPromoCode ? (
                                                    <Button variant="link" iconAfter={<IconPlus />} className="my-2" onClick={onClick}>
                                                        {t("couponCodeLinkText", "Tengo un código promocional")}
                                                    </Button>
                                                ) : null}

                                                {showPromoCode ? (
                                                    <div className="coupon-code-input flex-grow-1 w-100">
                                                        <div className="form-group">
                                                            <div className="form-input-group input-type-text">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={t("couponCodePlaceholder", "Cupón de descuento")}
                                                                    name="couponCode"
                                                                    autocomplete="off"
                                                                    value=""
                                                                />
                                                                <Button variant="outline" color="secondary" size="small">
                                                                    {t("apply", "Aplicar")}
                                                                </Button>
                                                                <div className="fake-form-control"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null}

                                                <div className="coupon-code-info flex-grow-1 d-flex align-items-center justify-content-between w-100">
                                                    <span className="label">{t("couponCodeLabel", "Cupón de descuento")}</span>
                                                    <span className="value">
                                                        <div className="chip chip--light-primary">
                                                            <div className="chip-label">STORE23</div>
                                                            <button className="chip-close-button">
                                                                <IconClose size="small" />
                                                            </button>
                                                        </div>
                                                    </span>
                                                </div>
                                            </div> */}
                                            <div className="cart-total__line cart-total__line--grand-total">
                                                <span className="label">
                                                    <strong>{t("total", "TOTAL")}</strong>
                                                </span>
                                                <span className="value">
                                                    <CurrencyFormat value={getTotal()} />
                                                </span>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    )) || <EmptyCart />}
            </div>
        </main>
    );
}

export default CheckoutPage;
