import { create } from "apisauce";
import localizationStorage from "../../contexts/localization/storage";

const apiClient = create({
    baseURL: process.env.REACT_APP_APP_BASE_URL,
    timeout: 150000,
    withCredentials: true
});

apiClient.addRequestTransform((request) => {
    const lang = localizationStorage.getStoredLanguage();

    if (lang) {
        request.headers["x-lang"] = lang;
    }
});

export default apiClient;
