import { create } from "apisauce";
import localizationStorage from "../../contexts/localization/storage";
import shoppingCartStorage from "../../contexts/shoppingCart/storage";

const apiClient = create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 150000,
    withCredentials: true,
});

apiClient.addRequestTransform((request) => {
    const lang = localizationStorage.getStoredLanguage();
    const cartKey = shoppingCartStorage.getKey();

    if (lang) {
        request.headers["x-lang"] = lang;
    }
    if (cartKey) {
        request.headers["x-cart"] = cartKey;
    }
});

export default apiClient;
