import React from "react";
import BaseIconSVG from "./BaseIconSVG";

function IconYoutube(props) {
    return (
        <BaseIconSVG
            Icon={({ ...otherProps }) => (
                <svg {...otherProps}>
                    <path d="m21.59 7.17c-.23-.87-.91-1.54-1.77-1.79-1.54-.41-7.82-.41-7.82-.41s-6.27 0-7.82.41c-.86.24-1.54.91-1.77 1.79-.41 1.56-.41 4.84-.41 4.84s0 3.29.41 4.86c.23.86.91 1.52 1.77 1.74 1.54.41 7.82.41 7.82.41s6.27 0 7.82-.41c.86-.24 1.54-.89 1.77-1.76.41-1.56.41-4.84.41-4.84s0-3.27-.41-4.84zm-12.44 8.91v-8.14l7.14 4.08z" />
                </svg>
            )}
            {...props}
        />
    );
}

export default IconYoutube;
