import React from "react";
import { t } from "i18next";
import useAuth from "../../../contexts/auth/useAuth";
import Button from "../../abstract/Button/Button";
import { IconUserCircle, IconUserCircleFilled } from "../../abstract/icons";
import Skeleton from "../../abstract/Skeleton/Skeleton";

function AccountSelector({ myAccountUrl }) {
    const { userLoaded, user } = useAuth();

    return userLoaded ? user ? <AccountSelectorLoggedIn myAccountUrl={myAccountUrl} /> : <AccountSelectorLogin /> : <AccountSelectorSkeleton />;
}

function AccountSelectorLoggedIn({ myAccountUrl }) {
    const { user } = useAuth();

    const { userDisplayName } = user;

    return (
        <Button className="btn-user-account bg-transparent border-0 flex-column" to={myAccountUrl}>
            {/* <Avatar size="xsmall" user={user} variant="circle" /> */}
            <IconUserCircleFilled />
            <span className="btn-label fs-xs opacity-50">{userDisplayName}</span>
        </Button>
    );
}

function AccountSelectorLogin() {
    const { getLoginUrl } = useAuth();
    return (
        <Button className="btn-user-account bg-transparent border-0 flex-column" href={getLoginUrl()} rel="nofollow">
            <IconUserCircle />
            <span className="btn-label fs-xs opacity-50">{t("login", "Iniciar Sesión")}</span>
        </Button>
    );
}

function AccountSelectorSkeleton() {
    return (
        <>
            <div className="d-flex flex-column align-items-center gap-1" style={{ paddingLeft: "0.75rem", paddingRight: "0.75rem" }}>
                <Skeleton isOnDarkBackground variant="circular" width="1.5rem" height="1.5rem" />
                <Skeleton isOnDarkBackground height="0.75rem" width="5rem" className="d-none d-xl-block" />
            </div>
        </>
    );
}

export default AccountSelector;
