import React from "react";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import { getProperty } from "../../../../helpers/misc";
import FormLabel from "../FormLabel";
import FormHelpText from "../FormHelpText";
import FormErrorMessage from "../FormErrorMessage";

function FormikTextarea(props) {
    const {
        label,
        rows = 3,
        labelHelp,
        id,
        name,
        className,
        // variant = "outlined",
        maxChars,
        placeholder,
        required = false,
        validOnSubmit = false,
        validIfNoErrors = false,
        autoComplete = false,
        helpText = null,
        disabled = false,
        onChange,
        ...otherProps
    } = props;

    const { setFieldTouched, setFieldValue, errors, touched, values, submitCount } = useFormikContext();

    const getValue = () => {
        if (values) {
            if ("undefined" !== getProperty(name, values)) {
                //console.log(`APP Form Input current value ${name}`, getProperty(name, values));
                return getProperty(name, values) || "";
            }
        }
        return "";
    };

    const setValue = (newValue) => {
        if (maxChars) {
            setFieldValue(name, newValue.substr(0, maxChars));
        } else {
            setFieldValue(name, newValue);
        }
    };

    const hasError = () =>
        errors && (getProperty(name, touched) || submitCount) && getProperty(name, errors) && "string" === typeof getProperty(name, errors) ? true : false;
    const showValid = () => (errors && ((submitCount && validOnSubmit) || validIfNoErrors) && !getProperty(name, errors) ? true : false);

    return (
        <div className={["form-group", ...(hasError() ? ["is-invalid"] : []), ...(showValid() ? ["is-valid"] : [])].join(" ")}>
            <FormLabel id={id} required={required} labelHelp={labelHelp}>
                {label}
            </FormLabel>
            <textarea
                rows={rows}
                className={[
                    "form-control",
                    ...(hasError() ? ["is-invalid"] : []),
                    ...(showValid() ? ["is-valid"] : []),
                    // ...(variant === "filled" ? ["form-control--filled"] : []),
                ].join(" ")}
                id={id}
                name={name}
                placeholder={placeholder}
                autoComplete={(!autoComplete && "off") || autoComplete}
                disabled={disabled}
                onBlur={() => setFieldTouched(name, true, false)}
                onChange={(e) => setValue(e.currentTarget.value)}
                value={getValue()}
                {...otherProps}
            />

            {((helpText || maxChars) && (
                <FormHelpText>
                    <span className="help-text">{helpText}</span>
                    {(maxChars && (
                        <span className="help-maxchars">
                            {getProperty(name, values) ? getProperty(name, values).length : 0} / {maxChars}
                        </span>
                    )) ||
                        ""}
                </FormHelpText>
            )) ||
                ""}

            <FormErrorMessage visible={hasError()}>{getProperty(name, errors)}</FormErrorMessage>
        </div>
    );
}

FormikTextarea.propTypes = {
    label: PropTypes.string,
    labelHelp: PropTypes.string,
    name: PropTypes.string,
    maxChars: PropTypes.number,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    // variant: PropTypes.oneOf(["Default", "filled"]),
    required: PropTypes.bool,
    validOnSubmit: PropTypes.bool,
    validIfNoErrors: PropTypes.bool,
    disabled: PropTypes.bool,
    autoComplete: PropTypes.bool,
    helpText: PropTypes.string,
};

export default FormikTextarea;
