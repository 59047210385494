import client from "./clients/cms";

const get = async (key) => {
    return client.get(`/orders/${key}/`);
};

const create = async (data) => {
    return client.post("/orders/", data);
};

export { get, create };
