import React from "react";
import useMainSearch from "../../../contexts/mainSearch/useMainSearch";
import ButtonClose from "../../abstract/ButtonClose/ButtonClose";
import Modal from "../../abstract/Modal/Modal";
import SearchForm from "../../concret/SearchForm/SearchForm";

function MainSearchModal() {
    const { modalOpen, machines, hideModal } = useMainSearch();

    return (
        <Modal show={modalOpen} onHide={hideModal} className="desktop-search-wrapper search-overlay" size="large" fullscreen={true}>
            <ButtonClose onClick={hideModal} />
            <SearchForm machines={machines} />
        </Modal>
    );
}

export default MainSearchModal;
