import React from "react";
import BaseIconSVG from "./BaseIconSVG";

function IconInstagram(props) {
    return (
        <BaseIconSVG
            Icon={({ ...otherProps }) => (
                <svg {...otherProps}>
                    <path d="m7.88 2c-3.23 0-5.88 2.65-5.88 5.88v8.24c0 3.23 2.65 5.88 5.88 5.88h8.24c3.23 0 5.88-2.65 5.88-5.88v-8.24c0-3.23-2.65-5.88-5.88-5.88zm0 1.82h8.24c2.25 0 4.06 1.81 4.06 4.06v8.24c0 2.25-1.81 4.06-4.06 4.06h-8.24c-2.25 0-4.06-1.81-4.06-4.06v-8.24c0-2.25 1.81-4.06 4.06-4.06zm9.49 1.99c-.46 0-.82.37-.82.82s.37.82.82.82.82-.37.82-.82-.37-.82-.82-.82zm-5.37.74c-3 0-5.45 2.45-5.45 5.45s2.45 5.45 5.45 5.45 5.45-2.45 5.45-5.45-2.45-5.45-5.45-5.45zm0 1.82c2.02 0 3.64 1.62 3.64 3.64s-1.62 3.64-3.64 3.64-3.64-1.62-3.64-3.64 1.62-3.64 3.64-3.64z" />
                </svg>
            )}
            {...props}
        />
    );
}

export default IconInstagram;
