import React, { useEffect, useState } from "react";
// import * as urlsApi from "./api/urls";

// import NewsArchiveLayout from "./layouts/news/NewsArchiveLayout";
// import PagesHome from "./pages/Home";
// import NewsSingle from "./content/news/NewsSingle";
//import NotFound from "./pages/NotFound";

// import Applications from "./pages/Applications";
// import ApplicationsSingle from "./pages/ApplicationsSingle";
// import Industries from "./pages/Industries";
// import IndustriesSingle from "./pages/IndustriesSingle";
// import News from "./pages/News";
// import NewsSingle from "./pages/NewsSingle";
// import PagesSingleCommercialNetwork from "./pages/PagesSingleCommercialNetwork";
// import PagesSingleHomeGrid from "./pages/PagesSingleHomeGrid";
// import ServicesSingle from "./pages/ServicesSingle";
// import SuccessCases from "./pages/SuccessCases";
// import SuccessCasesSingle from "./pages/SuccessCasesSingle";

// import Container from "./shortcodes/Container";
// import CmzHeader from "./shortcodes/CmzHeader";

import Container from "./components/abstract/Container/Container";
import Page from "./pages/Page/Page";
import ProductPage from "./pages/ProductPage/ProductPage";
import ProductsListPage from "./pages/ProductsListPage/ProductsListPage";
import HomeHero from "./components/concret/HomeHero/HomeHero";
import MachineSlider from "./components/concret/MachineSlider/MachineSlider";
import ProductsSlider from "./components/concret/ProductsSlider/ProductsSlider";
// import ShoppingCart from "./components/concret/ShoppingCart/ShoppingCart";
import CheckoutPage from "./pages/CheckoutPage/CheckoutPage";
import MyAccountPage from "./pages/MyAccountPage/MyAccountPage";
import OrderPayPage from "./pages/OrderPayPage/OrderPayPage";
import OrderReceivedPage from "./pages/OrderReceivedPage/OrderReceivedPage";
import OrdersPage from "./pages/OrdersPage/OrdersPage";
import OrderViewPage from "./pages/OrderViewPage/OrderViewPage";
import ShoppingCartPage from "./pages/ShoppingCartPage/ShoppingCartPage";
import StaticPage from "./pages/StaticPage/StaticPage";

import LayoutContext from "./contexts/layout/context";
import Header from "./layout/Header/Header";
import Footer from "./layout/Footer/Footer";
import NotFound from "./pages/NotFound/NotFound";
import { Helmet } from "react-helmet";

import "./assets/scss/app-base.scss";
import HeaderCheckout from "./layout/HeaderCheckout/HeaderCheckout";
import MainSearchModal from "./components/modals/MainSearchModal/MainSearchModal";

const AppVirtualPage = React.memo(function AppVirtualPage({ components, helmet, notFound = false }) {
    const [header, headerSet] = useState((components && components.length && components[0].props.header) || null);
    const [footer, footerSet] = useState((components && components.length && components[0].props.footer) || null);
    const [isMobileHeaderCollapsed, isMobileHeaderCollapsedSet] = useState(true);

    const RenderComponents = (components) =>
        components.map((component, componentIndex) => <React.Fragment key={componentIndex}>{RenderComponent(component.type, component.props)}</React.Fragment>);

    const RenderComponent = (component, props) => {
        const validComponents = {
            // NewsArchiveLayout: NewsArchiveLayout,
            // Applications,
            // ApplicationsSingle,
            // Container,
            // CmzHeader,
            // Home,
            // Industries,
            // IndustriesSingle,
            // News,
            // NewsSingle,
            // PagesSingleCommercialNetwork,
            // PagesSingleHomeGrid,
            // ServicesSingle,
            // SuccessCases,
            // SuccessCasesSingle
            // NewsSingle: NewsSingle,

            //HomePage,
            Page,
            ShoppingCartPage,
            CheckoutPage,
            ProductPage,
            ProductsListPage,
            MyAccountPage,
            OrderPayPage,
            OrderReceivedPage,
            OrdersPage,
            OrderViewPage,
            StaticPage,
            NotFound,
            Container,
            HomeHero,
            MachineSlider,
            // ShoppingCart,
            ProductsSlider,
        };

        if (validComponents[component]) {
            return React.createElement(
                validComponents[component],
                props,
                props.childrenComponents && props.childrenComponents.length ? RenderComponents(props.childrenComponents) : null
            );
        } else {
            throw new Error(`Component not found: ${component}`);
        }
    };

    useEffect(() => {
        isMobileHeaderCollapsedSet(true);
    }, [components]);

    return (
        <LayoutContext.Provider value={{ header, headerSet, isMobileHeaderCollapsed, isMobileHeaderCollapsedSet, footer, footerSet }}>
            {(helmet && (
                <Helmet>
                    {(helmet.title && <title>{helmet.title}</title>) || ""}
                    {(helmet.metas && helmet.metas.length && helmet.metas.map((meta, i) => <meta {...meta} key={`helmetMeta-${i}`} />)) || ""}
                    {(helmet.links && helmet.links.length && helmet.links.map((link, i) => <link {...link} key={`helmetLink-${i}`} />)) || ""}
                </Helmet>
            )) ||
                ""}
            {(header && ((header.type === "checkout" && <HeaderCheckout {...header} />) || <Header {...header} />)) || ""}
            {/* {(header && <Header {...header} />) || ""} */}
            {(notFound && <NotFound />) || RenderComponents(components)}
            {(footer && <Footer {...footer} />) || ""}
            <MainSearchModal />
        </LayoutContext.Provider>
    );
});

export default AppVirtualPage;
