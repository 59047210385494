const key = "language";

const isLocalStorageAvailable = () => {
    let test = "test";
    try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
    } catch (e) {
        return false;
    }
};
const storeLanguage = (language) => {
    if (isLocalStorageAvailable()) localStorage.setItem(key, language);
};

const getStoredLanguage = (defaultLanguage = "es") => {
    return isLocalStorageAvailable() ? localStorage.getItem(key) || defaultLanguage : defaultLanguage;
};

const removeStoredLanguage = () => {
    if (isLocalStorageAvailable()) localStorage.removeItem(key);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { storeLanguage, getStoredLanguage, removeStoredLanguage };
