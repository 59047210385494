import React from "react";
import BaseIconSVG from "./BaseIconSVG";

function IconTrash(props) {
    return (
        <BaseIconSVG
            Icon={({ ...otherProps }) => (
                <svg {...otherProps}>
                    <path d="M15 4V3H9V4H4V6H5V19C5 20.1 5.9 21 7 21H17C18.1 21 19 20.1 19 19V6H20V4H15ZM17 19H7V6H17V19Z" />
                    <path d="M9 8H11V17H9V8ZM13 8H15V17H13V8Z" />
                </svg>
            )}
            {...props}
        />
    );
}

export default IconTrash;
