import React from "react";
import PropTypes from "prop-types";
import Button from "../../abstract/Button/Button";
import { IconMinus, IconPlus } from "../../abstract/icons";

import "./InputQuantity.scss";

function InputQuantity({ size = "medium", value = 1, min = 1, max = 300, setValue, className, onClick, disabled = false, ...otherProps }) {
    return (
        <div
            className={[
                "quantity-input-group",
                ...(size === "small" ? ["quantity-input-group--sm"] : []),
                ...(size === "large" ? ["quantity-input-group--lg"] : []),
                ...(className && className.length ? [className.trim()] : []),
            ].join(" ")}
        >
            <Button
                color="secondary"
                variant="light"
                size={size}
                iconBefore={<IconMinus />}
                disabled={value <= min ? true : disabled}
                onClick={() => setValue(value - 1 >= min ? value - 1 : min)}
            ></Button>
            <input
                type="number"
                step={1}
                min={min}
                max={max}
                disabled={disabled}
                name="quantity"
                title="Qty"
                className="quantity-input-text"
                size={4}
                value={value}
                onChange={(e) => setValue(parseInt(e.currentTarget.value))}
            />
            <Button
                color="secondary"
                variant="light"
                size={size}
                iconBefore={<IconPlus />}
                disabled={value >= max ? true : disabled}
                onClick={() => setValue(value + 1 <= max ? value + 1 : max)}
            ></Button>
        </div>
    );
}

InputQuantity.propTypes = {
    className: PropTypes.string,
};

export default InputQuantity;
