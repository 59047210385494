import React from "react";
import PropTypes from "prop-types";

import "./Alert.scss";
import ButtonClose from "../ButtonClose/ButtonClose";

function Alert({ color = "primary", children, className = null, isDismissible = false }) {
    const getClassName = () => {
        let alertClassName = `alert--${color}`;

        return [
            "alert",
            ...(alertClassName.length ? [alertClassName] : []),
            ...(isDismissible ? ["alert--dismissible"] : []),
            ...(className && className.length ? [className.trim()] : []),
        ].join(" ");
    };

    return (
        <div className={getClassName()} role="alert">
            {children}
            {(isDismissible && <ButtonClose />) || ""}
        </div>
    );
}

Alert.propTypes = {
    variant: PropTypes.oneOf(["primary", "secondary", "danger", "warning"]),
};

export default Alert;
