import React, { useEffect, useState } from "react";
import { t } from "i18next";
import CurrencySelector from "../../components/concret/CurrencySelector/CurrencySelector";
import LanguageSelector from "../../components/concret/LanguageSelector/LanguageSelector";
import "./HeaderCheckout.scss";
import Button from "../../components/abstract/Button/Button";
import { Link } from "react-router-dom";
import { IconArrowBack, IconChevronDown, IconChevronUp } from "../../components/abstract/icons";
import Offcanvas from "../../components/abstract/Offcanvas/Offcanvas";
import SlideDown from "../../components/abstract/SlideDown/SlideDown";
import useVirtualPageHandler from "../../contexts/virtualPageHandler/useVirtualPageHandler";
import LazyImg from "../../components/abstract/LazyImg/LazyImg";
import classNames from "classnames";
import { getAssetUrl } from "../../helpers/misc";
import useLocalization from "../../contexts/localization/useLocalization";

function HeaderCheckout({ langNav, megaMenu, homeUrl, adminUrl, categoriesNav = {} }) {
    const { currentLanguage } = useLocalization();

    return (
        <LocalizedHeaderCheckout
            langNav={langNav}
            homeUrl={homeUrl}
            megaMenu={megaMenu}
            adminUrl={adminUrl}
            categoriesNav={categoriesNav}
            currentLanguage={currentLanguage}
        />
    );
}

function LocalizedHeaderCheckout({ langNav, megaMenu, homeUrl, adminUrl, categoriesNav = {} }) {
    const [offcanvasShow, setOffcanvasShow] = useState(false);
    const [mobileNavActiveCat, setMobileNavActiveCat] = useState(null);
    const toggleNavOffCanvas = () => {
        setOffcanvasShow((offcanvasShow) => !offcanvasShow);
    };
    const { pageData } = useVirtualPageHandler();

    useEffect(() => {
        setOffcanvasShow(false);
        setMobileNavActiveCat(null);
    }, [pageData]);

    const getImageProps = (sizes, alt) => ({ ...sizes.find((s) => s.name === "thumbnail__3x"), alt });

    return (
        <>
            <header className="header-checkout">
                <div className="header-checkout-topbar">
                    <div className="container h-100 d-flex">
                        <Button to={homeUrl} variant="link" color="secondary" size="small" iconBefore={<IconArrowBack />} className="ps-0">
                            {t("backToShop", "Volver a la tienda")}
                        </Button>
                        <div className="ms-auto d-flex align-items-center gap-3">
                            {adminUrl && (
                                <Button size="small" variant="ghost" href={adminUrl} target="_blank">
                                    {t("editInCMS", "Editar en CMS")}
                                </Button>
                            )}
                            {langNav && <LanguageSelector selectedLanguage={langNav.current} items={langNav.items} />}
                            <CurrencySelector />
                        </div>
                    </div>
                </div>
                <div className="header-checkout-main">
                    <div className="container">
                        {/* <Button onClick={toggleNavOffCanvas} className="btn-menu-toggle bg-transparent border-0 flex-column" iconBefore={<IconMenu />}></Button> */}
                        {/* <Button onClick={toggleNavOffCanvas} className="btn-menu-toggle bg-transparent btn--icon-only border-0 flex-column">
                            <div className="icon-wrapper">
                                <span className="line"></span>
                                <span className="line"></span>
                                <span className="line"></span>
                            </div>
                        </Button> */}
                        <Button to={homeUrl} variant="outline" color="white" iconBefore={<IconArrowBack />} className="border-0 d-lg-none"></Button>

                        <Link to={homeUrl} className="header-logo">
                            <img src={getAssetUrl("/assets/img/logos/logo-cmz-store-w.svg")} className="header-logo-img" alt="CMZ Store Logo" width="124" />
                        </Link>

                        <h1 className="text-white mb-0 header-checkout-title">{t("checkout", "Checkout")}</h1>
                    </div>
                </div>
            </header>

            <Offcanvas position="start" className="offcanvas-main-nav" show={offcanvasShow} onHide={toggleNavOffCanvas}>
                <Offcanvas.Body className="p-0">
                    <div className="d-flex gap-3 bg-gray-100 py-2 px-3">
                        <LanguageSelector selectedLanguage={langNav.current} items={langNav.items} />
                        <CurrencySelector />
                    </div>

                    <div className="sliding-panels">
                        <div className="sliding-panel sliding-panel--visible">
                            <nav className="offcanvas-navbar">
                                {(categoriesNav[1] && categoriesNav[1].children && Object.keys(categoriesNav[1].children).length && (
                                    <ul className="offcanvas-navbar-nav">
                                        {Object.keys(categoriesNav[1].children).map(
                                            (catIndex, cIndex) =>
                                                (Object.keys(categoriesNav[1].children[catIndex].children).length && (
                                                    <li
                                                        className={classNames(
                                                            "nav-item has-children",
                                                            categoriesNav[1].children[catIndex].active ? "nav-item-active" : null
                                                        )}
                                                        key={`offcanvas-cat--${cIndex}`}
                                                    >
                                                        <>
                                                            <Button
                                                                variant="link"
                                                                className="nav-link"
                                                                ripple={false}
                                                                onClick={() =>
                                                                    setMobileNavActiveCat(
                                                                        mobileNavActiveCat !== categoriesNav[1].children[catIndex].trId
                                                                            ? categoriesNav[1].children[catIndex].trId
                                                                            : null
                                                                    )
                                                                }
                                                            >
                                                                {categoriesNav[1].children[catIndex].image && (
                                                                    <LazyImg
                                                                        className="img-fluid"
                                                                        style={{ width: "40px" }}
                                                                        {...getImageProps(
                                                                            categoriesNav[1].children[catIndex].image.sizes,
                                                                            categoriesNav[1].children[catIndex].image.alt || ""
                                                                        )}
                                                                    />
                                                                )}
                                                                {categoriesNav[1].children[catIndex].name}
                                                                {(mobileNavActiveCat !== categoriesNav[1].children[catIndex].trId && (
                                                                    <IconChevronDown className="ms-auto flex-shrink-0" />
                                                                )) || <IconChevronUp className="ms-auto flex-shrink-0" />}
                                                            </Button>
                                                            <SlideDown closed={mobileNavActiveCat !== categoriesNav[1].children[catIndex].trId}>
                                                                <ul className="offcanvas-navbar-subnav">
                                                                    {Object.keys(categoriesNav[1].children[catIndex].children).map(
                                                                        (childCatIndex, childCIndex) => (
                                                                            <li
                                                                                className={classNames(
                                                                                    "nav-item",
                                                                                    categoriesNav[1].children[catIndex].children[childCatIndex].active
                                                                                        ? "nav-item-active"
                                                                                        : null
                                                                                )}
                                                                                key={`offcanvas-cat--${childCIndex}`}
                                                                            >
                                                                                <Link
                                                                                    className="nav-link"
                                                                                    to={categoriesNav[1].children[catIndex].children[childCatIndex].url}
                                                                                >
                                                                                    {categoriesNav[1].children[catIndex].children[childCatIndex].name}
                                                                                </Link>
                                                                            </li>
                                                                        )
                                                                    )}
                                                                </ul>
                                                            </SlideDown>
                                                        </>
                                                    </li>
                                                )) || (
                                                    <li
                                                        className={classNames(
                                                            "nav-item",
                                                            categoriesNav[1].children[catIndex].active ? "nav-item-active" : null
                                                        )}
                                                        key={`offcanvas-cat--${cIndex}`}
                                                    >
                                                        <Link className="nav-link" to={categoriesNav[1].children[catIndex].url}>
                                                            {categoriesNav[1].children[catIndex].image && (
                                                                <LazyImg
                                                                    className="img-fluid"
                                                                    style={{ width: "40px" }}
                                                                    {...getImageProps(
                                                                        categoriesNav[1].children[catIndex].image.sizes,
                                                                        categoriesNav[1].children[catIndex].image.alt || ""
                                                                    )}
                                                                />
                                                            )}
                                                            {categoriesNav[1].children[catIndex].name}
                                                        </Link>
                                                    </li>
                                                )
                                        )}
                                    </ul>
                                )) ||
                                    ""}
                                {(megaMenu && megaMenu[0] && megaMenu[0].machines && megaMenu[0].machines.length && (
                                    <div className="bg-gray-800">
                                        <p className="h6 text-muted px-3 pt-4">{t("searchByLatheSeries", "Busca por tipo de torno")}</p>
                                        <ul className="offcanvas-navbar-nav offcanvas-navbar-nav--dark">
                                            {megaMenu[0].machines.map((mac, macIndex) => (
                                                <li className="nav-item" key={`mac-${macIndex}`}>
                                                    <Link className="nav-link" to={mac.url}>
                                                        {mac.title}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )) ||
                                    ""}
                            </nav>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default HeaderCheckout;
