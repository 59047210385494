import { useEffect, useState } from "react";
import useLocalization from "../../../contexts/localization/useLocalization";
import useShoppingCart from "../../../contexts/shoppingCart/useShoppingCart";

function CurrencyFormat({ value, currency }) {
    const { currentLanguage } = useLocalization();
    const { currency: currentCurrency } = useShoppingCart();
    const [outputCurrency, setOutputCurrency] = useState(currency ? currency : currentCurrency);

    useEffect(() => {
        setOutputCurrency(currency ? currency : currentCurrency);
    }, [currency, currentCurrency]);

    const getLocale = () => {
        switch (currentLanguage) {
            case "en":
                return "en-GB";
            case "fr":
                return "fr-FR";
            case "de":
                return "de-DE";
            case "it":
                return "it-IT";
            default:
                return "es-ES";
        }
    };

    const numberFormat = new Intl.NumberFormat(getLocale(), { style: "currency", currency: outputCurrency ? outputCurrency.code : "EUR" });

    return numberFormat.format(outputCurrency && outputCurrency.rate ? value * outputCurrency.rate : value); // `${value}€`;
}

export default CurrencyFormat;
