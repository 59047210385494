import client from "./clients/cms";

const getAll = async () => {
    return client.get("/countries/");
};

const getStates = async (countryId) => {
    return client.get(`/countries/${countryId}/states/`);
};

export { getAll, getStates };
