import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import LazyImg from "../LazyImg/LazyImg";
import { getImagePropsFromDto } from "../../../helpers/misc";
import "./CardLathe.scss";

function CardLathe({ className, title = "Serie TL", url, image }) {
    const getImageProps = (sizes, alt) => ({ ...sizes.find((s) => s.name === "full"), alt });

    return (
        <div className={classnames(["card-lathe", className])}>
            <Link to={url}>
                <div className="card-lathe__media">
                    {image &&
                        ((image.props && <LazyImg className="img-fluid" {...getImagePropsFromDto(image.props)} />) || (
                            <LazyImg className="img-fluid" {...getImageProps(image.sizes, image.alt || "")} />
                        ))}
                </div>

                <div className="card-lathe__content">
                    <h2 className="card-lathe__title">{title}</h2>
                </div>
            </Link>
        </div>
    );
}

CardLathe.propTypes = {
    className: PropTypes.string,
};

export default CardLathe;
