import client from "./clients/cms";

const search = async (q, machine) => {
    return client.get("/products/", {
        q,
        machine,
    });
};

const getProductStock = async (sku) => {
    return client.get("/products-stock/", {
        sku,
    });
};

export { search, getProductStock };
