import React, { useEffect } from "react";
import useLayout from "../../contexts/layout/useLayout";
import ErrorPage from "../ErrorPage/ErrorPage";

function NotFound({ header, footer }) {
    const { headerSet, footerSet } = useLayout();

    useEffect(
        () => headerSet(header),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [header]
    );
    useEffect(
        () => footerSet(footer),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [footer]
    );

    return <ErrorPage />;
}

export default NotFound;
