import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { t } from "i18next";
import CartLineLite from "../../components/concret/CartLineLite/CartLineLite";
import CurrencyFormat from "../../components/concret/CurrencyFormat/CurrencyFormat";
import DateFormat from "../../components/concret/DateFormat/DateFormat";
import useLayout from "../../contexts/layout/useLayout";
import "./OrderReceivedPage.scss";
import { Trans } from "react-i18next";

function OrderReceivedPage({ header, footer, order, breadcrumbs = [] }) {
    const { headerSet, footerSet } = useLayout();

    useEffect(
        () => headerSet(header),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [header]
    );
    useEffect(
        () => footerSet(footer),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [footer]
    );

    const {
        orderNumber,
        orderDate,
        orderLines,
        currency,
        total,
        paymentGateway,
        shippingService,
        billingAddress,
        shippingAddress,
        userEmail,
        itemsTaxBase,
        shippingServiceTaxBase,
    } = order;

    return (
        <main className="main order-received-page">
            <div className="order-received-header py-md-8 py-4">
                <div className="container">
                    <div className="icon-thank-you-page mx-auto mb-3">
                        <svg fill="none" height="207" viewBox="0 0 196 207" width="196" xmlns="http://www.w3.org/2000/svg">
                            <g fill="#003da5">
                                <path d="m0 54h196v153h-196z" opacity=".2" />
                                <path d="m98 0h54.277l43.723 54h-98z" opacity=".6" />
                                <path d="m98 0h-54.2769l-43.7231 54h98z" opacity=".4" />
                                <path d="m105.015 122.398c9.242-9.402 17.618-17.668 18.611-18.299 4.031-2.589 8.374-.38 8.374 4.29 0 2.335-.743 3.218-20.784 23.535-19.4783 19.813-20.8442 21.076-22.9534 21.076-2.0468 0-3.0391-.821-12.717-10.665-9.9249-10.096-10.5456-10.852-10.5456-13.124 0-3.217 2.1714-5.489 5.3351-5.489 2.1091 0 2.9769.631 10.1138 7.888l7.8781 7.888z" />
                            </g>
                        </svg>
                    </div>
                    <h1 className="text-center">{t("thankYouForYourOrder", "¡Gracias por tu pedido!")}</h1>
                    <p className="text-center fs-5 mt-4">
                        <Trans i18nKey="yourOrderHasBeenRegisteredWithNumber">
                            Tu pedido ha sido registrado con el nº
                            <strong>{{ orderNumber }}</strong>
                        </Trans>
                    </p>
                    <div
                        className="mt-4 mt-md-6"
                        dangerouslySetInnerHTML={{
                            __html: paymentGateway.orderDescription
                                ? paymentGateway.orderDescription.replace(/___ORDERNUMBER___/gi, orderNumber)
                                : paymentGateway.orderDescription,
                        }}
                    ></div>
                </div>
            </div>
            <section className="order-received-details bg-gray-50 pt-5 pt-md-8 pb-10 pb-md-14">
                <div className="container">
                    <div className="row row-cols-1 row-cols-md-2 g-4">
                        <div className="col">
                            <div className="card card-shadow border-0">
                                <div className="card-body">
                                    <div className="card-title">
                                        <h2>{t("orderDetails", "Detalles del pedido")}</h2>
                                    </div>
                                    <div className="data-info">
                                        <div className="label">
                                            <svg className="icon-svg" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    opacity="0.5"
                                                    d="M18 21.6C16.3 21.6 15 20.3 15 18.6V2.50001C15 2.20001 14.6 1.99996 14.3 2.19996L13 3.59999L11.7 2.3C11.3 1.9 10.7 1.9 10.3 2.3L9 3.59999L7.70001 2.3C7.30001 1.9 6.69999 1.9 6.29999 2.3L5 3.59999L3.70001 2.3C3.50001 2.1 3 2.20001 3 3.50001V18.6C3 20.3 4.3 21.6 6 21.6H18Z"
                                                    fill="inherit"
                                                />
                                                <path
                                                    d="M12 12.6H11C10.4 12.6 10 12.2 10 11.6C10 11 10.4 10.6 11 10.6H12C12.6 10.6 13 11 13 11.6C13 12.2 12.6 12.6 12 12.6ZM9 11.6C9 11 8.6 10.6 8 10.6H6C5.4 10.6 5 11 5 11.6C5 12.2 5.4 12.6 6 12.6H8C8.6 12.6 9 12.2 9 11.6ZM9 7.59998C9 6.99998 8.6 6.59998 8 6.59998H6C5.4 6.59998 5 6.99998 5 7.59998C5 8.19998 5.4 8.59998 6 8.59998H8C8.6 8.59998 9 8.19998 9 7.59998ZM13 7.59998C13 6.99998 12.6 6.59998 12 6.59998H11C10.4 6.59998 10 6.99998 10 7.59998C10 8.19998 10.4 8.59998 11 8.59998H12C12.6 8.59998 13 8.19998 13 7.59998ZM13 15.6C13 15 12.6 14.6 12 14.6H10C9.4 14.6 9 15 9 15.6C9 16.2 9.4 16.6 10 16.6H12C12.6 16.6 13 16.2 13 15.6Z"
                                                    fill="inherit"
                                                />
                                                <path
                                                    d="M15 18.6C15 20.3 16.3 21.6 18 21.6C19.7 21.6 21 20.3 21 18.6V12.5C21 12.2 20.6 12 20.3 12.2L19 13.6L17.7 12.3C17.3 11.9 16.7 11.9 16.3 12.3L15 13.6V18.6Z"
                                                    fill="inherit"
                                                />
                                            </svg>
                                            {t("orderNumberTableLabel", "Nº pedido")}
                                        </div>
                                        <div className="value">{orderNumber}</div>
                                    </div>
                                    <div className="data-info">
                                        <div className="label">
                                            <svg className="icon-svg" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    opacity="0.5"
                                                    d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z"
                                                    fill="inherit"
                                                ></path>
                                                <path
                                                    d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z"
                                                    fill="inherit"
                                                ></path>
                                            </svg>
                                            {t("orderDataTableLabel", "Fecha")}
                                        </div>
                                        <div className="value">
                                            <DateFormat value={orderDate} />
                                        </div>
                                    </div>

                                    <div className="data-info">
                                        <div className="label">
                                            <svg className="icon-svg" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    opacity="0.5"
                                                    d="M3.20001 5.91897L16.9 3.01895C17.4 2.91895 18 3.219 18.1 3.819L19.2 9.01895L3.20001 5.91897Z"
                                                    fill="inherit"
                                                ></path>
                                                <path
                                                    opacity="0.5"
                                                    d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21C21.6 10.9189 22 11.3189 22 11.9189V15.9189C22 16.5189 21.6 16.9189 21 16.9189H16C14.3 16.9189 13 15.6189 13 13.9189ZM16 12.4189C15.2 12.4189 14.5 13.1189 14.5 13.9189C14.5 14.7189 15.2 15.4189 16 15.4189C16.8 15.4189 17.5 14.7189 17.5 13.9189C17.5 13.1189 16.8 12.4189 16 12.4189Z"
                                                    fill="inherit"
                                                ></path>
                                                <path
                                                    d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21V7.91895C21 6.81895 20.1 5.91895 19 5.91895H3C2.4 5.91895 2 6.31895 2 6.91895V20.9189C2 21.5189 2.4 21.9189 3 21.9189H19C20.1 21.9189 21 21.0189 21 19.9189V16.9189H16C14.3 16.9189 13 15.6189 13 13.9189Z"
                                                    fill="inherit"
                                                ></path>
                                            </svg>
                                            {t("orderPaymentGatewayTableLabel", "Metodo de pago")}
                                        </div>
                                        <div className="value">{paymentGateway.name}</div>
                                    </div>

                                    <div className="data-info border-0">
                                        <div className="label">
                                            <svg className="icon-svg" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M20 8H16C15.4 8 15 8.4 15 9V16H10V17C10 17.6 10.4 18 11 18H16C16 16.9 16.9 16 18 16C19.1 16 20 16.9 20 18H21C21.6 18 22 17.6 22 17V13L20 8Z"
                                                    fill="inherit"
                                                ></path>
                                                <path
                                                    opacity="0.5"
                                                    d="M20 18C20 19.1 19.1 20 18 20C16.9 20 16 19.1 16 18C16 16.9 16.9 16 18 16C19.1 16 20 16.9 20 18ZM15 4C15 3.4 14.6 3 14 3H3C2.4 3 2 3.4 2 4V13C2 13.6 2.4 14 3 14H15V4ZM6 16C4.9 16 4 16.9 4 18C4 19.1 4.9 20 6 20C7.1 20 8 19.1 8 18C8 16.9 7.1 16 6 16Z"
                                                    fill="inherit"
                                                ></path>
                                            </svg>
                                            {t("orderShippingServiceTableLabel", "Transporte")}
                                        </div>
                                        <div className="value">{shippingService.name}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card card-shadow border-0">
                                <div className="card-body">
                                    <div className="card-title">
                                        <h2>{t("contactData", "Datos de Contacto")}</h2>
                                    </div>
                                    <div className="data-info">
                                        <div className="label">
                                            <svg className="icon-svg" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    opacity="0.5"
                                                    d="M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z"
                                                    fill="inherit"
                                                />
                                                <path
                                                    d="M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z"
                                                    fill="inherit"
                                                />
                                                <rect x="7" y="6" width="4" height="4" rx="2" fill="inherit" />
                                            </svg>
                                            {t("fullName", "Nombre")}
                                        </div>
                                        <div className="value">
                                            {shippingAddress.firstName} {shippingAddress.lastName}
                                        </div>
                                    </div>

                                    <div className="data-info">
                                        <div className="label">
                                            <svg className="icon-svg" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    opacity="0.35"
                                                    d="M21 18H3C2.4 18 2 17.6 2 17V7C2 6.4 2.4 6 3 6H21C21.6 6 22 6.4 22 7V17C22 17.6 21.6 18 21 18Z"
                                                    fill="inherit"
                                                />
                                                <path
                                                    d="M11.4 13.5C11.8 13.8 12.3 13.8 12.6 13.5L21.6 6.30005C21.4 6.10005 21.2 6 20.9 6H2.99998C2.69998 6 2.49999 6.10005 2.29999 6.30005L11.4 13.5Z"
                                                    fill="inherit"
                                                />
                                            </svg>
                                            {t("email", "Email")}
                                        </div>
                                        <div className="value">
                                            <a href={`mailto:${userEmail}`} target="_blank" rel="nofollow noopener noreferrer">
                                                {userEmail}
                                            </a>
                                        </div>
                                    </div>

                                    <div className="data-info border-0">
                                        <div className="label">
                                            <svg className="icon-svg" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5 20H19V21C19 21.6 18.6 22 18 22H6C5.4 22 5 21.6 5 21V20ZM19 3C19 2.4 18.6 2 18 2H6C5.4 2 5 2.4 5 3V4H19V3Z"
                                                    fill="inherit"
                                                ></path>
                                                <path opacity="0.3" d="M19 4H5V20H19V4Z" fill="inherit"></path>
                                            </svg>
                                            {t("phoneNumner", "Número de Teléfono")}
                                        </div>
                                        <div className="value">{shippingAddress.phoneNumber}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card card card-shadow border-0">
                                <div className="position-absolute top-0 end-0 opacity-50 pe-none text-end">
                                    <svg
                                        className="icon-svg icon-giant"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            opacity="0.5"
                                            d="M18 21.6C16.3 21.6 15 20.3 15 18.6V2.50001C15 2.20001 14.6 1.99996 14.3 2.19996L13 3.59999L11.7 2.3C11.3 1.9 10.7 1.9 10.3 2.3L9 3.59999L7.70001 2.3C7.30001 1.9 6.69999 1.9 6.29999 2.3L5 3.59999L3.70001 2.3C3.50001 2.1 3 2.20001 3 3.50001V18.6C3 20.3 4.3 21.6 6 21.6H18Z"
                                            fill="inherit"
                                        />
                                        <path
                                            d="M12 12.6H11C10.4 12.6 10 12.2 10 11.6C10 11 10.4 10.6 11 10.6H12C12.6 10.6 13 11 13 11.6C13 12.2 12.6 12.6 12 12.6ZM9 11.6C9 11 8.6 10.6 8 10.6H6C5.4 10.6 5 11 5 11.6C5 12.2 5.4 12.6 6 12.6H8C8.6 12.6 9 12.2 9 11.6ZM9 7.59998C9 6.99998 8.6 6.59998 8 6.59998H6C5.4 6.59998 5 6.99998 5 7.59998C5 8.19998 5.4 8.59998 6 8.59998H8C8.6 8.59998 9 8.19998 9 7.59998ZM13 7.59998C13 6.99998 12.6 6.59998 12 6.59998H11C10.4 6.59998 10 6.99998 10 7.59998C10 8.19998 10.4 8.59998 11 8.59998H12C12.6 8.59998 13 8.19998 13 7.59998ZM13 15.6C13 15 12.6 14.6 12 14.6H10C9.4 14.6 9 15 9 15.6C9 16.2 9.4 16.6 10 16.6H12C12.6 16.6 13 16.2 13 15.6Z"
                                            fill="inherit"
                                        />
                                        <path
                                            d="M15 18.6C15 20.3 16.3 21.6 18 21.6C19.7 21.6 21 20.3 21 18.6V12.5C21 12.2 20.6 12 20.3 12.2L19 13.6L17.7 12.3C17.3 11.9 16.7 11.9 16.3 12.3L15 13.6V18.6Z"
                                            fill="inherit"
                                        />
                                    </svg>
                                </div>
                                <div className="card-body">
                                    <div className="card-title mb-5">
                                        <h2>{t("billingData", "Datos de Facturación")}</h2>
                                    </div>
                                    <address className="fs-sm mb-0">
                                        {billingAddress.companyName}
                                        <br />
                                        {billingAddress.vatNumber}
                                        <br />
                                        {billingAddress.address}
                                        <br />
                                        {billingAddress.city}
                                        {billingAddress.state && <>&nbsp;({billingAddress.state.name})</>}
                                        <br />
                                        {billingAddress.postalCode} - {billingAddress.country.name}
                                    </address>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card card card-shadow border-0">
                                <div className="position-absolute top-0 end-0 opacity-50 pe-none text-end">
                                    <svg
                                        className="icon-svg icon-giant"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M20 8H16C15.4 8 15 8.4 15 9V16H10V17C10 17.6 10.4 18 11 18H16C16 16.9 16.9 16 18 16C19.1 16 20 16.9 20 18H21C21.6 18 22 17.6 22 17V13L20 8Z"
                                            fill="inherit"
                                        ></path>
                                        <path
                                            opacity="0.5"
                                            d="M20 18C20 19.1 19.1 20 18 20C16.9 20 16 19.1 16 18C16 16.9 16.9 16 18 16C19.1 16 20 16.9 20 18ZM15 4C15 3.4 14.6 3 14 3H3C2.4 3 2 3.4 2 4V13C2 13.6 2.4 14 3 14H15V4ZM6 16C4.9 16 4 16.9 4 18C4 19.1 4.9 20 6 20C7.1 20 8 19.1 8 18C8 16.9 7.1 16 6 16Z"
                                            fill="inherit"
                                        ></path>
                                    </svg>
                                </div>
                                <div className="card-body">
                                    <div className="card-title mb-5">
                                        <h2>{t("shippingData", "Datos de Envío")}</h2>
                                    </div>
                                    <address className="fs-sm mb-0">
                                        {shippingAddress.companyName}
                                        <br />
                                        {shippingAddress.address}
                                        <br />
                                        {shippingAddress.city}
                                        {shippingAddress.state && <>&nbsp;({shippingAddress.state.name})</>}
                                        <br />
                                        {shippingAddress.postalCode} - {shippingAddress.country.name}
                                    </address>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-5 card card-shadow border-0">
                        <div className="card-body">
                            <section className="section-order-summary">
                                <div className="d-flex">
                                    <h2 className="title">{t("orderSummary", "Resumen del pedido")}</h2>
                                </div>
                                <div className="cart-list">
                                    {orderLines.map((line, linexIndex) => (
                                        <CartLineLite
                                            key={`cart-line-${linexIndex}`}
                                            product={line.product}
                                            regularTaxBase={line.unitRegularTaxBase * line.quantity}
                                            taxBase={line.totalTaxBase}
                                            quantity={line.quantity}
                                            currency={currency}
                                            className="border-0"
                                        />
                                    ))}
                                </div>
                            </section>
                            <section className="section-cart-totals">
                                <h2 className="title visually-hidden">{t("orderTotal", "Total Pedido")}</h2>
                                <div className="cart-total mt-3">
                                    <div className="cart-total__line">
                                        <span className="label">{t("subtotal", "Subtotal")}</span>
                                        <span className="value">
                                            <CurrencyFormat value={itemsTaxBase} currency={currency} />
                                        </span>
                                    </div>
                                    <div className="cart-total__line">
                                        <span className="label">
                                            {t("shippingCosts", "Gastos de envío")}
                                            <span className="d-flex fs-xs text-muted fw-normal">
                                                {t("shippingToCityPostalCodeCountry", "Envío a {{city}} {{postalCode}} {{countryCode}}", {
                                                    city: shippingAddress.city,
                                                    postalCode: shippingAddress.postalCode,
                                                    countryCode: shippingAddress.country.code,
                                                })}
                                            </span>
                                        </span>
                                        <span className="value">
                                            <CurrencyFormat value={shippingServiceTaxBase} currency={currency} />
                                        </span>
                                    </div>
                                    <div className="cart-total__line">
                                        <span className="label">{t("taxes", "Impuestos")}</span>
                                        <span className="value">
                                            <CurrencyFormat value={total - itemsTaxBase - shippingServiceTaxBase} currency={currency} />
                                        </span>
                                    </div>
                                    <div className="cart-total__line cart-total__line--grand-total">
                                        <span className="label">
                                            <strong>{t("total", "TOTAL")}</strong>
                                        </span>
                                        <span className="value">
                                            <CurrencyFormat value={total} currency={currency} />
                                        </span>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default OrderReceivedPage;
