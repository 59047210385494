import React from "react";
import PropTypes from "prop-types";

function FormErrorMessage(props) {
    const { children, visible } = props;

    return (visible && <div className="invalid-feedback">{children}</div>) || "";
}

FormErrorMessage.propTypes = {
    children: PropTypes.node,
    visible: PropTypes.bool,
};

export default FormErrorMessage;
