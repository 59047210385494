import React, { useState } from "react";
// import SweetAlert2 from "react-sweetalert2";
import { t } from "i18next";
import useShoppingCart from "../../../contexts/shoppingCart/useShoppingCart";
import Dropdown from "../../abstract/Dropdown/Dropdown";
import "./CurrencySelector.scss";

function CurrencySelector(props) {
    const [dropdownOpen, dropdownOpenSet] = useState(false);
    const { currency, currencies, changeCurrency } = useShoppingCart();
    // const [swalProps, setSwalProps] = useState({});

    const currencyClicked = (c) => {
        changeCurrency(c.code);
        dropdownOpenSet(false);
        // if (!c.isEtalon) {
        //     setSwalProps({
        //         show: true,
        //         title: "Aviso",
        //         text: `La moneda seleccionada (${c.code}) se ofrece únicamente como ayuda en la compra. El cambio puede no coincidir con el más reciente y el pago final se realizará en euros (EUR).`,
        //     });
        // }
    };

    return (
        <div className="currency-selector d-flex align-items-center gap-1">
            <span className="label fs-sm">{t("currencyPeriod", "Moneda:")}</span>
            <Dropdown
                align="end"
                buttonAnchor={currency ? currency.code : ""}
                setShow={dropdownOpenSet}
                show={dropdownOpen}
                size="small"
                buttonToggleClassName="px-0"
            >
                <nav className="currency-selector-nav">
                    <ul className="list-unstyled mb-0">
                        {currencies.map((c, i) => (
                            <li key={`currency-${c.code}`}>
                                <button className="dropdown-item" onClick={() => currencyClicked(c)}>
                                    {c.code}
                                </button>
                            </li>
                        ))}
                    </ul>
                </nav>
            </Dropdown>
        </div>
    );
}

export default CurrencySelector;
