import React, { useState } from "react";
import Dropdown from "../../../abstract/Dropdown/Dropdown";

function ContentListToolbarOrder({ orderByOptions, selectedOrderSlug, submitOrderBy }) {
    const [orderByOpen, setOrderByOpen] = useState(false);

    return (
        <div className="toolbar__orderby d-none d-lg-flex align-items-center">
            <Dropdown show={orderByOpen} setShow={setOrderByOpen} buttonAnchor={orderByOptions.find((o) => o.slug === selectedOrderSlug).name} size="small">
                <ul className="list-unstyled mb-0 fs-sm">
                    {orderByOptions.map((opt) => (
                        <li key={`orderByOption-${opt.slug}`}>
                            <button
                                title={opt.name}
                                className="dropdown-item"
                                onClick={() => {
                                    submitOrderBy(opt.slug);
                                    setOrderByOpen(false);
                                }}
                            >
                                {opt.name}
                            </button>
                        </li>
                    ))}
                </ul>
            </Dropdown>
        </div>
    );
}

export default ContentListToolbarOrder;
