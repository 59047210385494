import React, { useEffect, useState } from "react";
import { t } from "i18next";
import Button from "../../components/abstract/Button/Button";
import CartLine from "../../components/concret/CartLine/CartLine";
import useShoppingCart from "../../contexts/shoppingCart/useShoppingCart";
import CurrencyFormat from "../../components/concret/CurrencyFormat/CurrencyFormat";
import useLayout from "../../contexts/layout/useLayout";
import "./ShoppingCartPage.scss";
import IllustrationEmptyCart from "../../components/abstract/illustrations/IllustrationEmptyCart";
import useLocalization from "../../contexts/localization/useLocalization";
import EmptyCart from "../../components/concret/EmptyCart/EmptyCart";
import Modal from "../../components/abstract/Modal/Modal";
import { Trans } from "react-i18next";
import { IconInfo } from "../../components/abstract/icons";

function ShoppingCartPage({ header, footer }) {
    const { headerSet, footerSet } = useLayout();
    const { getCheckoutUrl, lines, numItems, getItemsTaxBase, getItemsTotal, getItemsTotalTax, restoreCart } = useShoppingCart();
    const { currentLanguage } = useLocalization();
    const [showTaxesModal, setShowTaxesModal] = useState(false);

    useEffect(
        () => headerSet(header),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [header]
    );
    useEffect(
        () => footerSet(footer),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [footer]
    );

    useEffect(() => {
        restoreCart();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLanguage]);

    return (
        <>
            <main className="shopping-cart-page d-flex flex-column">
                <div className="container flex-grow-1 d-flex flex-column">
                    {(numItems && (
                        <div className="shopping-cart-layout flex-grow-1">
                            <div className="shopping-cart-layout__main">
                                <h1>
                                    {t("myCart", "Mi carrito")} <small className="text-muted fw-normal">({numItems})</small>
                                </h1>
                                <div className="cart-list mt-4">
                                    {lines.map((line, lineIndex) => (
                                        <CartLine {...line} lineKey={line.key} key={`cart-line-${lineIndex}`} />
                                    ))}
                                </div>
                            </div>
                            <div className="shopping-cart-layout__aside">
                                <div className="shopping-cart-aside-wrapper">
                                    <section className="section-cart-totals">
                                        <h2 className="title">{t("cartTotal", "Total Carrito")}</h2>
                                        <div className="cart-total mt-4">
                                            <div className="cart-total__line">
                                                <span className="label">{t("subtotal", "Subtotal")}</span>
                                                <span className="value">
                                                    <CurrencyFormat value={getItemsTaxBase()} />
                                                </span>
                                            </div>
                                            <div className="cart-total__line">
                                                <span className="label">{t("shippingCosts", "Gastos de envío")}</span>
                                                <span className="value">
                                                    <small>{t("calculatedUponCheckout", "Calculados en el checkout")}</small>
                                                </span>
                                            </div>
                                            <div className="cart-total__line">
                                                <span className="label">{t("taxes", "Impuestos")}</span>
                                                <span className="value">
                                                    {/* <CurrencyFormat value={getItemsTotalTax()} /> */}
                                                    <small>{t("calculatedUponCheckout", "Calculados en el checkout")}</small>
                                                </span>
                                            </div>
                                            {/* {(getItemsTotalTax() > 0 && (
                                                <div style={{ textAlign: "right" }}>
                                                    <Button variant="link" size="small" iconBefore={<IconInfo />} onClick={() => setShowTaxesModal(true)}>
                                                        {t("whyISeeTaxesOnCartTitle", "¿Por qué se me aplican impuestos?")}
                                                    </Button>
                                                </div>
                                            )) ||
                                                ""} */}
                                            <div className="cart-total__line cart-total__line--grand-total mt-3 gap-3">
                                                <span className="label">
                                                    <strong>{t("total", "TOTAL")}</strong>
                                                    <span className="d-flex fs-xs text-muted fw-normal">
                                                        {t("NoTaxesIncludedText", "Impuestos y gastos de envío no incluidos")}
                                                    </span>
                                                </span>
                                                <span className="value">
                                                    {/* <CurrencyFormat value={getItemsTotal()} /> */}
                                                    <CurrencyFormat value={getItemsTaxBase()} />
                                                </span>
                                            </div>
                                            <Button to={getCheckoutUrl()} className="w-100 mt-4" size="large">
                                                {t("startOrder", "Comenzar pedido")}
                                            </Button>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    )) || (
                        <EmptyCart />
                        // <div className="d-flex flex-column align-items-center justify-content-center h-100 py-6" style={{ minHeight: "75vh" }}>
                        //     <IllustrationEmptyCart className="mb-6" />
                        //     <p>{t("emptyCart", "Tu carrito está vacío")}</p>
                        // </div>
                    )}
                </div>
            </main>
            <Modal show={showTaxesModal} onHide={() => setShowTaxesModal(false)}>
                <Modal.Header title={t("whyISeeTaxesOnCartTitle", "¿Por qué se me aplican impuestos?")} />
                <Modal.Body>
                    <Trans i18nKey="whyISeeTaxesOnCartDescription">
                        <p>
                            Mostramos los impuestos correspondientes durante el proceso de compra <strong>a modo informativo</strong>.
                        </p>
                        <p>
                            Una vez selecciones tu dirección de facturación en la pantalla de <strong>Finalizar Compra</strong> se aplicarán, en el caso de que
                            proceda, los impuestos correspondientes.
                        </p>
                    </Trans>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ShoppingCartPage;
