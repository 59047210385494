import classnames from "classnames";
import React, { useContext } from "react";
import { CSSTransition } from "react-transition-group";
import ButtonClose from "../../abstract/ButtonClose/ButtonClose";

import "./Modal.scss";

const ModalContext = React.createContext();

function Modal({ show, onHide, className, size = "medium", fullscreen = false, backdrop = true, children, ...otherProps }) {
    const getClassName = () =>
        [
            "modal-dialog modal-dialog--scrollable",
            ...(size === "medium" ? ["modal--md"] : []),
            ...(size === "small" ? ["modal--sm"] : []),
            ...(size === "large" ? ["modal--lg"] : []),
            ...(fullscreen === true ? ["modal--fullscreen"] : []),
            ...(fullscreen === "sm-down" ? ["modal--fullscreen-sm-down"] : []),
            ...(fullscreen === "md-down" ? ["modal--fullscreen-md-down"] : []),
            ...(fullscreen === "lg-down" ? ["modal--fullscreen-lg-down"] : []),
            ...(fullscreen === "xl-down" ? ["modal--fullscreen-xl-down"] : []),
            ...(className ? [className.trim()] : []),
        ].join(" ");

    return (
        <ModalContext.Provider value={{ onHide }}>
            <>
                <CSSTransition
                    in={show}
                    unmountOnExit
                    timeout={{ enter: 0, exit: 300 }}
                    classNames={{
                        enterDone: "show",
                    }}
                >
                    <aside className="modal fade">
                        <div className={getClassName()} {...otherProps}>
                            <div className="modal-content">{children}</div>
                        </div>
                    </aside>
                </CSSTransition>
                {backdrop && (
                    <CSSTransition
                        in={show}
                        unmountOnExit
                        timeout={{ enter: 0, exit: 300 }}
                        classNames={{
                            enterDone: "show",
                        }}
                    >
                        <div onClick={onHide} style={{ cursor: "pointer" }} className="modal-backdrop fade"></div>
                    </CSSTransition>
                )}
            </>
        </ModalContext.Provider>
    );
}

function ModalHeader({ title, className, ...otherProps }) {
    const { onHide } = useContext(ModalContext);

    return (
        <div className={classnames("modal-header", className)} {...otherProps}>
            {(title && <h1 className="modal-title">{title}</h1>) || ""}
            <ButtonClose onClick={onHide} />
        </div>
    );
}

function ModalBody({ className, children, ...otherProps }) {
    return (
        <div className={classnames("modal-body", className)} {...otherProps}>
            {children}
        </div>
    );
}

function ModalFooter({ title, children, ...otherProps }) {
    return (
        <div className="modal-footer" {...otherProps}>
            {children}
        </div>
    );
}

Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;

export default Modal;
