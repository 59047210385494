import React, { useEffect } from "react";
import { t } from "i18next";
import useMainSearch from "../../../contexts/mainSearch/useMainSearch";
import Button from "../../abstract/Button/Button";
import { IconSearch } from "../../abstract/icons";
import LazyImg from "../../abstract/LazyImg/LazyImg";
import SearchForm from "../SearchForm/SearchForm";
import "./HomeHero.scss";
import LazyPicture from "../../abstract/LazyPicture/LazyPicture";

function HomeHero({ title, preTitle, bgImage, bgImageSources, machines = [] }) {
    const getImageProps = (sizes, alt) => ({ ...sizes.find((s) => s.name === "full"), alt });
    const { showModal: showSearchModal, machinesSet } = useMainSearch();

    useEffect(() => {
        if (machines && machines.length) {
            machinesSet(machines);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [machines]);

    return (
        // <section className="section-home-hero">
        <div className="section-home-hero">
            <div className="home-hero__content">
                <div className="container">
                    <hgroup className="text-center">
                        {preTitle && <span className="caption">{preTitle}</span>}
                        {title && <h1 className="display-5">{title}</h1>}
                    </hgroup>
                    <div className="home-hero__advanced-search pt-3 pt-md-6">
                        <SearchForm machines={machines} className="d-none d-md-block" />
                        <Button color="white" className="btn-modal-search-toggle d-md-none" iconBefore={<IconSearch />} onClick={showSearchModal}>
                            {t("searchInputPlaceholder", "Busca por código, producto...")}
                        </Button>
                    </div>
                </div>
            </div>
            {bgImage && (
                <div className="home-hero__bg">
                    {(bgImageSources && bgImageSources.xs && (
                        <LazyPicture
                            alt={bgImageSources.xs.alt}
                            url={bgImageSources.xs.src}
                            sources={[
                                { ...(bgImageSources.lg ? { srcSet: bgImageSources.lg.src, media: "(min-width: 1200px)" } : null) },
                                { ...(bgImageSources.md ? { srcSet: bgImageSources.md.src, media: "(min-width: 768px)" } : null) },
                            ]}
                        />
                    )) || <LazyImg {...getImageProps(bgImage.sizes, bgImage.alt || "")} />}
                </div>
            )}
        </div>
        // </section>
    );
}

export default HomeHero;
