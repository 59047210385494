import React from "react";
import useVisibility from "../../../contexts/visibility/useVisibility";
import "./LazyImg.scss";

function LazyImg({ url, srcSet, srcSets = [], width, height, alt, title, className, style = {} }) {
    const { setElement, isVisible } = useVisibility({
        threshold: 0.1,
    });

    return (
        <img
            ref={setElement}
            className={["lazyimg", ...(isVisible ? ["lazyloaded"] : [""]), ...(className ? [className] : [])].join(" ")}
            width={width}
            height={height}
            alt={alt}
            title={title}
            src={
                isVisible
                    ? url
                    : `data:image/svg+xml;base64,${btoa(`<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="${width}" height="${height}"></svg>`)}`
            }
            srcSet={
                isVisible
                    ? srcSet
                        ? srcSet
                        : srcSets.length && isVisible
                        ? srcSets.map((s) => (s ? (s.key ? `${s.value} ${s.key}` : s.value) : null)).join(", ")
                        : null
                    : null
            }
            style={{ ...style, opacity: isVisible ? style.opacity || 1 : 0 }}
        />
    );
}

export default LazyImg;
