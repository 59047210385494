import React from "react";
import { t } from "i18next";
import Button from "../../../abstract/Button/Button";
import { IconSearch } from "../../../abstract/icons";

function ContentListToolbarSearch({ searchValue, setSearchValue, submitSearch }) {
    return (
        <div className="toolbar__search flex-grow-1 align-items-center form-input-group">
            <Button
                variant="ghost"
                color="secondary"
                className="rounded-circle"
                iconBefore={<IconSearch />}
                onClick={(e) => {
                    e.preventDefault();
                    submitSearch();
                }}
            />
            <input
                value={searchValue}
                onChange={(e) => {
                    e.preventDefault();
                    setSearchValue(e.currentTarget.value);
                }}
                onKeyUp={(e) => e.key === "Enter" && submitSearch()}
                className="form-control"
                placeholder={t("searchInThisList", "Buscar en este listado...")}
                style={{ border: "none", appearance: "none", outline: "none" }}
            />
        </div>
    );
}

export default ContentListToolbarSearch;
