import React from "react";
import BaseIconSVG from "./BaseIconSVG";

function IconCreditCard(props) {
    return (
        <BaseIconSVG
            Icon={({ ...otherProps }) => (
                <svg {...otherProps}>
                    <path d="m0 0h24v24h-24z" fill="none" />
                    <path d="m20 4h-16c-1.11 0-1.99.89-1.99 2l-.01 12c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2v-12c0-1.11-.89-2-2-2zm0 14h-16v-6h16zm0-10h-16v-2h16z" />
                </svg>
            )}
            {...props}
        />
    );
}

export default IconCreditCard;
