import React, { useContext } from "react";
import { CSSTransition } from "react-transition-group";
import ButtonClose from "../../abstract/ButtonClose/ButtonClose";

import "./Offcanvas.scss";

const OffcanvasContext = React.createContext();

function Offcanvas({ show, onHide, className, backdrop = true, hideOnBackdropClick = true, position = "end", children, ...otherProps }) {
    return (
        <OffcanvasContext.Provider value={{ onHide }}>
            <>
                <CSSTransition
                    in={show}
                    unmountOnExit
                    timeout={{ enter: 0, exit: 300 }}
                    classNames={{
                        enterDone: "show",
                    }}
                >
                    <div className={["offcanvas", `offcanvas--${position}`, ...(className ? [className.trim()] : [])].join(" ")} {...otherProps}>
                        {children}
                    </div>
                </CSSTransition>
                {backdrop && (
                    <CSSTransition
                        in={show}
                        unmountOnExit
                        timeout={{ enter: 0, exit: 300 }}
                        classNames={{
                            enterDone: "show",
                        }}
                    >
                        <div
                            onClick={hideOnBackdropClick ? onHide : null}
                            style={hideOnBackdropClick ? { cursor: "pointer" } : null}
                            className="offcanvas-backdrop fade"
                        ></div>
                    </CSSTransition>
                )}
            </>
        </OffcanvasContext.Provider>
    );
}

function OffcanvasHeader({ title, subtitle, ...otherProps }) {
    const { onHide } = useContext(OffcanvasContext);

    return (
        <div className="offcanvas-header" {...otherProps}>
            <div className="offcanvas-header__heading">
                {(title && <h5 className="offcanvas-title">{title}</h5>) || ""}
                {(subtitle && <>{subtitle}</>) || ""}
            </div>
            <ButtonClose onClick={onHide} />
        </div>
    );
}

function OffcanvasBody({ children, className, ...otherProps }) {
    return (
        <div className={["offcanvas-body", ...(className ? [className.trim()] : [])].join(" ")} {...otherProps}>
            {children}
        </div>
    );
}

function OffcanvasFooter({ title, children, ...otherProps }) {
    return (
        <div className="offcanvas-footer" {...otherProps}>
            {children}
        </div>
    );
}

Offcanvas.Header = OffcanvasHeader;
Offcanvas.Body = OffcanvasBody;
Offcanvas.Footer = OffcanvasFooter;

export default Offcanvas;
