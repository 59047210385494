import React from "react";
import { t } from "i18next";
import Modal from "../../abstract/Modal/Modal";
import Button from "../../abstract/Button/Button";
import useShoppingCart from "../../../contexts/shoppingCart/useShoppingCart";
import { IconCheckCircle } from "../../abstract/icons";
import CartLine from "../../concret/CartLine/CartLine";
import Alert from "../../abstract/Alert/Alert";
import EmptyCart from "../../concret/EmptyCart/EmptyCart";
import SlideDown from "../../abstract/SlideDown/SlideDown";

function ShoppingCartModal() {
    const { lines, modalOpen, closeCartModal, getCartUrl } = useShoppingCart();

    return (
        <Modal show={modalOpen} onHide={closeCartModal} className="modal-shopping-cart" size="large" fullscreen="md-down">
            <Modal.Header />
            <Modal.Body className="pt-0">
                <SlideDown closed={!lines.length}>
                    <Alert color="success">
                        <div className="d-flex justify-content-center gap-2">
                            <IconCheckCircle className="flex-shrink-0" />
                            {t("productAddedToCart", "Producto añadido al carrito.")}
                        </div>
                    </Alert>

                    <div className="cart-list">
                        {lines.map((line, lineIndex) => (
                            <CartLine {...line} lineKey={line.key} key={`cart-line-${lineIndex}`} />
                        ))}
                    </div>
                </SlideDown>
                <SlideDown closed={lines.length}>
                    <EmptyCart />
                </SlideDown>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" color="secondary" onClick={closeCartModal} className="me-2">
                    {t("continueShipping", "Continúa Comprando")}
                </Button>
                <Button to={lines.length ? getCartUrl() : null} onClick={closeCartModal} isDisabled={!lines.length}>
                    {t("goToCart", "Ir al Carrito")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ShoppingCartModal;
