import React, { useEffect } from "react";
import { t } from "i18next";
import useLayout from "../../contexts/layout/useLayout";
import StripeCardPayment from "../../components/abstract/StripeCardPayment/StripeCardPayment";
import CartLineLite from "../../components/concret/CartLineLite/CartLineLite";
import CurrencyFormat from "../../components/concret/CurrencyFormat/CurrencyFormat";
import "./OrderPayPage.scss";

function OrderPayPage({ header, footer, breadcrumbs, order, payment }) {
    const { headerSet, footerSet } = useLayout();

    useEffect(
        () => headerSet(header),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [header]
    );
    useEffect(
        () => footerSet(footer),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [footer]
    );

    const { orderNumber, orderLines, currency, total, shippingAddress, itemsTaxBase, shippingServiceTaxBase } = order;

    return (
        <main className="main order-pay-page d-flex flex-column">
            <div className="container flex-grow-1 d-flex flex-column">
                <div className="shopping-cart-layout flex-grow-1">
                    <div className="shopping-cart-layout__main">
                        <h1>{t("orderXXXPay", "Pago Pedido {{orderNumber}}", { orderNumber })}</h1>
                        {payment.gatewaySlug === "stripe" && (
                            <div className="strype-card-payment-wrapper mt-8">
                                <StripeCardPayment clientSecret={payment.metas.clientSecret} returnUrl={payment.metas.returnUrl} />
                            </div>
                        )}
                    </div>
                    <div className="shopping-cart-layout__aside">
                        <div className="shopping-cart-aside-wrapper">
                            <section className="section-order-summary">
                                <div className="d-flex">
                                    <h2 className="title">{t("orderSummary", "Resumen del pedido")}</h2>
                                </div>
                                <div className="cart-list">
                                    {orderLines.map((line, linexIndex) => (
                                        <CartLineLite
                                            key={`cart-line-${linexIndex}`}
                                            product={line.product}
                                            regularTaxBase={line.unitRegularTaxBase * line.quantity}
                                            taxBase={line.totalTaxBase}
                                            quantity={line.quantity}
                                            currency={currency}
                                        />
                                    ))}
                                </div>
                            </section>
                            <section className="section-cart-totals">
                                <h2 className="title">{t("orderTotal", "Total Pedido")}</h2>
                                <div className="cart-total mt-3">
                                    <div className="cart-total__line">
                                        <span className="label">{t("subtotal", "Subtotal")}</span>
                                        <span className="value">
                                            <CurrencyFormat value={itemsTaxBase} currency={currency} />
                                        </span>
                                    </div>
                                    <div className="cart-total__line">
                                        <span className="label">
                                            {t("shippingCosts", "Gastos de envío")}
                                            <span className="d-flex fs-xs text-muted fw-normal">
                                                {t("shippingToCityPostalCodeCountry", "Envío a {{city}} {{postalCode}} {{countryCode}}", {
                                                    city: shippingAddress.city,
                                                    postalCode: shippingAddress.postalCode,
                                                    countryCode: shippingAddress.country.code,
                                                })}
                                            </span>
                                        </span>
                                        <span className="value">
                                            <CurrencyFormat value={shippingServiceTaxBase} currency={currency} />
                                        </span>
                                    </div>
                                    <div className="cart-total__line">
                                        <span className="label">{t("taxes", "Impuestos")}</span>
                                        <span className="value">
                                            <CurrencyFormat value={total - itemsTaxBase - shippingServiceTaxBase} currency={currency} />
                                        </span>
                                    </div>
                                    <div className="cart-total__line cart-total__line--grand-total">
                                        <span className="label">
                                            <strong>{t("total", "TOTAL")}</strong>
                                        </span>
                                        <span className="value">
                                            <CurrencyFormat value={total} currency={currency} />
                                        </span>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default OrderPayPage;
