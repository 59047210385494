import React from "react";
import useVisibility from "../../../contexts/visibility/useVisibility";
import "./LazyPicture.scss";

function LazyPicture({ url, srcSet, sources = [], width, height, alt, title, className, style = {} }) {
    const { setElement, isVisible } = useVisibility({
        threshold: 0.1,
    });
    return (
        <picture ref={setElement}>
            {sources.map((s, sIndex) => (
                <source
                    key={sIndex}
                    srcSet={
                        isVisible
                            ? s.srcSet
                            : width && height
                            ? `data:image/svg+xml;base64,${btoa(
                                  `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="${width}" height="${height}"></svg>`
                              )}`
                            : null
                    }
                    media={s.media}
                />
            ))}
            <img
                className={["lazyimg", ...(isVisible ? ["lazyloaded"] : [""]), ...(className ? [className] : [])].join(" ")}
                width={width}
                height={height}
                alt={alt}
                title={title}
                src={
                    isVisible
                        ? url
                        : `data:image/svg+xml;base64,${btoa(
                              `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="${width}" height="${height}"></svg>`
                          )}`
                }
                srcSet={
                    isVisible
                        ? srcSet
                        : width && height
                        ? `data:image/svg+xml;base64,${btoa(
                              `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="${width}" height="${height}"></svg>`
                          )}`
                        : null
                }
                style={{ ...style, opacity: isVisible ? style.opacity || 1 : 0 }}
            />
        </picture>
    );
}

export default LazyPicture;
