import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import PropTypes from "prop-types";

function CountryFlag({ className, countryCode }) {
    return (
        (countryCode && !["XX"].find(c => c === countryCode.trim().toUpperCase()) && (
            <ReactCountryFlag className={`country-flag rounded-1 ${className ? " " + className : ""}`} svg countryCode={countryCode.trim()} />
        )) || ""
    );
}

CountryFlag.propTypes = {
    className: PropTypes.string,
    countryCode: PropTypes.string
};

export default CountryFlag;