import React from "react";
import PropTypes from "prop-types";

import "./Avatar.scss";

function Avatar({ variant = "rounded", size = "medium", icon, user, bgColor, textColor, /*src,*/ children, className = null }) {
    const hRange = [0, 360];
    const sRange = [20, 80];
    const lRange = [78, 90];

    const getHashOfString = (str) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        hash = Math.abs(hash);
        return hash;
    };

    const normalizeHash = (hash, min, max) => {
        return Math.floor((hash % (max - min)) + min);
    };

    const generateHSL = (name) => {
        const hash = getHashOfString(name);
        const h = normalizeHash(hash, hRange[0], hRange[1]);
        const s = normalizeHash(hash, sRange[0], sRange[1]);
        const l = normalizeHash(hash, lRange[0], lRange[1]);
        return [h, s, l];
    };

    const HSLtoString = (hsl) => {
        return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
    };

    const generateColorStyles = (name) => {
        const bgCol = generateHSL(name);
        return {
            backgroundColor: HSLtoString(bgCol),
            color: HSLtoString([bgCol[0], bgCol[1], 20]),
        };
    };

    const getClassName = () =>
        `avatar ${size === "medium"
            ? ""
            : size === "small"
                ? " avatar--sm"
                : size === "large"
                    ? " avatar--lg"
                    : size === "xsmall"
                        ? " avatar--xs"
                        : size === "xxsmall"
                            ? " avatar--xxs"
                            : ""
        }${variant === " rounded" ? "" : variant === "circle" ? " rounded-circle" : ""} ${className ? className : ""}${textColor ? " text-" + textColor : ""}${bgColor ? " bg-" + bgColor : ""
        }`;

    return (
        (user && (
            <div className={getClassName()} style={generateColorStyles(user.userDisplayName || user.userInitials)}>
                {user.userInitials}
            </div>
        )) || <div className={getClassName()}>{icon || children}</div>
    );
}

Avatar.propTypes = {
    variant: PropTypes.oneOf(["rounded", "circle"]),
    size: PropTypes.oneOf(["xxsmall", "xsmall", "small", "medium", "large"]),
    icon: PropTypes.node,
    children: PropTypes.node,
    className: PropTypes.string,
};

export default Avatar;
