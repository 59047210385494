import React from "react";
import BaseIconSVG from "./BaseIconSVG";

function IconLinkedin(props) {
    return (
        <BaseIconSVG
            Icon={({ ...otherProps }) => (
                <svg {...otherProps}>
                    <path d="m4.86 2c-1.33 0-2.4 1.07-2.4 2.4s1.08 2.43 2.4 2.43 2.4-1.1 2.4-2.43-1.07-2.4-2.4-2.4zm11.72 6.36c-2.02 0-3.17 1.05-3.73 2.1h-.06v-1.82h-3.98v13.35h4.14v-6.61c0-1.74.13-3.43 2.29-3.43 2.12 0 2.16 1.99 2.16 3.54v6.5h4.14v-7.33c0-3.59-.77-6.3-4.97-6.3zm-13.79.28v13.35h4.14v-13.34h-4.15z" />
                </svg>
            )}
            {...props}
        />
    );
}

export default IconLinkedin;
