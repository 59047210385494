import React, { useEffect } from "react";
import MastHead from "../../components/abstract/MastHead/MastHead";
import useLayout from "../../contexts/layout/useLayout";
import "./StaticPage.scss";

function StaticPage({ header, footer, title, breadcrumbs = [], html }) {
    const { headerSet, footerSet } = useLayout();

    useEffect(
        () => headerSet(header),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [header]
    );
    useEffect(
        () => footerSet(footer),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [footer]
    );

    return (
        <main className="main static-page">
            <MastHead darkMode breadcrumbs={breadcrumbs} title={title} />
            <div className="container container--lg py-8 py-md-12">
                <div dangerouslySetInnerHTML={{ __html: html }}></div>
            </div>
        </main>
    );
}

export default StaticPage;
