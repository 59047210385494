import React, { useEffect, useRef, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import _ from "lodash";
import PropTypes from "prop-types";

import FormLabel from "../FormLabel";
import FormHelpText from "../FormHelpText";
import FormErrorMessage from "../FormErrorMessage";
import { getProperty } from "../../../../helpers/misc";
import { useFormikContext } from "formik";
import { IconCheck, IconChevronDown, IconClose } from "../../icons";
import FormikSelectContext from "./select/context/context";
import useFormikSelectContext from "./select/context/useFormikSelect";
import IconLoading from "../../icons/IconLoading";
import { t } from "i18next";

import "./FormikSelect.scss";

function FormikSelectSync({
    name,
    className = null,
    multiple = false,
    isClearable = true,
    label,
    labelHelp,
    size = "medium",
    required = false,
    validOnSubmit = false,
    validIfNoErrors = false,
    isLoading = false,
    disabled = false,
    placeholder = t("selectAnOption", "Select an option"),
    id = null,
    helpText = null,
    options = [],
    noOptionsMessage = t("noOptionsAvailableTryDifferentSearch", "No options available. Try with a different search."),
    endOptionsListMessage = t("youHaveReachedEndListing", "You have reached the end of this listing"),
    onBlur,
    onFocus,
    onChange,
}) {
    const [show, setShow] = useState(false);
    const [search, setSearch] = useState("");
    const [formControlFocus, formControlFocusSet] = useState(false);

    const wrapperRef = useRef();
    const searchRef = useRef();
    const fakeFormControlRef = useRef();
    const queryClient = new QueryClient();
    const { setFieldTouched, setFieldValue, errors, touched, values, submitCount } = useFormikContext();

    const getValue = () => {
        const optionsToFetch = [...options];
        if (multiple) {
            if (!values || !getProperty(name, values)) {
                return [];
            }
            return values
                ? getProperty(name, values) && getProperty(name, values).length
                    ? optionsToFetch.filter((o) => o.value === getProperty(name, values).find((v) => v === o.value))
                    : []
                : [];
        } else {
            let retVal = values
                ? "undefined" !== typeof getProperty(name, values)
                    ? optionsToFetch.find((o) => o.value === getProperty(name, values))
                    : ""
                : "";
            retVal = "undefined" !== typeof retVal ? retVal : "";
            return retVal;
        }
    };

    const searchChanged = (e) => {
        setSearch(e.currentTarget.value);
    };

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
                closeDropdown(true);
            }
        };
        if (show) {
            //if (!isTouchEnabled()) {
            window.setTimeout(() => {
                if (searchRef.current) {
                    searchRef.current.focus();
                }
            }, 30);
            //}
            document.addEventListener("click", handleClickOutside, { capture: true });
        } else {
            document.removeEventListener("click", handleClickOutside, { capture: true });
        }

        return () => {
            document.removeEventListener("click", handleClickOutside, { capture: true });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    const closeDropdown = (markAsTouched = false) => {
        if (markAsTouched) {
            if (!getProperty(name, touched)) {
                setFieldTouched(name, true);
            }
        }
        setShow(false);
        document.body.classList.remove("formik-select-dropdown-open");
        setSearch("");
        formControlFocusSet(false);
        if (onBlur) {
            onBlur();
        }
    };

    const openDropdown = () => {
        //window.setTimeout(() => { setShow(true); document.body.classList.add("formik-select-dropdown-open"); if (onFocus) { onFocus(); } }, 100);
        setShow(true);
        formControlFocusSet(true);
        document.body.classList.add("formik-select-dropdown-open");
        if (onFocus) {
            onFocus();
        }
    };

    const getSelectedOptions = () => {
        if (!multiple) {
            if (getValue() && options.find((o) => o.value === getValue().value)) {
                return [options.find((o) => o.value === getValue().value)];
            }
        } else {
            if (getValue()) {
                let selectedOptions = [];
                for (let val of getValue()) {
                    if (options.find((o) => o.value === val.value)) {
                        selectedOptions.push(val);
                    }
                }
                return selectedOptions;
            }
        }
        return [];
    };

    const setCurrentValue = (newValue) => {
        if (_.isEqual(getValue(), newValue)) {
            return;
        }
        if (!getProperty(name, touched)) {
            setFieldTouched(name, true, false);
        }
        setFieldValue(name, newValue);
        if (onChange) {
            onChange(newValue);
        }
    };

    const removeOption = (option) => {
        setCurrentValue(
            getValue()
                .filter((v) => v.value !== option.value)
                .map((v) => v.value)
        );
    };

    const hasValue = () => getSelectedOptions().length > 0;

    const hasError = () =>
        errors &&
        (getProperty(name, touched) || submitCount) &&
        getProperty(name, errors) &&
        getProperty(name, errors) &&
        "string" === typeof getProperty(name, errors)
            ? true
            : false;
    const showValid = () => (errors && ((submitCount && validOnSubmit) || validIfNoErrors) && !getProperty(name, errors) ? true : false);

    const emptyValue = () => {
        if (multiple) {
            setCurrentValue([]);
        } else {
            setCurrentValue("");
            // if (typeof getValue() === "string") {
            // }
            // else {
            //     setCurrentValue(null);
            // }
        }
    };

    const getDefaultSearchPlaceHolder = () => {
        let placeholder = "";
        if (getSelectedOptions().length) {
            return getSelectedOptions()
                .map((o) => o.label)
                .join(", ");
        }

        return placeholder;
    };

    const getMenuOptions = () => options;

    return (
        <FormikSelectContext.Provider value={{ options, closeDropdown, getValue }}>
            <div
                className={[
                    "formik-select-wrapper form-group ",
                    ...(formControlFocus ? ["form-group-focus"] : []),
                    ...(className ? [className.trim()] : []),
                ].join(" ")}
                ref={wrapperRef}
            >
                <div
                    className={[
                        "formik-select",
                        ...(show ? ["dropdown-open"] : ["dropdown-closed"]),
                        ...(hasError() ? ["is-invalid"] : []),
                        ...(showValid() ? ["is-valid"] : []),
                    ].join(" ")}
                >
                    <div className="form-label-select-wrapper" ref={fakeFormControlRef}>
                        <FormLabel id={id} required={required} labelHelp={labelHelp} onClick={() => closeDropdown(true)}>
                            {label}
                        </FormLabel>
                        {(!show && (
                            <div
                                className={[
                                    "form-select",
                                    ...(size === "small" ? ["form-select--sm"] : [""]),
                                    ...(size === "medium" ? [""] : [""]),
                                    ...(size === "large" ? ["form-select--lg"] : [""]),
                                    ...(disabled ? ["is-disabled"] : []),
                                ].join(" ")}
                            >
                                {(multiple && (
                                    <div
                                        className="form-select-multivalue-container"
                                        onClick={openDropdown}
                                        role="button"
                                        aria-haspopup="listbox"
                                        aria-expanded={show}
                                    >
                                        <input
                                            className={"form-control"}
                                            //readOnly={true}
                                            value={getDefaultSearchPlaceHolder()}
                                            placeholder={placeholder}
                                            onFocus={() => formControlFocusSet(true)}
                                            onBlur={() => formControlFocusSet(false)}
                                            onKeyDown={(e) => {
                                                if (["ArrowDown", "Enter"].indexOf(e.key) !== -1) {
                                                    e.preventDefault();
                                                    openDropdown();
                                                }
                                            }}
                                            style={getSelectedOptions().length ? { position: "absolute", width: 0, height: 0 } : {}}
                                        />
                                        {(getSelectedOptions().length && (
                                            <>
                                                {getSelectedOptions().map((o, i) => (
                                                    <div className="chip chip--light-primary" key={i}>
                                                        <div className="chip-label">{o.label}</div>
                                                        <button
                                                            type="button"
                                                            className=""
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                removeOption(o);
                                                            }}
                                                        >
                                                            <IconClose size="small" />
                                                        </button>
                                                    </div>
                                                ))}
                                            </>
                                        )) ||
                                            ""}
                                        {/* {(getSelectedOptions().length && (
                                            <>
                                                {getSelectedOptions().map((o, i) => (
                                                    <div className="chip chip--light-primary" key={i}>
                                                        <div className="chip-label">{o.label}</div>
                                                        <button
                                                            type="button"
                                                            className=""
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                removeOption(o);
                                                            }}
                                                        >
                                                            <IconClose size="small" />
                                                        </button>
                                                    </div>
                                                ))}
                                            </>
                                        )) || (
                                                <input
                                                    className={`form-control`}
                                                    readOnly={true}
                                                    value={getDefaultSearchPlaceHolder()}
                                                    placeholder={placeholder}
                                                    onFocus={() => formControlFocusSet(true)}
                                                    onBlur={() => formControlFocusSet(false)}
                                                    onKeyDown={(e) => { if (["ArrowDown", "Enter"].indexOf(e.key) !== -1) { e.preventDefault(); openDropdown(); } }}
                                                />
                                            )} */}
                                    </div>
                                )) || (
                                    // <input
                                    //     className="form-control"
                                    //     readOnly={false}
                                    //     value={getDefaultSearchPlaceHolder()}
                                    //     placeholder={placeholder}
                                    //     onClick={openDropdown}
                                    // />
                                    <div className="form-select-value-container">
                                        <div className="selected-value">
                                            {getSelectedOptions().map((o, i) => (
                                                <React.Fragment key={i}>{o.label}</React.Fragment>
                                            ))}
                                        </div>
                                        <div
                                            style={{
                                                position: "absolute",
                                                cursor: "text",
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                bottom: 0,
                                                zIndex: 3,
                                            }}
                                            onClick={openDropdown}
                                            aria-haspopup="listbox"
                                            aria-expanded={show}
                                        ></div>
                                        <input
                                            className={`form-control ${
                                                size === "medium" ? "" : size === "small" ? " form-control--sm" : size === "large" ? " form-control--lg" : ""
                                            }`}
                                            //readOnly={true}
                                            value=""
                                            placeholder={!hasValue() ? placeholder : ""}
                                            onFocus={() => formControlFocusSet(true)}
                                            onBlur={() => formControlFocusSet(false)}
                                            onKeyDown={(e) => {
                                                if (["ArrowDown", "Enter"].indexOf(e.key) !== -1) {
                                                    e.preventDefault();
                                                    openDropdown();
                                                }
                                            }}
                                        />
                                    </div>
                                )}

                                <div className="form-select-indicators">
                                    {isLoading && (
                                        <div className="form-select-loading-indicator">
                                            <IconLoading size="xsmall" />
                                        </div>
                                    )}
                                    {isClearable && !disabled && hasValue() && (
                                        <div className="form-select-clear-indicator">
                                            <IconClose size="small" onClick={emptyValue} role="button" />
                                        </div>
                                    )}

                                    <div className="form-select-arrow-indicator">
                                        <IconChevronDown onClick={openDropdown} />
                                    </div>
                                </div>
                                <div className="fake-form-control"></div>
                            </div>
                        )) || (
                            <div
                                className={[
                                    "form-select",
                                    ...(size === "small" ? ["form-select--sm"] : [""]),
                                    ...(size === "medium" ? [""] : [""]),
                                    ...(size === "large" ? ["form-select--lg"] : [""]),
                                    ...(disabled ? ["is-disabled"] : []),
                                ].join(" ")}
                            >
                                {(multiple && (
                                    <div className="form-select-multivalue-container">
                                        {(getSelectedOptions().length && (
                                            <>
                                                {getSelectedOptions().map((o, i) => (
                                                    <div className="chip chip--light-primary" key={i}>
                                                        <div className="chip-label">{o.label}</div>
                                                        <button type="button" className="" onClick={() => removeOption(o)}>
                                                            <IconClose size="small" />
                                                        </button>
                                                    </div>
                                                ))}
                                            </>
                                        )) ||
                                            ""}
                                        <input
                                            className={"form-control"}
                                            onChange={searchChanged}
                                            value={search}
                                            ref={searchRef}
                                            //placeholder={(!multiple && getDefaultSearchPlaceHolder() && getDefaultSearchPlaceHolder()) || t("typeToSearchPeriod", "Type to search...")}
                                            placeholder={!hasValue() ? t("typeToSearchPeriod", "Type to search...") : ""}
                                        />
                                    </div>
                                )) || (
                                    // <input
                                    //     className="form-control"
                                    //     onChange={searchChanged}
                                    //     value={search}
                                    //     ref={searchRef}
                                    //     placeholder={(!multiple && getDefaultSearchPlaceHolder()) || t("typeToSearchPeriod", "Type to search...")}
                                    // />
                                    <div className="form-select-value-container">
                                        {((!search || !search.length) && (
                                            <div className="selected-value">
                                                {getSelectedOptions().map((o, i) => (
                                                    <React.Fragment key={i}>{o.label}</React.Fragment>
                                                ))}
                                            </div>
                                        )) ||
                                            ""}

                                        <input
                                            className={`form-control ${
                                                size === "medium" ? "" : size === "small" ? " form-control--sm" : size === "large" ? " form-control--lg" : ""
                                            }`}
                                            onChange={searchChanged}
                                            //value={search}
                                            ref={searchRef}
                                            //placeholder={(!multiple && getDefaultSearchPlaceHolder() && getDefaultSearchPlaceHolder()) || t("typeToSearchPeriod", "Type to search...")}
                                            placeholder={!hasValue() ? t("typeToSearchPeriod", "Type to search...") : ""}
                                        />
                                    </div>
                                )}

                                <div className="form-select-indicators">
                                    {isClearable && !disabled && hasValue() && (
                                        <div className="form-select-clear-indicator">
                                            <IconClose size="small" onClick={emptyValue} role="button" />
                                        </div>
                                    )}

                                    <div className="form-select-arrow-indicator">
                                        <IconChevronDown onClick={() => closeDropdown(true)} role="button" />
                                    </div>
                                </div>
                                <div className="fake-form-control"></div>
                            </div>
                        )}
                    </div>
                    <FormHelpText>{helpText}</FormHelpText>
                    <FormErrorMessage visible={hasError()}>{getProperty(name, errors)}</FormErrorMessage>
                </div>
                {show && (
                    <QueryClientProvider client={queryClient}>
                        <FormikSelectDropdown
                            search={search}
                            setSearch={setSearch}
                            name={name}
                            multiple={multiple}
                            setCurrentValue={setCurrentValue}
                            options={getMenuOptions()}
                            inputWrapperRef={fakeFormControlRef}
                            noOptionsMessage={noOptionsMessage}
                            endOptionsListMessage={endOptionsListMessage}
                        />
                    </QueryClientProvider>
                )}
            </div>
        </FormikSelectContext.Provider>
    );
}

function FormikSelectDropdown({ name, multiple, search, setSearch, options, inputWrapperRef, noOptionsMessage, endOptionsListMessage, setCurrentValue }) {
    const { closeDropdown, getValue } = useFormikSelectContext();
    //const [bounds, setBounds] = useState(null);
    const dropdownHeight = 300;

    const scrollableWrapperRef = useRef();

    useEffect(() => {
        const scrollableWrapper =
            inputWrapperRef.current.closest(".is-scrollable") || inputWrapperRef.current.closest(".modal-dialog-scrollable .modal-body") || window;
        const resizeObserver = new window.ResizeObserver(() => {
            positionDropdown();
        });
        const positionDropdown = () => {
            const wrapperBounds = inputWrapperRef.current.getBoundingClientRect();

            let direction = "down";
            if (window.innerHeight - wrapperBounds.bottom < dropdownHeight && 0 > wrapperBounds.top - (dropdownHeight + 3)) {
                direction = "up";
            }
            const bounds = {
                left: wrapperBounds.left,
                width: inputWrapperRef.current.clientWidth,
            };
            if ("up" === direction) {
                bounds.top = wrapperBounds.top - (dropdownHeight + 3);
            } else {
                bounds.top = wrapperBounds.bottom + 3;
            }
            //setBounds(bounds);
        };
        positionDropdown();
        window.addEventListener("resize", positionDropdown);
        scrollableWrapper.addEventListener("scroll", positionDropdown);
        resizeObserver.observe(inputWrapperRef.current);

        return () => {
            window.removeEventListener("resize", positionDropdown);
            scrollableWrapper.removeEventListener("scroll", positionDropdown);
            resizeObserver.disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // const scrollParentToChild = (parent, child) => {

    //     // Where is the parent on page
    //     var parentRect = parent.getBoundingClientRect();
    //     // What can you see?
    //     var parentViewableArea = {
    //         height: parent.clientHeight,
    //         width: parent.clientWidth
    //     };

    //     // Where is the child
    //     var childRect = child.getBoundingClientRect();
    //     // Is the child viewable?
    //     var isViewable = (childRect.top >= parentRect.top) && (childRect.bottom <= parentRect.top + parentViewableArea.height);

    //     // if you can't see the child try to scroll parent
    //     if (!isViewable) {
    //         // Should we scroll using top or bottom? Find the smaller ABS adjustment
    //         const scrollTop = childRect.top - parentRect.top;
    //         const scrollBot = childRect.bottom - parentRect.bottom;
    //         let targetScrollTop = 0;
    //         if (Math.abs(scrollTop) < Math.abs(scrollBot)) {
    //             // we're near the top of the list
    //             //parent.scrollTop += scrollTop;
    //             targetScrollTop = parent.scrollTop + scrollTop;
    //         } else {
    //             // we're near the bottom of the list
    //             //parent.scrollTop += scrollBot;
    //             targetScrollTop = parent.scrollTop + scrollBot;
    //         }
    //         parent.scroll({
    //             top: targetScrollTop,
    //             behaviour: "smooth"
    //         });
    //     }

    // }

    // useEffect(() => {
    //     const positionDropdown = () => {
    //         const scrollableWrapper = inputWrapperRef.current.closest(".is-scrollable") || inputWrapperRef.current.closest(".modal-dialog-scrollable .modal-body") || document.querySelector("body");
    //         const scrollableWrapperRect = scrollableWrapper.getBoundingClientRect();
    //         const dropdownRect = scrollableWrapperRef.current.getBoundingClientRect();
    //         if (scrollableWrapperRect.height < dropdownRect.height + 100) {
    //             scrollableWrapperRef.current.style.maxHeight = `${scrollableWrapperRect.height - 100}px`;
    //         }
    //         const bottom = inputWrapperRef.current.clientHeight + 3;
    //         scrollableWrapperRef.current.style.transform = `translateY(${bottom}px)`;
    //         scrollParentToChild(scrollableWrapper, scrollableWrapperRef.current);
    //     };
    //     const resizeObserver = new window.ResizeObserver(() => {
    //         positionDropdown();
    //     });
    //     positionDropdown();
    //     resizeObserver.observe(inputWrapperRef.current);
    //     return () => {
    //         resizeObserver.disconnect();
    //     };
    // }, []);

    const handleOptionClick = (clickedOption) => {
        if (multiple) {
            if (getValue().find((v) => v.value === clickedOption.value)) {
                setCurrentValue(
                    getValue()
                        .filter((v) => v.value !== clickedOption.value)
                        .map((v) => v.value)
                );
            } else {
                setCurrentValue([...getValue().map((v) => v.value), clickedOption.value]);
                //setSearch("");
            }
        } else {
            setCurrentValue(clickedOption.value);
            closeDropdown();
        }
    };

    const isSelected = (option) => {
        if (multiple) {
            if (getValue().find((v) => v.value === option.value)) {
                return true;
            }
        } else {
            if (getValue() && getValue().value === option.value) {
                return true;
            }
        }

        return false;
    };

    const optionMatchesSearch = (option) => {
        return "string" === typeof option.search ? option.search.match(new RegExp(search, "i")) : option.label.match(new RegExp(search, "i"));
    };

    const handleOptionKeyDown = (event, option) => {
        if (-1 !== [" ", "SpaceBar", "Enter"].indexOf(event.key)) {
            event.preventDefault();
            handleOptionClick(option);
        } else if (-1 !== ["Escape"].indexOf(event.key)) {
            event.preventDefault();
            closeDropdown();
        }
    };

    return (
        <>
            <div
                className="select-dropdown-menu"
                // style={{ ...(bounds ? bounds : {}), minHeight: "0", maxHeight: `${dropdownHeight}px` }}
                style={{
                    position: "absolute",
                    inset: "0px auto auto 0px",
                    width: "100%",
                    transform: "translateY(73px)",
                }}
                ref={scrollableWrapperRef}
                role="listbox"
                tabIndex={-1}
                aria-activedescendant={getValue()}
            >
                {(options && options.length && options.filter((o) => (search && search.length ? optionMatchesSearch(o) : true)).length && (
                    <>
                        {options
                            .filter((o) => (search && search.length ? optionMatchesSearch(o) : true))
                            .map((o, i) => (
                                <button
                                    className={`select-dropdown-item ${isSelected(o) && "active"}`}
                                    key={"option-" + i}
                                    onKeyDown={(e) => handleOptionKeyDown(e, o)}
                                    onClick={() => handleOptionClick(o)}
                                    type="button"
                                    role="option"
                                    aria-selected={isSelected(o)}
                                    tabIndex={0}
                                >
                                    {o.renderedLabel || o.label}

                                    {isSelected(o) && (
                                        <div className="select-dropdown-item__meta">
                                            <IconCheck />
                                        </div>
                                    )}
                                </button>
                            ))}
                        {options.length > 7 &&
                            ((endOptionsListMessage && (
                                <div className="p-3 mt-2 border-top">
                                    <span className="d-block fs-xs text-muted">{endOptionsListMessage}</span>
                                </div>
                            )) ||
                                "")}
                    </>
                )) ||
                    (noOptionsMessage && (
                        <div className="p-3">
                            <span className="d-block fs-xs text-muted">{noOptionsMessage}</span>
                        </div>
                    )) ||
                    ""}
            </div>
        </>
    );
}
FormikSelectSync.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    labelHelp: PropTypes.string,
    size: PropTypes.oneOf(["small", "medium", "large"]),
    multiple: PropTypes.bool,
    required: PropTypes.bool,
    validOnSubmit: PropTypes.bool,
    validIfNoErrors: PropTypes.bool,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    isClearable: PropTypes.bool,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.any,
            search: PropTypes.any,
            renderedLabel: PropTypes.any,
            value: PropTypes.any,
        })
    ),
    className: PropTypes.string,
    placeholder: PropTypes.string,
    helpText: PropTypes.string,
    noOptionsMessage: PropTypes.string,
    endOptionsListMessage: PropTypes.string,
};

export default FormikSelectSync;
