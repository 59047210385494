import React from "react";
import ReactDOM from "react-dom/client";
import "lazysizes";
// import "./assets/scss/app-base.scss";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// if (process.env.NODE_ENV === "development") {
//     const root = ReactDOM.createRoot(document.getElementById("root"));
//     root.render(<App />);
// } else {
//     ReactDOM.hydrateRoot(document.getElementById("root"), <App />);
// }

if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// if (document.getElementById("root").getAttribute("data-hydrated")) {
//     ReactDOM.hydrateRoot(document.getElementById("root"), <App />);
// } else {
//     const root = ReactDOM.createRoot(document.getElementById("root"));
//     root.render(
//         <React.StrictMode>
//             <App />
//         </React.StrictMode>
//     );
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// React Render End
