import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import AnimateHeight from "react-animate-height";
import useClickOutside from "../../../contexts/clickOutside/useClickOutside";
import "./Dropdown.scss";
import Button from "../Button/Button";
function Dropdown({
    size,
    buttonAnchor,
    buttonToggleClassName,
    buttonOnClick,
    align = "start",
    show = false,
    setShow,
    autoClose = true,
    children,
    ...otherProps
}) {
    const [dropdownOpen, dropdownOpenSet] = useState(show);
    const wrapperRef = useRef(null);

    useEffect(() => {
        dropdownOpenSet(show);
    }, [show]);

    useClickOutside(wrapperRef, () => autoClose && (setShow ? setShow(false) : dropdownOpenSet(false)));

    return (
        <div className="dropdown" ref={wrapperRef} {...otherProps}>
            <Button
                onClick={() => (setShow ? setShow(!show) : dropdownOpenSet(!dropdownOpen))}
                // className={["dropdown-toggle", ...(dropdownOpen ? [] : ["collapsed"])].join(" ")}
                className={classnames(["dropdown-toggle", buttonToggleClassName, dropdownOpen ? "" : "collapsed"])}
                variant="ghost"
                color="secondary"
                size={size}
            >
                {buttonAnchor}
            </Button>
            <AnimateHeight
                height={dropdownOpen ? "auto" : 0}
                delay={0}
                duration={300}
                className="dropdown-menu"
                style={{ ...{ right: "end" === align ? 0 : null }, ...{ transition: "none" } }}
            >
                <div className="dropdown-menu-wrapper">{children}</div>
            </AnimateHeight>
        </div>
    );
}

Dropdown.propTypes = {
    buttonAnchor: PropTypes.string,
    buttonOnClick: PropTypes.func,
    align: PropTypes.oneOf(["start", "end"]),
    show: PropTypes.bool,
    setShow: PropTypes.func,
    autoClose: PropTypes.bool,
};

export default Dropdown;
