import { create } from "apisauce";
import history from "../../ui-events/history";

const apiClient = create({
    baseURL: process.env.REACT_APP_AUTH_API_BASE_URL,
    timeout: 150000,
    withCredentials: true
});

apiClient.addResponseTransform((response) => {
    if (!response.ok) {
        if (401 === response.status) {
            history.push("/auth/logout");
        } else if (403 === response.status) {
            // Swal.fire({
            //     icon: "danger",
            //     title: "No Autorizado",
            //     text: "Lo sentimos, pero no estas autorizado para llevar a cabo esta acción",
            // });
        }
    }
});

export default apiClient;
