import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as countriesApi from "../../../../../api/countries";
import CountryFlag from "../../../../abstract/CountryFlag/CountryFlag";
import FormikSelectSync from "../FormikSelectSync";
import { t } from "i18next";

function CountryDropdownLabel({ name, code }) {
    return (
        <div className="d-flex align-items-center gap-2">
            {code && <CountryFlag countryCode={code} />}
            <div className="text-truncate">{name}</div>
        </div>
    );
}
function CountryDropdownMultipleLabel({ name, code }) {
    return name;
}

function CountryDropdownRenderedLabel({ name, code }) {
    return (
        <div className="d-flex align-items-center gap-3">
            {code && (
                <div className="select-dropdown-item__graphic d-flex align-items-center">
                    <CountryFlag countryCode={code} />
                </div>
            )}
            <div className="select-dropdown-item__text">
                <div className="select-dropdown-item-primary__text">{name}</div>
            </div>
        </div>
    );
}

function CountryDropdown(props) {
    const { id, name, label, multiple = false, size, ...otherProps } = props;
    const [options, setOptions] = useState([]);
    const [isOptionsLoading, setIsOptionsLoading] = useState(false);

    const loadOptions = async () => {
        setIsOptionsLoading(true);
        const response = await countriesApi.getAll();
        setIsOptionsLoading(false);
        if (response.ok) {
            const loadedCountryOptions = [];
            for (let country of response.data) {
                loadedCountryOptions.push({
                    value: country.id,
                    label: multiple ? <CountryDropdownMultipleLabel {...country} /> : <CountryDropdownLabel {...country} />,
                    renderedLabel: <CountryDropdownRenderedLabel {...country} />,
                    search: country.name,
                });
            }
            loadedCountryOptions.sort((a, b) => Intl.Collator().compare(a.label, b.label));
            setOptions(loadedCountryOptions);
        }
    };

    useEffect(() => {
        loadOptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FormikSelectSync
            label={label}
            name={name}
            id={id}
            multiple={multiple}
            size={size}
            placeholder={isOptionsLoading ? t("loadingCountriesEllipse", "Loading countries...") : t("selectCountryEllipse", "Select a country...")}
            isLoading={isOptionsLoading}
            options={isOptionsLoading ? [] : options}
            //options={[{ value: "", label: isOptionsLoading ? "Cargando Países..." : multiple ? "" : "-- Selecciona un País --" }, ...options]}
            {...otherProps}
        />
    );
}

CountryDropdown.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    labelHelp: PropTypes.string,
    multiple: PropTypes.bool,
    size: PropTypes.oneOf(["small", "medium", "large"]),
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    isClearable: PropTypes.bool,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    helpText: PropTypes.string,
};

export default CountryDropdown;
