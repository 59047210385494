import React from "react";
import ImageGallery from "react-image-gallery";
// import "react-image-gallery/styles/scss/image-gallery.scss";
import useMediaQuery from "../../../contexts/mediaQuery/useMediaQuery";
import { lgDown } from "../../../helpers/mediaQueries";
import LazyImg from "../../abstract/LazyImg/LazyImg";
import "./ProductImageGallery.scss";

function ProductImageGallery({ images, productImages }) {
    const [isMobile] = useMediaQuery(lgDown);

    return (
        <ImageGallery
            thumbnailPosition={isMobile ? "bottom" : "left"}
            showPlayButton={false}
            showNav={false}
            items={productImages.map((image) => ({
                original: image.original.src,
                originalHeight: image.original.height,
                originalWidth: image.original.width,
                thumbnail: image.thumbnail.src,
                thumbnailHeight: image.thumbnail.height,
                thumbnailWidth: image.thumbnail.width,
                fullscreen: image.fullscreen.src,
                fullscreenHeight: image.fullscreen.height,
                fullscreenWidth: image.fullscreen.width,
                loading: "lazy",
            }))}
        />
    );
}

export default ProductImageGallery;
