import React from "react";
import { Link } from "react-router-dom";

function FooterNav({ items = [] }) {
    return (
        <div className="row">
            {items.map((item, itemIndex) => (
                <div className="col-lg py-4" key={`footerNavItem--${itemIndex}`}>
                    <h2 className="footer-links-title text-white">{item.name}</h2>
                    {(item.children.length && (
                        <ul className="nav footer-links-nav">
                            {item.children.map((childItem, childIndex) => (
                                <li className="nav-item" key={`footerNavItemChild--${childIndex}`}>
                                    {childItem.newWindow ? (
                                        <a href={childItem.url} title={childItem.name} className="nav-link" target="_blank" rel="nofollow noreferrer noopener">
                                            {childItem.name}
                                        </a>
                                    ) : (
                                        <Link to={childItem.url} title={childItem.name} className="nav-link">
                                            {childItem.name}
                                        </Link>
                                    )}
                                </li>
                            ))}
                        </ul>
                    )) ||
                        ""}
                </div>
            ))}
        </div>
    );
}

export default FooterNav;
