import React from "react";

function Container({ containerWidth, renderingTag, wrapperClassName, wrapperId, children }) {
    const WrappingEl = ({ Tag = "section", children, ...otherProps }) => {
        return <Tag {...otherProps}>{children}</Tag>;
    };

    return (
        <WrappingEl Tag={renderingTag || undefined} className={wrapperClassName} id={wrapperId}>
            {(containerWidth && <div className="container">{children}</div>) || children}
        </WrappingEl>
    );
}

export default Container;
