import classnames from "classnames";
import React from "react";

function IllustrationEmptyCart({ className }) {
    return (
        <svg fill="none" viewBox="0 0 276 236" width={200} xmlns="http://www.w3.org/2000/svg" className={classnames([className])}>
            <linearGradient id="a" gradientUnits="userSpaceOnUse" x1="140.817" x2="143.167" y1="-37.3495" y2="365.463">
                <stop offset={0} stopColor="#fff" stopOpacity={0} />
                <stop offset={1} stopColor="#d0d5dd" />
            </linearGradient>
            <linearGradient id="b" gradientUnits="userSpaceOnUse" x1="71.0005" x2="117.646" y1="122.5" y2="168.86">
                <stop offset={0} stopColor="#fff" />
                <stop offset={1} stopColor="#ced4dd" />
            </linearGradient>
            <linearGradient id="c" gradientUnits="userSpaceOnUse" x1="311.08" x2="206.579" y1="65.559" y2="67.5073">
                <stop offset={0} stopColor="#fff" />
                <stop offset={1} stopColor="#d0d5dd" />
            </linearGradient>
            <linearGradient id="d" gradientUnits="userSpaceOnUse" x1="50.7989" x2="229.552" y1="108.138" y2="110.274">
                <stop offset={0} stopColor="#eaecf0" />
                <stop offset={1} stopColor="#d0d5dd" />
            </linearGradient>
            <linearGradient id="e">
                <stop offset={0} stopColor="#fff" />
                <stop offset={1} stopColor="#eaecf0" />
            </linearGradient>
            <linearGradient id="f" gradientUnits="userSpaceOnUse" x1="96.3695" x2="126.709" xlinkHref="#e" y1="219.747" y2="220.227" />
            <linearGradient id="g">
                <stop offset={0} stopColor="#d0d5dd" />
                <stop offset={1} stopColor="#98a2b3" />
            </linearGradient>
            <linearGradient id="h" gradientUnits="userSpaceOnUse" x1="-.047591" x2="42.2492" xlinkHref="#g" y1="97.6979" y2="97.9725" />
            <linearGradient id="i" gradientUnits="userSpaceOnUse" x1="221.952" x2="264.249" xlinkHref="#g" y1="169.698" y2="169.972" />
            <linearGradient id="j" gradientUnits="userSpaceOnUse" x1="27.8081" x2="40.5004" xlinkHref="#g" y1="163.135" y2="150.907" />
            <linearGradient id="k" gradientUnits="userSpaceOnUse" x1="242.5" x2="41.9664" y1={91} y2="55.6755">
                <stop offset={0} stopColor="#c3c9d3" />
                <stop offset={1} stopColor="#d0d5dd" />
            </linearGradient>
            <linearGradient id="l" gradientUnits="userSpaceOnUse" x1="163.369" x2="193.709" xlinkHref="#e" y1="219.747" y2="220.227" />
            <path
                d="m141.701 229.402c63.348 0 114.701-51.353 114.701-114.701 0-63.3476-51.353-114.701-114.701-114.701-63.3476 0-114.701 51.3534-114.701 114.701 0 63.348 51.3534 114.701 114.701 114.701z"
                fill="url(#a)"
            />
            <path
                clipRule="evenodd"
                d="m139.423 169.606c0-4.738 3.841-8.579 8.579-8.579h49.774c6.801 0 13.302 2.013 18.308 5.692 5.003 3.678 8.975 9.462 8.975 16.64s-3.97 12.962-8.972 16.642c-5.005 3.683-11.507 5.699-18.311 5.699h-118.2695c-4.7379 0-8.5788-3.841-8.5788-8.579s3.8409-8.579 8.5788-8.579h118.2695c3.529 0 6.379-1.063 8.143-2.361 1.767-1.3 1.982-2.396 1.982-2.822 0-.424-.214-1.517-1.979-2.815-1.764-1.296-4.615-2.359-8.146-2.359l-50.276-1.685c-4.737 0-8.077-2.156-8.077-6.894z"
                fill="url(#b)"
                fillRule="evenodd"
            />
            <path
                clipRule="evenodd"
                d="m217.5 67.154c1.129-4.2194 4.952-7.154 9.32-7.154h39.021c5.328 0 9.647 4.3193 9.647 9.6474 0 5.3282-4.319 9.6475-9.647 9.6475h-31.615l-11.257 42.0721c-1.377 5.147-6.666 8.203-11.813 6.826s-8.203-6.666-6.826-11.813z"
                fill="url(#c)"
                fillRule="evenodd"
            />
            <path
                d="m108.077 235.364c11.542 0 20.9-9.357 20.9-20.9s-9.358-20.9-20.9-20.9c-11.543 0-20.9002 9.357-20.9002 20.9s9.3572 20.9 20.9002 20.9z"
                fill="#c5cad4"
            />
            <path
                d="m211.799 85.8708h-144.027c-10.7964 0-18.7712 9.1575-16.3297 18.7372l11.734 56.347c1.7656 6.919 8.5324 11.827 16.3297 11.827l126.994 8.218 21.663-76.367c5.337-15.633-5.542-18.7622-16.364-18.7622z"
                fill="url(#d)"
            />
            <path d="m107.5 225c5.799 0 10.5-4.701 10.5-10.5s-4.701-10.5-10.5-10.5-10.5 4.701-10.5 10.5 4.701 10.5 10.5 10.5z" fill="url(#f)" />
            <path
                d="m18.968 96.4608c.5176-2.144 3.5672-2.144 4.0848 0l1.7419 7.2162c.1849.766.7831 1.364 1.5493 1.549l7.216 1.742c2.1441.518 2.1441 3.567 0 4.085l-7.216 1.742c-.7662.185-1.3644.783-1.5493 1.549l-1.7419 7.216c-.5176 2.144-3.5672 2.144-4.0848 0l-1.7419-7.216c-.185-.766-.7832-1.364-1.5494-1.549l-7.21597-1.742c-2.14402-.518-2.14403-3.567 0-4.085l7.21597-1.742c.7662-.185 1.3644-.783 1.5494-1.549z"
                fill="url(#h)"
            />
            <path
                d="m240.968 168.461c.518-2.144 3.567-2.144 4.085 0l1.742 7.216c.185.766.783 1.364 1.549 1.549l7.216 1.742c2.144.518 2.144 3.567 0 4.085l-7.216 1.742c-.766.185-1.364.783-1.549 1.549l-1.742 7.216c-.518 2.144-3.567 2.144-4.085 0l-1.742-7.216c-.185-.766-.783-1.364-1.549-1.549l-7.216-1.742c-2.144-.518-2.144-3.567 0-4.085l7.216-1.742c.766-.185 1.364-.783 1.549-1.549z"
                fill="url(#i)"
            />
            <path
                d="m32.0142 157.053c-.333-1.306.8727-2.483 2.1703-2.118l8.7585 2.461c1.2976.365 1.7138 1.997.7492 2.939l-6.5107 6.354c-.9646.942-2.5865.486-2.9195-.82z"
                fill="url(#j)"
            />
            <rect fill="url(#k)" height={13} rx="6.5" width={189} x={45} y={84} />
            <path d="m174.57 235.364c11.543 0 20.9-9.357 20.9-20.9s-9.357-20.9-20.9-20.9-20.9 9.357-20.9 20.9 9.357 20.9 20.9 20.9z" fill="#c5cad4" />
            <path d="m174.5 225c5.799 0 10.5-4.701 10.5-10.5s-4.701-10.5-10.5-10.5-10.5 4.701-10.5 10.5 4.701 10.5 10.5 10.5z" fill="url(#l)" />
        </svg>
    );
}

export default IllustrationEmptyCart;
