import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import IconLoading from "../icons/IconLoading";
import { isTouchEnabled } from "../../../helpers/misc";
import { BUTTON_COLORS } from "../../../helpers/theme";

import "./Button.scss";

function Button({
    color = "primary",
    variant = "solid",
    size = "medium",
    ripple = true,
    //iconOnly = false,
    isLoading = false,
    isDisabled = false,
    href,
    to,
    children,
    className,
    iconAfter,
    iconBefore,
    target,
    rel,
    type = "button",
    onClick,
    ...otherProps
}) {
    const getClassName = () => {
        let variantColorClassName = `${variant !== "solid" ? variant + "-" : ""}${color !== "default" ? color : ""}`;
        if (variantColorClassName.length) {
            if ("-" === variantColorClassName.substring(variantColorClassName.length - 1)) {
                variantColorClassName = variantColorClassName.substring(0, variantColorClassName.length - 1);
            }
            variantColorClassName = `btn--${variantColorClassName}`;
        }

        return [
            "btn",
            ...(variantColorClassName.length ? [variantColorClassName] : []),
            ...(size === "small" ? ["btn--sm"] : []),
            ...(size === "large" ? ["btn--lg"] : []),
            ...(!children ? ["btn--icon-only"] : []),
            ...(children && iconBefore ? ["btn--icon-before"] : []),
            ...(children && iconAfter ? ["btn--icon-after"] : []),
            ...(className && className.length ? [className.trim()] : []),
            ...(isLoading ? ["is-loading"] : []),
        ].join(" ");
    };

    const handleClick = (event) => {
        if (showRipple()) {
            event.persist();
            const button = event.currentTarget;

            const circle = document.createElement("span");
            const diameter = Math.max(button.clientWidth, button.clientHeight);
            const radius = diameter / 2;

            circle.style.width = circle.style.height = `${diameter}px`;
            circle.style.left = `${event.clientX - button.offsetLeft - radius}px`;
            circle.style.top = `${event.clientY - button.offsetTop - radius}px`;
            circle.classList.add("button-ripple");

            const ripple = button.getElementsByClassName("button-ripple")[0];

            if (ripple) {
                ripple.remove();
            }

            button.appendChild(circle);
            if (onClick) {
                window.setTimeout(() => {
                    onClick(event);
                }, 100);
            }
            return;
        } else if (onClick) {
            onClick(event);
        }
    };

    const showRipple = () => (ripple ? true : null === ripple ? isTouchEnabled() : false);

    return href ? (
        <a
            onClick={handleClick}
            href={href}
            target={target}
            rel={"_blank" === target && null === rel ? "nofollow noopener noreferrer" : rel}
            className={getClassName()}
            {...otherProps}
        >
            {iconBefore && ((!children && iconBefore) || <span className="icon-left">{iconBefore}</span>)}
            {children}
            {iconAfter && ((!children && iconAfter) || <span className="icon-right">{iconAfter}</span>)}
            {(isLoading && <IconLoading className="ms-2" />) || ""}
        </a>
    ) : to ? (
        <Link disabled={isDisabled || isLoading || null} to={to} onClick={handleClick} className={getClassName()} {...otherProps}>
            {iconBefore && ((!children && iconBefore) || <span className="icon-left">{iconBefore}</span>)}
            {children}
            {iconAfter && ((!children && iconAfter) || <span className="icon-right">{iconAfter}</span>)}
            {(isLoading && <IconLoading className="ms-2" />) || ""}
        </Link>
    ) : (
        <button disabled={isDisabled || isLoading || null} type={type} onClick={handleClick} className={getClassName()} {...otherProps}>
            {iconBefore && ((!children && iconBefore) || <span className="icon-left">{iconBefore}</span>)}
            {children}
            {iconAfter && ((!children && iconAfter) || <span className="icon-right">{iconAfter}</span>)}
            {(isLoading && <IconLoading className="ms-2" />) || ""}
        </button>
    );
}

Button.propTypes = {
    //children: PropTypes.node,
    color: PropTypes.oneOf(BUTTON_COLORS),
    variant: PropTypes.oneOf(["solid", "light", "outline", "ghost", "link"]),
    size: PropTypes.oneOf(["small", "medium", "large"]),
    ripple: PropTypes.bool,
    isLoading: PropTypes.bool,
    isDisabled: PropTypes.bool,
    href: PropTypes.string,
    to: PropTypes.string,
    className: PropTypes.string,
    iconAfter: PropTypes.node,
    iconBefore: PropTypes.node,
    target: PropTypes.string,
    rel: PropTypes.string,
    type: PropTypes.string,
};

export default Button;
