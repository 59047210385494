import React from "react";
import SweetAlert2 from "react-sweetalert2";

function Dialog(props) {
    const canUseDOM = !!(typeof window !== "undefined" && window.document && window.document.createElement);
    return canUseDOM ? <DialogClientSide {...props} /> : null;
}

function DialogClientSide(props) {
    return <SweetAlert2 {...props} />;
}

export default Dialog;
