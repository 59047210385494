import React, { useEffect } from "react";
import { t } from "i18next";
import IllustrationError from "../../components/abstract/illustrations/IllustrationError";
import useLayout from "../../contexts/layout/useLayout";

function ErrorPage({ header, footer }) {
    const { headerSet, footerSet } = useLayout();

    useEffect(
        () => headerSet(header),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [header]
    );
    useEffect(
        () => footerSet(footer),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [footer]
    );

    return (
        <main className="main error-page">
            <div className="container h-100 d-flex flex-column align-items-center justify-content-center text-center py-4" style={{ minHeight: "90vh" }}>
                <IllustrationError />
                <h1>{t("error404", "Error 404")}</h1>
                <p className="lead">{t("error404Lead", "Ups! Parece que la página que estás buscando no existe.")}</p>
                <p>{t("error404Text", "Utilice el menú superior para navegar por nuestra página y encuentre lo que busca.")}</p>
            </div>
        </main>
    );
}

export default ErrorPage;
