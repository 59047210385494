import React from "react";
import BaseIconSVG from "./BaseIconSVG";

function IconCheckCircle(props) {
    return (
        <BaseIconSVG
            Icon={({ ...otherProps }) => (
                <svg {...otherProps}>
                    <g fillRule="evenodd">
                        <path
                            d="M12 20a8 8 0 100-16 8 8 0 000 16zm0 2C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10z"
                            fillRule="nonzero"
                        ></path>
                        <path d="M9.707 11.293a1 1 0 10-1.414 1.414l2 2a1 1 0 001.414 0l4-4a1 1 0 10-1.414-1.414L11 12.586l-1.293-1.293z"></path>
                    </g>
                </svg>
            )}
            {...props}
        />
    );
}

export default IconCheckCircle;
