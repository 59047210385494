import React from "react";
import PropTypes from "prop-types";

import "./FormRadio.scss";

function FormRadio(props) {
    const { label, id, name, className = "", inputClassName = "", size = "medium", checked = true, disabled = false, onChange, onClick, ...otherProps } = props;
    return (
        <div
            className={[
                "form-check",
                ...(className ? [className] : []),
                ...(size === "medium" ? [""] : []),
                ...(size === "small" ? ["form-check--sm"] : []),
                ...(size === "large" ? ["form-check--lg"] : []),
            ].join(" ")}
        >
            <input
                className={[
                    "form-check-input",
                    ...(inputClassName ? [inputClassName] : []),
                    ...(size === "medium" ? [""] : []),
                    ...(size === "small" ? ["form-check-input--sm"] : []),
                    ...(size === "large" ? ["form-check-input--lg"] : []),
                ].join(" ")}
                type="radio"
                id={id}
                name={name}
                checked={checked}
                disabled={disabled}
                onChange={onChange ? onChange : () => null}
                onClick={onClick}
                // readOnly={onChange ? null : true}
                {...otherProps}
            />
            {label && (
                <label {...(id && { htmlFor: id })} className={"form-check-label"} onClick={() => (onClick ? onClick() : !id && onChange ? onChange() : null)}>
                    {label}
                </label>
            )}
        </div>
    );
}

FormRadio.propTypes = {
    label: PropTypes.string,
    checked: PropTypes.bool,
    className: PropTypes.string,
    size: PropTypes.oneOf(["small", "medium", "large"]),
    disabled: PropTypes.bool,
};

export default FormRadio;
