import { format } from "date-fns";

const isTouchEnabled = () => "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

const getProperty = (propertyName, object) => {
    if (!object || !propertyName) {
        return null;
    }
    if ("undefined" !== typeof object[propertyName]) {
        return object[propertyName];
    }

    var parts = propertyName.split("."),
        length = parts.length,
        i,
        property = object || this;

    if (length < 2) {
        return null;
    }

    for (i = 0; i < length; i++) {
        // if (property && "undefined" !== property[parts[i]]) {
        // if (typeof property === "object" && parts && property && "undefined" !== typeof parts[i] && "undefined" !== typeof property[parts[i]]) {
        if (typeof property === "object" && "undefined" !== property[parts[i]]) {
            property = property[parts[i]];
        }
    }

    return property;
};

const getImagePropsFromDto = (imageDto) => {
    let props = { ...imageDto };
    props.url = props.src ?? null;
    if (props.srcSet && props.srcSet.length) {
        props.srcSet = props.srcSet.join(", ");
    }
    return props;
};

const getAssetUrl = (uri) => `${process.env.PUBLIC_URL}${uri}`;

const formatDate = (value) => format(new Date(value), "dd/MM/yyyy");

export { isTouchEnabled, getAssetUrl, getProperty, formatDate, getImagePropsFromDto };
