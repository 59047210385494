import { useContext } from "react";

import LayoutContext from "./context";

const useLayout = () => {
    const { headerSet, isMobileHeaderCollapsed, isMobileHeaderCollapsedSet, footerSet } = useContext(LayoutContext);

    return { headerSet, footerSet, isMobileHeaderCollapsed, isMobileHeaderCollapsedSet };
};

export default useLayout;
