import React from "react";
import Skeleton from "../../abstract/Skeleton/Skeleton";
import useProductStock from "../../../entities/products/hooks/useProductStock";
import classNames from "classnames";
import { Trans } from "react-i18next";
import { formatDate } from "../../../helpers/misc";
import useVisibility from "../../../contexts/visibility/useVisibility";

function ProductStockData({ stock, sku, showRestockDate = false, restockDate }) {
    return (
        <>
            {(stock !== null && <LiveProductStockData stock={stock} restockDate={showRestockDate ? restockDate : null} />) || (
                <LazyProductStockData sku={sku} showRestockDate={showRestockDate} />
            )}
        </>
    );
}

function LazyProductStockData({ sku, showRestockDate = false }) {
    const { setElement, isVisible } = useVisibility({
        threshold: 0.1,
    });

    return (
        <div className="card-product__stock mt-2" ref={setElement}>
            {(isVisible && <LazyProductStockDataElement sku={sku} showRestockDate={showRestockDate} />) || <Skeleton maxWidth="230px" height="22px" />}
        </div>
    );
}

function LazyProductStockDataElement({ sku, showRestockDate = false }) {
    const { data, isLoading } = useProductStock(sku);

    if (isLoading)
        return (
            <div className="card-product__stock mt-2">
                <Skeleton maxWidth="230px" height="22px" />
            </div>
        );

    if (data /*&& data.stock*/)
        return (
            <div className="card-product__stock mt-2">
                <LiveProductStockData stock={data.stock} restockDate={showRestockDate ? data.restockDate : null} />
            </div>
        );

    return null;
}

function LiveProductStockData({ stock, restockDate }) {
    if (stock && stock >= 1)
        return (
            <div className={classNames("cart-line-stock stock-info mt-1", stock < 4 ? "few-stock" : "in-stock")}>
                <UnitsInStockTrans stockCount={stock} />
            </div>
        );

    return (
        <>
            <div className="cart-line-stock stock-info out-of-stock mt-1">
                <Trans i18nKey="noStockWillShipASP">
                    <strong>Sin Stock</strong>. Te lo enviaremos cuando entre en Stock.
                </Trans>
            </div>
            {restockDate && (
                <div className="stock fs-xs mt-1">
                    <EstimatedReceptionDateTrans estimatedDate={formatDate(restockDate)} />
                    <br />
                </div>
            )}
        </>
    );
}

function UnitsInStockTrans({ stockCount }) {
    return stockCount > 1 ? (
        <Trans i18nKey="xxxUnitsInStock">
            <strong>{{ stockCount }}</strong> unidades en Stock
        </Trans>
    ) : (
        <Trans i18nKey="oneUnitInStock">
            <strong>1</strong> unidad en Stock
        </Trans>
    );
}

function EstimatedReceptionDateTrans({ estimatedDate }) {
    return (
        <Trans>
            Fecha estimada de entrada el <strong>{{ estimatedDate }}</strong>
        </Trans>
    );
}

export default ProductStockData;
