import classNames from "classnames";
import React from "react";
import { getAssetUrl } from "../../../helpers/misc";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import LazyImg from "../LazyImg/LazyImg";
import LazyPicture from "../LazyPicture/LazyPicture";
import "./MastHead.scss";

function MastHead({ darkMode, breadcrumbs = [], image = null, imageSources, title }) {
    const getImageProps = (sizes, alt) => ({ ...sizes.find((s) => s.name === "full"), alt });

    return (
        <div className={classNames(["page-masthead", darkMode ? "page-masthead--bg-dark" : null])}>
            <div className="page-masthead__content">
                <div className="container d-flex flex-column justify-content-center">
                    {(breadcrumbs && breadcrumbs.length && <Breadcrumbs darkMode={darkMode} breadcrumbs={breadcrumbs} />) || ""}
                    <h1 className="text-center">{title}</h1>
                </div>
            </div>
            <div className="page-masthead__bg">
                {(imageSources && imageSources.xs && (
                    <LazyPicture
                        alt={imageSources.xs.alt}
                        url={imageSources.xs.src}
                        sources={[
                            { ...(imageSources.lg ? { srcSet: imageSources.lg.src, media: "(min-width: 1200px)" } : null) },
                            { ...(imageSources.md ? { srcSet: imageSources.md.src, media: "(min-width: 768px)" } : null) },
                        ]}
                    />
                )) ||
                    (image && <LazyImg {...getImageProps(image.sizes, image.alt || "")} />) || (
                        <LazyImg url={getAssetUrl("/assets/img/masthead-bg.jpg")} alt="" width="2092" height="936" />
                    )}
            </div>
        </div>
    );
}

export default MastHead;
