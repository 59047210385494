import React from "react";

export default function useMouseOut(ref, callback) {
    const handleClick = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
            callback();
        }
    };
    React.useEffect(() => {
        document.addEventListener("mouseover", handleClick, { capture: true });
        return () => {
            document.removeEventListener("mouseout", handleClick, { capture: true });
        };
    });
}
