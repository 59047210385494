import React from "react";
import CardLathe from "../../abstract/CardLathe/CardLathe";
import Slider from "../../abstract/Slider/Slider";
import "./MachineSlider.scss";

function MachineSlider({ title, subtitle, lathes = [] }) {
    // const getImageProps = (sizes, alt) => ({ ...sizes.find((s) => s.name === "full"), alt });

    return (
        (lathes.length && (
            <>
                <div className="section-header pb-6">
                    <div className="container text-center">
                        {title && <h2 className="section-title">{title}</h2>}
                        {subtitle && <p>{subtitle}</p>}
                    </div>
                </div>
                <Slider>
                    <Slider.PreviousButton size="large" />
                    <Slider.Stage>
                        {lathes.map((lathe, latheIndex) => (
                            <div className="col-6 col-md-4 col-lg-4 col-xl-3" key={`machine-${latheIndex}`}>
                                <CardLathe {...lathe} />
                            </div>
                        ))}
                    </Slider.Stage>
                    <Slider.NextButton size="large" />
                </Slider>
            </>
        )) ||
        ""
    );
}

export default MachineSlider;
