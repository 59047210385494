import React, { useState } from "react";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import { getProperty } from "../../../../helpers/misc";
import IconEye from "../../icons/IconEye";
import IconEyeOff from "../../icons/IconEyeOff";
import IconCheck from "../../icons/IconCheck";
import FormErrorMessage from "../FormErrorMessage";
import FormHelpText from "../FormHelpText";
import FormLabel from "../FormLabel";
import IconCloseCircleFilled from "../../icons/IconCloseCircleFilled";

function FormikInput(props) {
    const {
        label,
        labelHelp,
        id,
        name,
        type = "text",
        // variant = "outlined",
        className,
        classNameInput,
        size = "medium",
        placeholder,
        required = false,
        validOnSubmit = false,
        validIfNoErrors = false,
        maxChars,
        autoComplete = false,
        helpText = null,
        isClearable = false,
        iconAfter = null,
        iconBefore = null,
        disabled = false,
        validationHints = [],
        onChange,
        clearValue,
        value = null,
        ...otherProps
    } = props;

    const { setFieldTouched, setFieldValue, errors, touched, values, submitCount } = useFormikContext();

    const [isEyeOpened, setIsEyeOpened] = useState(true);

    const getInputType = () => {
        if (type === "password" && !isEyeOpened) {
            return "text";
        }
        return type;
    };

    const handleEyeClick = (e) => {
        e.preventDefault();
        setIsEyeOpened(!isEyeOpened);
    };

    const handleClearClick = () => {
        if (!isClearable) {
            return;
        }
        if (clearValue) {
            clearValue();
        } else {
            setFieldValue(name, "");
        }
    };

    const setValue = (newValue) => {
        if (maxChars) {
            setFieldValue(name, newValue.substr(0, maxChars));
        } else {
            setFieldValue(name, newValue);
        }
    };

    const getValue = () => (value ? value : values ? (getProperty(name, values) ? getProperty(name, values) : "") : "");

    const hasError = () =>
        errors && (getProperty(name, touched) || submitCount) && getProperty(name, errors) && "string" === typeof getProperty(name, errors) ? true : false;
    const showValid = () => (errors && ((submitCount && validOnSubmit) || validIfNoErrors) && !getProperty(name, errors) ? true : false);

    return (
        <div
            className={[
                "form-group",
                ...(className ? [className] : []),
                ...(type === "hidden" ? ["mb-0"] : []),
                ...(hasError() ? ["is-invalid"] : []),
                ...(showValid() ? ["is-valid"] : []),
            ].join(" ")}
        >
            <FormLabel id={id} required={required} labelHelp={labelHelp}>
                {label}
            </FormLabel>
            <div className={["form-input-group", ...(type ? [`input-type-${type}`] : []), ...(disabled ? ["is-disabled"] : [])].join(" ")}>
                {iconBefore && (
                    <div className="input-group__prefix">
                        <span>{iconBefore}</span>
                    </div>
                )}
                <input
                    type={getInputType()}
                    className={[
                        "form-control",
                        ...(classNameInput ? [classNameInput] : []),
                        ...(hasError() ? ["is-invalid"] : []),
                        ...(showValid() ? ["is-valid"] : []),
                        ...(size === "small" ? ["form-control--sm"] : []),
                        ...(size === "large" ? ["form-control--lg"] : []),
                        // ...(variant === "filled" ? ["form-control--filled"] : []),
                    ].join(" ")}
                    id={id}
                    placeholder={placeholder}
                    name={name}
                    autoComplete={(!autoComplete && "off") || autoComplete}
                    disabled={disabled}
                    onBlur={() => setFieldTouched(name, true, false)}
                    onChange={(e) => (onChange ? onChange(e) : setValue(e.currentTarget.value))}
                    // value={value ? value : values ? (getProperty(name, values) ? getProperty(name, values) : "") : ""}
                    value={getValue()}
                    {...otherProps}
                />
                {(isClearable && getValue() !== "" && (
                    <div className="input-group__suffix">
                        <div className="btn btn-clear" onClick={handleClearClick}>
                            <IconCloseCircleFilled />
                        </div>
                    </div>
                )) ||
                    ""}
                {type === "password" && (
                    <div className="input-group__suffix">
                        <div className={["btn", "btn-password-toggle", ...(size === "small" ? ["btn--sm"] : [])].join(" ")} onClick={handleEyeClick}>
                            {(isEyeOpened && <IconEye />) || <IconEyeOff />}
                        </div>
                    </div>
                )}
                {iconAfter && (
                    <div className="input-group__suffix">
                        <span>{iconAfter}</span>
                    </div>
                )}

                <div className="fake-form-control"></div>
            </div>
            {(validationHints.length && (
                <div className="password-requirements mt-3">
                    <ul className="password-requirements-list list-unstyled">
                        {validationHints.map((validationHint, validationHintIndex) => (
                            <li
                                key={validationHintIndex}
                                className={`password-requirements-item d-flex ${
                                    values && getProperty(name, values) && getProperty(name, values).match(validationHint.regex) ? "completed" : ""
                                }`}
                            >
                                <div className="icon">
                                    <span className="dot"></span>
                                    <span className="check">
                                        <IconCheck />
                                    </span>
                                </div>
                                <div className="label">{validationHint.message}</div>
                            </li>
                        ))}
                    </ul>
                </div>
            )) ||
                ""}

            {((helpText || maxChars) && (
                <FormHelpText>
                    <span className="help-text">{helpText}</span>
                    {(maxChars && (
                        <span className="help-maxchars">
                            {getProperty(name, values) ? getProperty(name, values).length : 0} / {maxChars}
                        </span>
                    )) ||
                        ""}
                </FormHelpText>
            )) ||
                ""}

            <FormErrorMessage visible={hasError()}>{getProperty(name, errors)}</FormErrorMessage>
        </div>
    );
}

FormikInput.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    labelHelp: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    // variant: PropTypes.oneOf(["Default", "filled"]),
    size: PropTypes.oneOf(["small", "medium", "large"]),
    required: PropTypes.bool,
    validOnSubmit: PropTypes.bool,
    validIfNoErrors: PropTypes.bool,
    autoComplete: PropTypes.bool,
    isClearable: PropTypes.bool,
    maxChars: PropTypes.number,
    helpText: PropTypes.string,
    icon: PropTypes.node,
    disabled: PropTypes.bool,
    validationHints: PropTypes.arrayOf(
        PropTypes.shape({
            regex: PropTypes.string,
            message: PropTypes.string,
        })
    ),
};

export default FormikInput;
