import client from "./clients/auth";

const canUseDOM = !!(typeof window !== "undefined" && window.document && window.document.createElement);

const login = async (email, password, rememberme = false) => {
    return client.post("/auth", { email, password, rememberme });
};

const logout = async () => {
    //await client.delete("/auth");
    if (canUseDOM) {
        window.location.href = process.env.REACT_APP_AUTH_LOGOUT_URL + "?next=" + encodeURIComponent(window.location.href);
    }
};

const getLogoutUrl = () =>
    (canUseDOM && process.env.REACT_APP_AUTH_LOGOUT_URL + "?next=" + encodeURIComponent(window.location.href)) || process.env.REACT_APP_AUTH_LOGOUT_URL;

const getLoginUrl = (url = null) =>
    process.env.REACT_APP_AUTH_LOGIN_URL +
    (canUseDOM ? "?next=" + encodeURIComponent(url || window.location.href) : url ? "?next=" + encodeURIComponent(url) : "");

const me = async () => {
    return client.get("/users/me");
};

const myRoles = async () => {
    return client.get("/users/me/roles");
};

export { login, logout, me, myRoles, getLoginUrl, getLogoutUrl };
