import React from "react";
import BaseIconSVG from "./BaseIconSVG";

function IconSearch(props) {
    return (
        <BaseIconSVG
            Icon={({ ...otherProps }) => (
                <svg {...otherProps}>
                    <path
                        d="M16.436 15.085l3.94 4.01a1 1 0 01-1.425 1.402l-3.938-4.006a7.5 7.5 0 111.423-1.406zM10.5 16a5.5 5.5 0 100-11 5.5 5.5 0 000 11z"
                        fillRule="evenodd"
                    ></path>
                </svg>
            )}
            {...props}
        />
    );
}

export default IconSearch;
