import React, { Suspense } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import AppVirtualPageHandler from "./AppVirtualPageHandler";
import SplashScreen from "./components/abstract/SplashScreen/SplashScreen";
import { getHomeUri } from "./helpers/routes";

function AppRoutes() {
    const location = useLocation();

    return (
        <>
            <AddTrailingSlash />
            <Suspense fallback={<SplashScreen />}>
                <Routes>
                    <Route path="/" exact element={<Navigate to={getHomeUri()} />} />
                    <Route path="*" element={<AppVirtualPageHandler path={location.pathname} />} />
                </Routes>
            </Suspense>
        </>
    );
}

function AddTrailingSlash({ ...rest }) {
    const location = useLocation();

    // If the last character of the url is not '/'
    if (location.pathname.match(".*[^/]+$")) {
        return (
            <Navigate
                replace
                {...rest}
                to={{
                    pathname: location.pathname + "/",
                    search: location.search,
                }}
            />
        );
    } else return null;
}

export default AppRoutes;
