import React from "react";
import { Link } from "react-router-dom";
import "./Breadcrumbs.scss";

function Breadcrumbs({ breadcrumbs = [], darkMode = false, className }) {
    return (
        <nav className="breadcrumbs-wrapper" aria-label="breadcrumb">
            <ol
                className={["breadcrumb", ...(darkMode ? ["breadcrumb--dark"] : []), ...(className && className.length ? [className.trim()] : [])].join(" ")}
                itemScope=""
                itemType="http://schema.org/BreadcrumbList"
            >
                {breadcrumbs.map(({ active = false, name, url }, breadcrumbIndex) => (
                    <li
                        className={["breadcrumb-item", ...(active ? ["active"] : [])].join(" ")}
                        itemProp="itemListElement"
                        itemScope=""
                        itemType="http://schema.org/ListItem"
                        key={`breadcrumb-${breadcrumbIndex}`}
                    >
                        {(url && !active && (
                            <>
                                <Link itemProp="item" typeof="WebPage" to={url} title={name}>
                                    <span itemProp="name">{breadcrumbIndex > 0 ? name : "Home"}</span>
                                </Link>
                            </>
                        )) || (
                            <>
                                <span itemProp="name">{name}</span>
                                {url && <meta itemProp="item" itemScope="" itemType="https://schema.org/Thing" itemID={url} />}
                            </>
                        )}
                    </li>
                ))}
            </ol>
        </nav>
    );
}

Breadcrumbs.propTypes = {};

export default Breadcrumbs;
