import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as countriesApi from "../../../../../api/countries";
import FormikSelectSync from "../FormikSelectSync";
import { t } from "i18next";

function StateDropdown(props) {
    const { countryId, id, name, label, multiple = false, ...otherProps } = props;
    const [options, setOptions] = useState([]);
    const [isOptionsLoading, setIsOptionsLoading] = useState(false);

    useEffect(() => {
        const loadOptions = async () => {
            setOptions([]);
            if (!countryId) {
                return;
            }
            setIsOptionsLoading(true);
            const response = await countriesApi.getStates(countryId);
            setIsOptionsLoading(false);
            if (response.ok) {
                const loadedOptions = [];
                for (let state of response.data) {
                    loadedOptions.push({
                        value: state.id,
                        label: state.name,
                    });
                }
                loadedOptions.sort((a, b) => Intl.Collator().compare(a.label, b.label));
                setOptions(loadedOptions);
            }
        };
        loadOptions();
    }, [countryId]);

    return (
        <>
            {(options.length && !isOptionsLoading && (
                <FormikSelectSync
                    label={label}
                    name={name}
                    id={id}
                    multiple={multiple}
                    placeholder={isOptionsLoading ? t("loadingStatesEllipse", "Loading states...") : t("selectStateEllipse", "Select a state...")}
                    isLoading={isOptionsLoading}
                    options={isOptionsLoading ? [] : options}
                    {...otherProps}
                />
            )) ||
                ""}
        </>
    );
}

StateDropdown.propTypes = {
    countryId: PropTypes.number,
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    labelHelp: PropTypes.string,
    multiple: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    isClearable: PropTypes.bool,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    helpText: PropTypes.string,
};

export default StateDropdown;
