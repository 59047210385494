import React, { useContext, useEffect } from "react";
import useVisibility from "../../../../../contexts/visibility/useVisibility";
import IconLoading from "../../../../abstract/icons/IconLoading";
import ContentListContext from "../../context";

function ContentListPaginationClientSideLoader({ visible = true }) {
    const { setEndReach } = useContext(ContentListContext);
    const { setElement, isVisible } = useVisibility({
        threshold: 0.1,
        always: true,
    });

    useEffect(() => {
        setEndReach(isVisible ? true : false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible]);

    return (
        <div ref={setElement} className="p-4 justify-content-center align-items-center d-flex flex-column flex-grow-1" style={{ width: "100%" }}>
            {visible && <IconLoading size="xsmall" color="primary" />}
        </div>
    );
}

export default ContentListPaginationClientSideLoader;
