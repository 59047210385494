import React from "react";
import PropTypes from "prop-types";
import { BADGE_COLORS } from "../../../helpers/theme";

import "./Counter.scss";

function Counter({ color = "secondary", variant = "solid", children, className = null }) {
    const getClassName = () => {
        let badgeClassName = `counter${className ? " " + className : ""}`;
        badgeClassName += ` counter--${variant !== "solid" ? variant + "-" : ""}${color}`;
        return badgeClassName;
    };

    return <span className={getClassName()}>{children}</span>;
}

Counter.propTypes = {
    color: PropTypes.oneOf(BADGE_COLORS),
    variant: PropTypes.oneOf(["solid", "light"]),
};

export default Counter;
