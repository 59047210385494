import React from "react";
import PropTypes from "prop-types";

import "./FormCheckbox.scss";

function FormCheckbox(props) {
    const {
        label,
        id,
        name,
        isSwitch = false,
        className = "",
        inputClassName = "",
        size = "medium",
        state = "unchecked",
        disabled = false,
        required = false,
        onChange,
        ...otherProps
    } = props;
    return (
        <div
            className={[
                "form-check",
                ...(isSwitch ? ["form-switch"] : []),
                ...(className ? [className] : []),
                ...(size === "medium" ? [""] : []),
                ...(size === "small" ? ["form-check--sm"] : []),
                ...(size === "large" ? ["form-check--lg"] : []),
            ].join(" ")}
        >
            <input
                className={[
                    "form-check-input",
                    ...(inputClassName ? [inputClassName] : []),
                    ...(state === "semichecked" && !isSwitch ? ["indeterminate"] : []),
                    ...(size === "medium" ? [""] : []),
                    ...(size === "small" ? ["form-check-input--sm"] : []),
                    ...(size === "large" ? ["form-check-input--lg"] : []),
                ].join(" ")}
                type="checkbox"
                id={id}
                name={name}
                checked={state === "checked" ? true : false}
                disabled={disabled}
                onChange={onChange}
                readOnly={onChange ? null : true}
                {...otherProps}
            />
            {label && (
                <label
                    {...(id && { htmlFor: id })}
                    className={["form-check-label", ...(required ? ["is-required"] : [])].join(" ")}
                    onClick={() => (!id && onChange ? onChange() : null)}
                >
                    {label}
                </label>
            )}
        </div>
    );
}

FormCheckbox.propTypes = {
    label: PropTypes.string,
    state: PropTypes.oneOf(["checked", "unchecked", "semichecked"]),
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    size: PropTypes.oneOf(["small", "medium", "large"]),
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    isSwitch: PropTypes.bool,
};

export default FormCheckbox;
