import React from "react";
import { t } from "i18next";
import useLocalization from "../../../contexts/localization/useLocalization";
import IllustrationEmptyCart from "../../abstract/illustrations/IllustrationEmptyCart";

function EmptyCart({ style }) {
    const { currentLanguage } = useLocalization();

    return <EmptyCartLocalized currentLanguage={currentLanguage} style={style} />;
}

function EmptyCartLocalized({ style }) {
    return (
        <>
            <div className="d-flex flex-column align-items-center justify-content-center flex-grow-1 py-6" style={style}>
                <IllustrationEmptyCart className="mb-6" />
                <p>{t("emptyCart", "Tu carrito está vacío")}</p>
            </div>
        </>
    );
}

export default EmptyCart;
