import React from "react";
import HeaderMegaMenuItem from "./HeaderMegaMenuItem";

function HeaderMegaMenu({ ref, items = [], openBackdrop, closeBackdrop }) {
    return (
        <nav className="navbar navbar--dark" ref={ref}>
            <ul className="navbar-nav" onMouseEnter={openBackdrop} onMouseLeave={closeBackdrop}>
                {items.map((treeNode, treeNodeIndex) => (
                    <HeaderMegaMenuItem treeNode={treeNode} key={`megaMenuTreeNode-${treeNodeIndex}`} />
                ))}
                {/* <li className="navbar-nav-item d-flex align-items-center opacity-25">|</li>
                <li className="navbar-nav-item ms-auto">
                    <Link to="/" className="navbar-nav-link dropdown-toggle hidden-caret">
                        Busca por torno
                    </Link>

                    <div className="navbar-dropdown py-8 pb-10">
                        <div className="container">
                            <div className="row row-cols-md-6 g-2">
                                <div className="col">
                                    <CardLathe title="Serie TTL" />
                                </div>
                                <div className="col">
                                    <CardLathe title="Serie TA" />
                                </div>
                                <div className="col">
                                    <CardLathe title="Serie TC" />
                                </div>
                                <div className="col">
                                    <CardLathe title="Serie TX" />
                                </div>
                                <div className="col">
                                    <CardLathe title="Serie TTS" />
                                </div>
                                <div className="col">
                                    <CardLathe title="Serie TL" />
                                </div>
                                <div className="col">
                                    <CardLathe title="Serie TD Z2200-Z3200" />
                                </div>
                                <div className="col">
                                    <CardLathe title="Serie TD Z800-Z1350" />
                                </div>
                                <div className="col">
                                    <CardLathe title="Serie TBI-520" />
                                </div>
                                <div className="col">
                                    <CardLathe title="Serie TBI-480" />
                                </div>
                            </div>
                        </div>
                    </div>
                </li> */}
            </ul>
        </nav>
    );
}

export default HeaderMegaMenu;
