import client from "./clients/cms";

const get = async () => {
    return client.get("/cart/");
};

const getCurrencies = async () => {
    return client.get("/currencies/");
};

const setCurrency = async (slug) => {
    return client.put("/cart/", {
        currency: slug,
    });
};

const post = async () => {
    return client.post("/cart/");
};

const addLine = async (sku, quantity) => {
    return client.post("/cart-lines/", {
        sku,
        quantity,
    });
};

const updateLineQuantity = async (key, targetQuantity) => {
    return client.put("/cart-lines/" + key + "/", { quantity: targetQuantity });
};

const removeLine = async (key) => {
    return client.delete("/cart-lines/" + key + "/");
};

const setShippingAddress = async (id, addressType = null) => {
    return client.put("/cart/", {
        shippingAddress: id ? (addressType === "company" ? { companyId: id } : { id }) : null,
    });
};

const setBillingAddress = async (id, addressType = null) => {
    return client.put("/cart/", {
        billingAddress: id ? (addressType === "company" ? { companyId: id } : { id }) : null,
    });
};

const setBillingAddressIncorrect = async (billingAddressIncorrect) => {
    return client.put("/cart/", {
        billingAddressIncorrect,
    });
};

const setShippingService = async (slug) => {
    return client.put("/cart/", {
        shippingService: slug,
    });
};

const setPaymentGateway = async (slug) => {
    return client.put("/cart/", {
        paymentGateway: slug,
    });
};

const enablePartialShipping = async () => {
    return client.put("/cart/", {
        partialShippingCandidate: true,
    });
};

const disablePartialShipping = async () => {
    return client.put("/cart/", {
        partialShippingCandidate: false,
    });
};

const updateShippingComments = async (shippingComments) => {
    return client.put("/cart/", {
        shippingComments,
    });
};

const updateCustomOrderNumber = async (customOrderNumber) => {
    return client.put("/cart/", {
        customOrderNumber,
    });
};

export {
    get,
    getCurrencies,
    setCurrency,
    post,
    addLine,
    updateLineQuantity,
    removeLine,
    setShippingAddress,
    setBillingAddress,
    enablePartialShipping,
    disablePartialShipping,
    setShippingService,
    setPaymentGateway,
    updateShippingComments,
    updateCustomOrderNumber,
    setBillingAddressIncorrect,
};
