import React from "react";
import BaseIconSVG from "./BaseIconSVG";

function IconShoppingCart(props) {
    return (
        <BaseIconSVG
            Icon={({ ...otherProps }) => (
                <svg {...otherProps}>
                    <path d="m18.18 20.12c0-1.04.84-1.88 1.88-1.88s1.88.84 1.88 1.88c0 1.04-.84 1.88-1.87 1.88-1.04 0-1.88-.84-1.88-1.88z" />
                    <path d="m8.35 20.12c0-1.04.84-1.88 1.88-1.88s1.88.84 1.88 1.88c0 1.04-.84 1.88-1.88 1.88-1.03 0-1.87-.84-1.87-1.88z" />
                    <path d="m23.43 5.6c-.18-.23-.45-.36-.75-.36h-15.08l-.85-2.59c-.13-.39-.49-.65-.9-.66h-4.53c-.53 0-.95.43-.95.95s.43.95.95.95h3.84l.85 2.59 3.17 9.62c.13.39.49.65.9.65h10.37c.44 0 .83-.3.93-.73l2.23-9.62c.07-.28 0-.58-.18-.81zm-3.74 9.26h-8.93l-2.54-7.71h13.26z" />
                </svg>
            )}
            {...props}
        />
    );
}

export default IconShoppingCart;
