import { useContext } from "react";
import VirtualPageHandlerContext from "./context";

const useVirtualPageHandler = () => {
    const { setScrollToTopOnPageLoad, pageData } = useContext(VirtualPageHandlerContext);

    return {
        setScrollToTopOnPageLoad,
        pageData,
    };
};

export default useVirtualPageHandler;
