import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import "./SidebarNav.scss";
import "./SidebarFilters.scss";
import "./Chip.scss";
import "./ProductsListPage.scss";
import CardProduct from "../../components/abstract/CardProduct/CardProduct";

import { Link } from "react-router-dom";
import FormCheckbox from "../../components/forms/FormCheckbox/FormCheckbox";
import useLayout from "../../contexts/layout/useLayout";
import CategoriesNav from "../../components/concret/CategoriesNav/CategoriesNav";
import MastHead from "../../components/abstract/MastHead/MastHead";
import ContentList from "../../components/concret/ContentList/ContentList";
import useVirtualPageHandler from "../../contexts/virtualPageHandler/useVirtualPageHandler";
import { IconClose } from "../../components/abstract/icons";

function ProductsListPage({
    header,
    footer,
    breadcrumbs = [],
    categoriesNav,
    category,
    products = [],
    filters = [],
    resetFiltersUrl,
    page = 1,
    numFilters,
    totalCount,
    nextPageLink,
    previousPageLink,
    itemsPerPage,
    masthead,
}) {
    const { headerSet, footerSet } = useLayout();
    const { setScrollToTopOnPageLoad } = useVirtualPageHandler();
    const navigate = useNavigate();

    const getFilterChips = () => {
        const chips = [];

        for (let tax of filters) {
            if (tax.items && tax.items.length) {
                for (let filter of tax.items) {
                    if (filter && filter.selected && filter.name && filter.links && filter.links.grouped) {
                        chips.push({
                            name: filter.name,
                            link: filter.links.grouped,
                        });
                    }
                }
            }
        }

        return chips;
    };

    useEffect(
        () => headerSet(header),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [header]
    );
    useEffect(
        () => footerSet(footer),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [footer]
    );

    return (
        <main className="main">
            <MastHead
                darkMode
                breadcrumbs={breadcrumbs}
                title={(masthead && masthead.title) || category.name}
                image={(masthead && masthead.image) || null}
                imageSources={(masthead && masthead.sources) || null}
            />

            <div className="product-list sidebar-layout container">
                <aside className="product-list__sidebar sidebar py-8 d-none d-lg-flex flex-column">
                    {categoriesNav && <CategoriesNav categories={categoriesNav} className="sidebar-nav nav" />}
                    {filters &&
                        filters.map(
                            (filter, filterIndex) =>
                                (filter.items.length && (
                                    <div className="sidebar-filters" key={`advanced-filter-${filterIndex}`}>
                                        <h3>{filter.name}</h3>

                                        <div className="form-check-options filter-options">
                                            {filter.items.map((filterItem, filterItemIndex) => (
                                                <React.Fragment key={`advanced-filter-${filterIndex}-option-${filterItemIndex}`}>
                                                    {(!filterItem.links.grouped.follow && (
                                                        <span style={{ cursor: "pointer" }} onClick={() => navigate(filterItem.links.grouped.url)}>
                                                            <FormCheckbox
                                                                label={filterItem.name}
                                                                size="medium"
                                                                state={filterItem.selected ? "checked" : "unchecked"}
                                                            />
                                                        </span>
                                                    )) || (
                                                        <Link
                                                            to={filterItem.links.grouped.url}
                                                            rel={!filterItem.links.grouped.follow ? "nofollow" : null}
                                                            onClick={() => setScrollToTopOnPageLoad(false)}
                                                        >
                                                            <FormCheckbox
                                                                label={filterItem.name}
                                                                size="medium"
                                                                state={filterItem.selected ? "checked" : "unchecked"}
                                                            />
                                                        </Link>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                        </div>
                                    </div>
                                )) ||
                                ""
                        )}
                </aside>

                <div className="product-list__content main-content pt-4 pt-lg-8 pb-10">
                    {(getFilterChips().length && (
                        <div className="filter-tags d-none d-lg-block">
                            <ul className="list-unstyled d-flex flex-wrap gap-2">
                                {getFilterChips().map((chip, chipIndex) => (
                                    <li key={`chip-${chipIndex}`}>
                                        <div className="chip chip--light-secondary">
                                            <div className="chip-label">{chip.name}</div>
                                            <Link to={chip.link.url} rel={!chip.link.follow ? "nofollow" : null} className="chip-close-button">
                                                <IconClose size="small" />
                                            </Link>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )) ||
                        ""}
                    <ContentList
                        totalCount={totalCount}
                        nextPageLink={nextPageLink}
                        previousPageLink={previousPageLink}
                        page={page}
                        filters={filters}
                        resetFiltersUrl={resetFiltersUrl}
                        itemsPerPage={itemsPerPage}
                        numFilters={numFilters}
                        orderBy="default"
                        orderByOptions={[
                            {
                                name: t("orderByDefault", "Predeterminado"),
                                slug: "default",
                            },
                            {
                                name: t("orderByNameASC", "Nombre (A-Z)"),
                                slug: "name_asc",
                            },
                            {
                                name: t("orderByNameDESC", "Nombre (Z-A)"),
                                slug: "name_desc",
                            },
                        ]}
                        search=""
                    >
                        {products.map((product, productIndex) => (
                            <CardProduct product={product} key={`product-list--${productIndex}`} />
                        ))}
                    </ContentList>
                </div>
            </div>
        </main>
    );
}

export default ProductsListPage;
