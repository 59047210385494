import { useState, useEffect } from "react";

const defaultConfig = {
    root: null,
    threshold: 1.0,
    rootMargin: "0px",
    always: false,
};

const useVisibility = (options = {}, visible = false) => {
    let observer;
    const [isVisible, setIsVisible] = useState(visible);
    const [element, setElement] = useState(null);

    const forceVisible = () => {
        setIsVisible(true);
    };

    const forceCheck = () => {
        observer.unobserve(element);
        observer.observe(element);
    };

    const visibilityCallBack = ([entry]) => {
        if (options.always) {
            setIsVisible(entry.isIntersecting);
        } else if (entry.isIntersecting) {
            setIsVisible(true);
            observer.disconnect();
        }
    };

    useEffect(() => {
        if (visible) {
            forceVisible();
        }
    }, [visible]);

    useEffect(() => {
        if (!element) {
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer = new IntersectionObserver(visibilityCallBack, {
            ...defaultConfig,
            ...options,
        });
        observer.observe(element);
        return () => observer && observer.disconnect && observer.disconnect();
    }, [element, options.root, options.rootMargin, options.threshold]);

    return { setElement, isVisible, forceVisible, forceCheck };
};

export default useVisibility;
