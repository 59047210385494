import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { CancelToken } from "apisauce";
import * as urlsApi from "../../../../../api/urls";
import ContentListContext from "../../context";
import ContentListPaginationClientSideLoader from "./Loader";
import CardProduct from "../../../../abstract/CardProduct/CardProduct";

function ContentListPaginationClientSide() {
    const location = useLocation();
    const { endReach, items, setItems, paginationState, setPaginationState } = useContext(ContentListContext);
    const [orderBy, setOrderBy] = useState(paginationState.orderBy);
    const [search, setSearch] = useState(paginationState.search);
    const cancelTokenSource = useRef(null);

    const getNumLoadingSkeletons = () =>
        paginationState.page === 0
            ? paginationState.itemsPerPage
            : paginationState.totalCount - paginationState.page * paginationState.itemsPerPage > paginationState.itemsPerPage
            ? paginationState.itemsPerPage
            : paginationState.totalCount - paginationState.page * paginationState.itemsPerPage;

    const paginate = async () => {
        if (paginationState.page >= paginationState.totalPages || (paginationState.page > 0 && paginationState.totalCount < 1)) {
            return;
        }

        if (cancelTokenSource.current) {
            cancelTokenSource.current.cancel();
        }

        cancelTokenSource.current = CancelToken.source();

        setPaginationState({ ...paginationState, loading: true, totalCount: 0 === paginationState.page ? null : paginationState.totalCount });

        // *** Adding skeletons
        setItems([...(0 !== paginationState.page ? items : []), ...[...Array(getNumLoadingSkeletons()).keys()].map((c) => <CardProduct skeleton />)]);

        // *** Request new data
        const formData = new FormData();
        formData.append("action", "pagination");
        formData.append("page", paginationState.page + 1);
        formData.append("orderBy", paginationState.orderBy);
        formData.append("search", paginationState.search);

        const result = await urlsApi.post(location.pathname, formData, {
            cancelToken: cancelTokenSource.current.token,
        });

        // *** Removing skeletons and Adding real cards
        if (result.ok && result.data && result.data.items) {
            setItems([
                ...(0 !== paginationState.page ? items.filter((i) => !i.props.skeleton) : []),
                ...result.data.items.map((c) => <CardProduct product={c} />),
            ]);
        }

        setPaginationState({
            ...paginationState,
            totalCount: result.data.totalCount !== null ? result.data.totalCount : paginationState.totalCount,
            totalPages:
                result.data.totalCount !== null
                    ? Math.ceil(result.data.totalCount / paginationState.itemsPerPage) > 0
                        ? Math.ceil(result.data.totalCount / paginationState.itemsPerPage)
                        : 1
                    : paginationState.totalPages,
            page: paginationState.page + 1,
            loading: false,
        });
    };

    useEffect(() => {}, []);

    useEffect(() => {
        if (endReach && !paginationState.loading) {
            paginate();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endReach]);

    useEffect(() => {
        if (orderBy !== paginationState.orderBy) {
            setOrderBy(paginationState.orderBy);
            setPaginationState({ ...paginationState, page: 0 });
        } else if (search !== paginationState.search) {
            setSearch(paginationState.search);
            setPaginationState({ ...paginationState, page: 0 });
        } else if ((!paginationState.loading && endReach) || (!paginationState.loading && 0 === paginationState.page)) {
            paginate();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginationState]);

    useEffect(() => {
        if (!cancelTokenSource.current) {
            cancelTokenSource.current = CancelToken.source();
        }

        return () => {
            if (cancelTokenSource.current) {
                cancelTokenSource.current.cancel();
            }
        };
    }, []);

    return <ContentListPaginationClientSideLoader visible={paginationState.totalPages > paginationState.page && !paginationState.loading} />;
}

export default ContentListPaginationClientSide;
