import React from "react";
import PropTypes from "prop-types";

function FormLabel(props) {
    const { id, children, className, required, labelHelp, ...otherProps } = props;
    return (
        <>
            {children && (
                <label {...(id && { htmlFor: id })} className={`form-label ${className || ""} ${required ? "is-required" : ""}`} {...otherProps}>
                    {children}
                </label>
            )}
            {labelHelp && <span className="label-help-text float-end">{labelHelp}</span>}
        </>
    );
}

FormLabel.propTypes = {
    id: PropTypes.string,
    required: PropTypes.bool,
    labelHelp: PropTypes.node,
    children: PropTypes.node,
};

export default FormLabel;
