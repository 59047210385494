import React, { useState } from "react";
import PropTypes from "prop-types";
import { t } from "i18next";
import Button from "../Button/Button";
import "./CardProduct.scss";
import Badge from "../Badge/Badge";
import { Link } from "react-router-dom";
import useShoppingCart from "../../../contexts/shoppingCart/useShoppingCart";
import TagLatheSeries from "../../concret/TagLatheSeries/TagLatheSeries";
import LazyImg from "../LazyImg/LazyImg";
import Skeleton from "../Skeleton/Skeleton";
import classNames from "classnames";
import CurrencyFormat from "../../concret/CurrencyFormat/CurrencyFormat";
import IconShoppingCartAdd from "../icons/IconShoppingCartAdd";
import Dialog from "../Dialog/Dialog";
import { Trans } from "react-i18next";
import { formatDate, getImagePropsFromDto } from "../../../helpers/misc";
import ProductStockData from "../../../components/concret/ProductStockData/ProductStockData";
import useProductStock from "../../../entities/products/hooks/useProductStock";

function CardProduct({ product, layout = "default", skeleton = false }) {
    const [swalProps, setSwalProps] = useState({});
    const { addToCart, openCartModal } = useShoppingCart();
    const [isAddingToCart, setIsAddingToCart] = useState(false);
    const getImageProps = (sizes, alt) => {
        const keys = {
            small: "",
            medium: "2x",
        };

        let imageProps = {
            srcSets: [],
            // [
            //     sizes.find((s) => s.name === "small")
            //         ? {
            //               key: "",
            //               value: sizes.find((s) => s.name === "small").url,
            //           }
            //         : null,
            //     sizes.find((s) => s.name === "medium")
            //         ? {
            //               key: "2x",
            //               value: sizes.find((s) => s.name === "medium").url,
            //           }
            //         : null,
            // ],
            ...sizes.find((s) => s.name === "full"),
            alt,
        };

        for (let size of sizes) {
            if (keys[size.name]) {
                imageProps.srcSets.push({
                    key: keys[size.name],
                    value: size.url,
                });
            }
        }

        return imageProps;
    };

    const addToCartClick = async (e) => {
        setIsAddingToCart(true);
        const success = await addToCart(product, 1);
        setIsAddingToCart(false);
        if (success) {
            openCartModal();
        } else {
            setSwalProps({
                show: true,
                title: t("errorTitle", "Error"),
                text: t(
                    "errorAddingToCart",
                    "Se ha producido un error añadiendo el producto al carrito. Por favor, inténtalo de nuevo o contacta con nuestro Departamento de Atención al Cliente."
                ),
                icon: "error",
            });
        }
    };

    return (
        <>
            {(skeleton && (
                <article className={classNames("card-product card-product__skeleton", layout !== "default" ? "layout--" + layout : null)}>
                    <div className="card-product__media">
                        <Skeleton variant="square" />
                    </div>
                    <div className="card-product__body">
                        <Skeleton maxWidth="230px" height="1.75rem" />
                    </div>
                </article>
            )) || (
                <article className={classNames("card-product", layout !== "default" ? "layout--" + layout : null)}>
                    <div className={classNames("card-product__media", product.hasOldMainImage ? "old-main-image" : null)}>
                        <div className="card-product__tags">
                            {product.isNew && (
                                <Badge color="secondary" variant="solid">
                                    {t("newProductTag", "NEW!")}
                                </Badge>
                            )}
                        </div>
                        <Link to={product.url}>
                            <LazyImg className="img-fluid" {...getImagePropsFromDto(product.productImage)} />
                        </Link>
                        <div className="card-product__code">{product.sku}</div>
                    </div>

                    <div className="card-product__body">
                        <h2 className="card-product__title">
                            <Link to={product.url}>{product.title}</Link>
                        </h2>
                        <div className="tag-group d-flex gap-2 overflow-hidden">
                            {product.machines.map((machine, machineIndex) => (
                                <TagLatheSeries key={machineIndex}>{machine.shortName}</TagLatheSeries>
                            ))}
                        </div>
                        {!!product.purchasable && (
                            // <>{(product.stock !== null && <ProductStockData stock={product.stock} />) || <LazyProductStockData sku={product.sku} />}</>
                            <ProductStockData stock={product.stock} sku={product.sku} />
                        )}
                        <div className="card-product__actions">
                            {(product.purchasable && (
                                <>
                                    <div className="price">
                                        {(product.unitRegularTaxBase !== product.unitTaxBase && (
                                            <>
                                                <del>
                                                    <CurrencyFormat value={product.unitRegularTaxBase} />
                                                </del>
                                                <ins>
                                                    <CurrencyFormat value={product.unitTaxBase} />
                                                </ins>
                                            </>
                                        )) || <CurrencyFormat value={product.unitTaxBase} />}
                                    </div>
                                    <div className="d-none d-xl-block">
                                        <Button
                                            className="btn-add-to-cart"
                                            size="small"
                                            onClick={addToCartClick}
                                            isDisabled={isAddingToCart}
                                            isLoading={isAddingToCart}
                                        >
                                            {t("addToCart", "Añadir al carrito")}
                                        </Button>
                                    </div>

                                    <div className="d-block d-xl-none">
                                        <Button
                                            className="btn-add-to-cart"
                                            size="medium"
                                            onClick={addToCartClick}
                                            isDisabled={isAddingToCart}
                                            isLoading={isAddingToCart}
                                            iconBefore={<IconShoppingCartAdd />}
                                        />
                                    </div>
                                </>
                            )) || <span>{t("productNotForSale", "No disponible")}</span>}
                        </div>
                    </div>
                </article>
            )}
            <Dialog onConfirm={() => setSwalProps({ show: false })} {...swalProps} />
        </>
    );
}

// function LazyProductStockData({ sku }) {
//     const { data, isLoading, error } = useProductStock(sku);

//     if (isLoading)
//         return (
//             <div className="card-product__stock mt-2">
//                 <Skeleton maxWidth="230px" height="1.75rem" />
//             </div>
//         );

//     if (data && data.stock)
//         return (
//             <div className="card-product__stock mt-2">
//                 <ProductStockData stock={data.stock} />
//             </div>
//         );
// }

// function ProductStockData({ stock, restockDate = false }) {
//     return (
//         <>
//             {(stock && stock >= 1 && (
//                 <>
//                     <div className={classNames("cart-line-stock stock-info mt-1", stock < 4 ? "few-stock" : "in-stock")}>
//                         <UnitsInStockTrans stockCount={stock} />
//                     </div>
//                 </>
//             )) || (
//                 <>
//                     <div className="cart-line-stock stock-info out-of-stock mt-1">
//                         <Trans i18nKey="noStockWillShipASP">
//                             <strong>Sin Stock</strong>. Te lo enviaremos cuando entre en Stock.
//                         </Trans>
//                     </div>
//                     {restockDate && (
//                         <div className="stock fs-xs mt-1">
//                             <EstimatedReceptionDateTrans estimatedDate={formatDate(restockDate)} />
//                             <br />
//                         </div>
//                     )}
//                 </>
//             )}
//         </>
//     );
// }

// function UnitsInStockTrans({ stockCount }) {
//     return stockCount > 1 ? (
//         <Trans i18nKey="xxxUnitsInStock">
//             <strong>{{ stockCount }}</strong> unidades en Stock
//         </Trans>
//     ) : (
//         <Trans i18nKey="oneUnitInStock">
//             <strong>1</strong> unidad en Stock
//         </Trans>
//     );
// }

// function EstimatedReceptionDateTrans({ estimatedDate }) {
//     return (
//         <Trans>
//             Fecha estimada de entrada el <strong>{{ estimatedDate }}</strong>
//         </Trans>
//     );
// }

CardProduct.propTypes = {
    className: PropTypes.string,
};

export default CardProduct;
