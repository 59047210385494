import React, { useEffect } from "react";
import { t } from "i18next";
import Avatar from "../../components/abstract/Avatar/Avatar";
import Badge from "../../components/abstract/Badge/Badge";
import Breadcrumbs from "../../components/abstract/Breadcrumbs/Breadcrumbs";
import Button from "../../components/abstract/Button/Button";
import CurrencyFormat from "../../components/concret/CurrencyFormat/CurrencyFormat";
import DateFormat from "../../components/concret/DateFormat/DateFormat";
import useAuth from "../../contexts/auth/useAuth";
import useLayout from "../../contexts/layout/useLayout";
import "./MyAccountPage.scss";
import IconLoading from "../../components/abstract/icons/IconLoading";
import IconLogout from "../../components/abstract/icons/IconLogout";
import OrdersTable from "../../components/concret/OrdersTable/OrdersTable";

function MyAccountPage({ header, footer, orders, onlinePendinOrders, breadcrumbs = [] }) {
    const { headerSet, footerSet } = useLayout();
    const { userLoaded, user } = useAuth();

    useEffect(
        () => headerSet(header),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [header]
    );
    useEffect(
        () => footerSet(footer),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [footer]
    );

    return (
        <main className="my-account-page">
            <div className="container">
                <Breadcrumbs className="mt-3 mb-3 position-relative" breadcrumbs={breadcrumbs} />
            </div>
            <div className="sidebar-layout pt-lg-6 pb-14 container">
                <aside className="sidebar my-account-sidebar">
                    <h1 className="my-account-sidebar__title mb-3 d-none d-xl-block">{t("myAccount", "Mi cuenta")}</h1>
                    <div className="card-user d-flex gap-3">
                        {(user && (
                            <>
                                <Avatar
                                    size="medium"
                                    user={{
                                        userDisplayName: user.userDisplayName,
                                        userInitials: user.userInitials,
                                    }}
                                    variant="circle"
                                />

                                <div className="card-user__info flex-grow-1 d-flex flex-column align-items-start">
                                    <div className="user-name fw-semibold">{user.userDisplayName}</div>
                                    <Button size="small" variant="link" color="secondary" href={`mailto:${user.userEmail}`} className="user-email text-muted">
                                        {user.userEmail}
                                    </Button>
                                    <div className="divider border-top my-2"></div>
                                    <Button
                                        size="small"
                                        variant="link"
                                        color="secondary"
                                        iconBefore={<IconLogout />}
                                        className="ps-0"
                                        href={"https://my.cmz.com/logout?next=https%3A%2F%2Fstore.cmz.com%2F"}
                                    >
                                        {t("logout", "Cerrar Sesión")}
                                    </Button>
                                </div>
                            </>
                        )) ||
                            ""}
                    </div>
                    {/* 
                    <ul className="sidebar-nav">
                        <li className="nav-item active">
                            <Link to="/" className="nav-link active">
                                Pedidos
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/" className="nav-link">
                                Direcciones
                            </Link>
                        </li>
                    </ul> */}
                </aside>
                <div className="main-content">
                    <h1>{t("orders", "Pedidos")}</h1>
                    {(!orders.length && !onlinePendinOrders.length && <p>{t("noOrdersYet", "Aún no has hecho ningún pedido... ¡Anímate a comprar!")}</p>) || (
                        <>
                            {[
                                // {
                                //     title: t("checkoutYourOrder", "Finaliza tu Compra"),
                                //     orders: onlinePendinOrders,
                                // },
                                {
                                    title: null, //t("orders", "Pedidos"),
                                    orders: [...onlinePendinOrders, ...orders].sort((a, b) => (a.orderDate < b.orderDate ? 1 : -1)),
                                },
                            ].map((ordersGroup, ordersGroupIndex) => (
                                <OrdersTable {...ordersGroup} key={`orders-group-${ordersGroupIndex}`} />
                            ))}
                        </>
                    )}
                </div>
            </div>
        </main>
    );
}

export default MyAccountPage;
