import React from "react";
import BaseIconSVG from "./BaseIconSVG";

function IconPackage(props) {
    return (
        <BaseIconSVG
            Icon={({ ...otherProps }) => (
                <svg {...otherProps}>
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.5021 1.40276C10.9577 1.14026 11.4742 1.00208 12 1.00208C12.5258 1.00208 13.0424 1.14027 13.4979 1.4028C13.4986 1.4032 13.4993 1.4036 13.5 1.404L20.5 5.40399C20.87 5.61761 21.1891 5.90717 21.4369 6.25238C21.4973 6.31275 21.5508 6.3819 21.5956 6.4593C21.6342 6.52597 21.6642 6.59524 21.686 6.66583C21.8917 7.0793 21.9995 7.53551 22 7.999V16.0011C21.9995 16.5271 21.8606 17.0438 21.5973 17.4993C21.334 17.9548 20.9556 18.333 20.5 18.5961L20.4961 18.5983L13.5 22.5961C13.4994 22.5964 13.4988 22.5967 13.4982 22.5971C13.181 22.7799 12.8342 22.9025 12.4753 22.9601C12.3339 23.0366 12.172 23.08 12 23.08C11.828 23.08 11.6661 23.0366 11.5247 22.9601C11.1658 22.9025 10.8191 22.78 10.5019 22.5971C10.5012 22.5968 10.5006 22.5964 10.5 22.5961L3.50386 18.5983L3.5 18.5961C3.04439 18.333 2.66597 17.9548 2.40269 17.4993C2.13941 17.0438 2.00054 16.5271 2 16.0011V7.999C2.00048 7.53551 2.10832 7.07931 2.31402 6.66584C2.33581 6.59524 2.36583 6.52597 2.40439 6.4593C2.44917 6.3819 2.50272 6.31275 2.56308 6.25238C2.81094 5.90717 3.13002 5.61761 3.5 5.404L3.50386 5.40177L6.75258 3.54537C6.90017 3.37906 7.09618 3.27052 7.30632 3.22894L10.5021 1.40276ZM7.54999 5.39321L5.03899 6.82807L12 10.8548L14.5065 9.40482L7.54999 5.39321ZM16.5052 8.24866L9.5609 4.24412L11.4961 3.13827L11.5 3.13605C11.652 3.04828 11.8245 3.00208 12 3.00208C12.1755 3.00208 12.348 3.04828 12.5 3.13605L18.961 6.82807L16.5052 8.24866ZM20 8.53756L13 12.5868V20.5783L19.5 16.864C19.5005 16.8637 19.5011 16.8634 19.5016 16.8631C19.6527 16.7754 19.7783 16.6497 19.8658 16.4984C19.9535 16.3466 19.9998 16.1744 20 15.999V8.53756ZM11 20.5783V12.5868L4 8.53756V15.9994C4.00025 16.1746 4.04654 16.3467 4.13423 16.4984C4.22169 16.6497 4.34726 16.7754 4.49842 16.8631C4.49895 16.8634 4.49947 16.8637 4.5 16.864L11 20.5783Z"
                        fill="#344054"
                    />
                </svg>
            )}
            {...props}
        />
    );
}

export default IconPackage;
