import React, { useState } from "react";
import { t } from "i18next";
import { useElements, useStripe, Elements, PaymentElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Button from "../Button/Button";
// import SweetAlert2 from "react-sweetalert2";
import { CSSTransition } from "react-transition-group";
import SplashScreen from "../SplashScreen/SplashScreen";
import Dialog from "../Dialog/Dialog";

function StripeCardPayment({ clientSecret, returnUrl }) {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
    const options = {
        // passing the client secret obtained from the server
        clientSecret,
    };

    return (
        <Elements stripe={stripePromise} options={options}>
            <StripeCheckoutForm returnUrl={returnUrl} />
        </Elements>
    );
}

function StripeCheckoutForm({ returnUrl }) {
    const [swalProps, setSwalProps] = useState({});
    const [isProcessing, setIsProcessing] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const handleSubmit = async (event) => {
        setIsProcessing(true);
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: returnUrl,
            },
        });

        setIsProcessing(false);

        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            console.log(result.error.message);
            setSwalProps({
                show: true,
                title: "Error",
                text: result.error.message,
                icon: "error",
            });
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };
    return (
        <>
            <CSSTransition
                in={isProcessing}
                unmountOnExit
                timeout={{ enter: 0, exit: 300 }}
                classNames={{
                    enterDone: "show",
                }}
            >
                <SplashScreen show={true} light={true} />
            </CSSTransition>
            <form onSubmit={handleSubmit}>
                <PaymentElement />
                <Button disabled={!stripe} type="submit" className="btn-checkout-next-step my-4">
                    {t("pay", "Pagar")}
                </Button>
            </form>
            <Dialog onConfirm={() => setSwalProps({ show: false })} {...swalProps} />
        </>
    );
}

export default StripeCardPayment;
