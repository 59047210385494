import React, { useContext, useEffect, useState } from "react";
import { t } from "i18next";
import { Link, Navigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import classNames from "classnames";
// import SweetAlert2 from "react-sweetalert2";
import CheckoutPortletContext from "../../context";
import * as api from "../../../../../api/paymentGateways";
import * as ordersApi from "../../../../../api/orders";
import useShoppingCart from "../../../../../contexts/shoppingCart/useShoppingCart";
import Button from "../../../../abstract/Button/Button";
import SlideDown from "../../../../abstract/SlideDown/SlideDown";
import FormRadio from "../../../../forms/FormRadio/FormRadio";
import "./PaymentPortlet.scss";
import FormCheckbox from "../../../../forms/FormCheckbox/FormCheckbox";
import { IconBankTransfer, IconCreditCard } from "../../../../abstract/icons";
import SplashScreen from "../../../../abstract/SplashScreen/SplashScreen";
import Dialog from "../../../../abstract/Dialog/Dialog";
import Skeleton from "../../../../abstract/Skeleton/Skeleton";
import { Trans } from "react-i18next";

function PaymentPortlet({ mode = "edit" }) {
    return <PaymentPortletEdit />;
}

function PaymentPortletEdit() {
    const [swalProps, setSwalProps] = useState({});
    const { getPaymentGateway, setPaymentGateway, currency, restoreCart, getLegalUrl } = useShoppingCart();
    const { setIsCorrect } = useContext(CheckoutPortletContext);
    const [isLoading, setIsLoading] = useState(true);
    const [isProcessingCheckout, setIsProcessingCheckout] = useState(false);
    const [isSelecting, setIsSelecting] = useState(false);
    const [paymentGateways, setPaymentGateways] = useState([]);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [redirectTo, setRedirectTo] = useState(null);

    const loadPaymentGateways = async () => {
        setIsLoading(true);
        setIsCorrect(false);
        const response = await api.getAll();
        if (response.ok && response.data && response.data.length) {
            let newPaymentGateways = [];
            for (let add of response.data) {
                newPaymentGateways.push(add);
            }
            setPaymentGateways(newPaymentGateways);
        }
        setIsLoading(false);
    };

    const autoSelectPaymentGateways = async () => {
        if (!paymentGateways || !paymentGateways.length) {
            return;
        }

        const firstPaymentGateway = paymentGateways[0];
        if (firstPaymentGateway) {
            updatePaymentGateway(firstPaymentGateway);
        }
        setIsCorrect(true);
    };

    const updatePaymentGateway = async (gateway, skipIfNotEmpty = true) => {
        setIsSelecting(true);
        if (!skipIfNotEmpty || !getPaymentGateway()) {
            await setPaymentGateway(gateway.slug);
        }
        setIsSelecting(false);
    };

    const isGatewaySelected = (gateway) => (getPaymentGateway() && getPaymentGateway().slug === gateway.slug) || false;

    const processCheckout = async () => {
        setIsProcessingCheckout(true);
        const response = await ordersApi.create({
            currency: currency.code,
        });
        if (response && response.ok && response.data && response.data.redirect) {
            console.log("Redirecting to " + response.data.redirect);
            setRedirectTo(response.data.redirect);
            setTimeout(restoreCart, 300);
            // await restoreCart();
        } else {
            setSwalProps({
                show: true,
                title: t("error", "Error"),
                text: t(
                    "errorProcessingCheckout",
                    "Se ha producido un error procesando tu pedido. Por favor, inténtalo de nuevo o contacta con nuestro Departamento de Atención al Cliente."
                ),
                icon: "error",
            });
        }
        setIsProcessingCheckout(false);
    };

    useEffect(() => {
        autoSelectPaymentGateways();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentGateways]);

    useEffect(() => {
        loadPaymentGateways();
        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        (redirectTo && <Navigate to={redirectTo} />) || (
            <>
                <CSSTransition
                    in={isProcessingCheckout}
                    unmountOnExit
                    timeout={{ enter: 0, exit: 300 }}
                    classNames={{
                        enterDone: "show",
                    }}
                >
                    <SplashScreen show={true} light={true} />
                </CSSTransition>
                <SlideDown>
                    {(isLoading && (
                        <>
                            <Skeleton maxWidth="240px" height="1.5rem" />

                            <ul className="list-unstyled d-flex flex-column gap-3">
                                <li>
                                    <div className="mega-option is-selected">
                                        <div className="mega-option__control">
                                            <Skeleton variant="circular" height="1.25rem" width="1.25rem" className="mt-1" />
                                        </div>
                                        <label className="mega-option__label">
                                            <div className="mega-option__label__title">
                                                <Skeleton maxWidth="120px" height="1.875rem" />
                                            </div>
                                            <div className="mega-option__description">
                                                <Skeleton height="1.125rem" />
                                                <Skeleton height="1.125rem" />
                                            </div>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className="mega-option">
                                        <div className="mega-option__control">
                                            <Skeleton variant="circular" height="1.25rem" width="1.25rem" className="mt-1" />
                                        </div>
                                        <label className="mega-option__label">
                                            <div className="mega-option__label__title">
                                                <Skeleton maxWidth="120px" height="1.875rem" />
                                            </div>
                                        </label>
                                    </div>
                                </li>
                            </ul>
                        </>
                    )) || (
                        <>
                            <p>{t("selectPaymentMethod", "Selecciona una forma de pago:")}</p>

                            <ul className="list-unstyled d-flex flex-column gap-3">
                                {paymentGateways &&
                                    paymentGateways.length &&
                                    paymentGateways.map((gateway, gatewayIndex) => (
                                        <li key={`gateway-${gatewayIndex}`}>
                                            <div
                                                className={classNames("mega-option", isGatewaySelected(gateway) && "is-selected")}
                                                onClick={isGatewaySelected(gateway) ? null : () => updatePaymentGateway(gateway, false)}
                                                style={isGatewaySelected(gateway) ? {} : { cursor: "pointer" }}
                                            >
                                                <div className="mega-option__control">
                                                    <FormRadio disabled={isSelecting} checked={isGatewaySelected(gateway)} />
                                                </div>
                                                <label className="mega-option__label">
                                                    <div
                                                        className="mega-option__label__title"
                                                        onClick={isGatewaySelected(gateway) ? null : () => updatePaymentGateway(gateway, false)}
                                                        style={isGatewaySelected(gateway) ? {} : { cursor: "pointer" }}
                                                    >
                                                        {gateway.slug === "bank_transfer" && <IconBankTransfer />}
                                                        {gateway.slug === "stripe" && <IconCreditCard />}

                                                        {gateway.name}
                                                    </div>
                                                    <SlideDown closed={!isGatewaySelected(gateway)}>
                                                        <div
                                                            className="mega-option__description"
                                                            dangerouslySetInnerHTML={{ __html: gateway.checkoutDescription }}
                                                        ></div>
                                                    </SlideDown>
                                                </label>
                                            </div>
                                        </li>
                                    ))}
                            </ul>
                            <div className="mt-8 mb-4">
                                <div className="d-flex">
                                    <FormCheckbox checked={termsAccepted} onClick={() => setTermsAccepted(!termsAccepted)} style={{ cursor: "pointer" }} />
                                    <div>
                                        <Trans i18nKey="legalTermsLabel">
                                            He leído y acepto los
                                            <Link to={getLegalUrl()} target="_blank">
                                                términos y condiciones de uso
                                            </Link>
                                        </Trans>
                                    </div>
                                </div>
                                <Button
                                    onClick={processCheckout}
                                    isLoading={isProcessingCheckout}
                                    className="btn-checkout-next-step mt-4"
                                    size="large"
                                    isDisabled={!termsAccepted}
                                >
                                    {t("completeCheckout", "Finalizar pedido")}
                                </Button>
                            </div>
                        </>
                    )}
                </SlideDown>
                <SlideDown closed={isLoading || (paymentGateways && paymentGateways.length)}>
                    <p>
                        {t(
                            "unexpectedCheckoutError",
                            "Se ha producido un error cargando los métodos de pago. Por favor, recarga la página o reporta la incidencia a nuestro Departamento de Atención al Cliente."
                        )}
                    </p>
                </SlideDown>
                <Dialog onConfirm={() => setSwalProps({ show: false })} {...swalProps} />
            </>
        )
    );
}

export default PaymentPortlet;
