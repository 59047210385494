import React, { useContext, useEffect, useState } from "react";
import { t } from "i18next";
import CheckoutPortletContext from "./context";
import Button from "../../abstract/Button/Button";
import { IconCheck } from "../../abstract/icons";
import SlideDown from "../../abstract/SlideDown/SlideDown";
import "./CheckoutPortlets.scss";

const CheckoutPortletsContext = React.createContext();
// const CheckoutPortletContext = React.createContext();

function CheckoutPortlets({ initialCurrent, forceLinealFlow = false, children }) {
    const [portlets, setPortlets] = useState([]);
    // const [currentPortlet, setCurrentPortlet] = useState(null);

    useEffect(() => {
        const portletsToSet = [];
        if (children.length) {
            let currentSet = false;
            for (let child of children) {
                let portletStatus = !currentSet ? "completed" : "pending";
                if ((!currentSet && !initialCurrent) || initialCurrent === child.props.id) {
                    portletStatus = "current";
                    currentSet = true;
                }
                portletsToSet.push({
                    ...child.props,
                    id: child.props.id,
                    title: child.props.title || null,
                    nextStepButtonTitle: child.props.nextStepButtonTitle || t("continue", "Continuar"),
                    status: portletStatus,
                    showNextStepButton: child.props.showNextStepButton,
                    index: portletsToSet.length + 1,
                });
            }
            if (!currentSet && portletsToSet.length) {
                portletsToSet[0].status = "current";
            }
        }
        setPortlets(portletsToSet);
        // setCurrentPortlet(portletsToSet.find((p) => p.status === "current").id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //[children]);

    const markPortletAsCompleted = (portletId) => {
        let portletsToSet = [...portlets];
        let portletIndex = portletsToSet.indexOf(portletsToSet.find((p) => p.id === portletId));
        if (portletIndex > -1) {
            portletsToSet[portletIndex].status = "completed";
            for (let p in portletsToSet) {
                if (p > portletIndex && portletsToSet[p].status === "pending") {
                    portletsToSet[p].status = "current";
                    break;
                }
            }
            setPortlets(portletsToSet);
            // setCurrentPortlet(portletsToSet.find((p) => p.status === "current").id);
        } else {
            throw new Error(`${portletId} not found while marking portlet as completed`);
        }
    };

    const markPortletAsCurrent = (portletId) => {
        let portletsToSet = [...portlets];
        let portletIndex = portletsToSet.indexOf(portletsToSet.find((p) => p.id === portletId));
        if (portletIndex > -1) {
            if (forceLinealFlow) {
                for (let p in portletsToSet) {
                    if (p > portletIndex) {
                        portletsToSet[p].status = "pending";
                    }
                }
            }
            portletsToSet[portletIndex].status = "current";
            setPortlets(portletsToSet);
            // setCurrentPortlet(portletsToSet.find((p) => p.status === "current").id);
        } else {
            throw new Error(`${portletId} not found while marking portlet as current`);
        }
    };

    return (
        <CheckoutPortletsContext.Provider value={{ markPortletAsCompleted, markPortletAsCurrent }}>
            <div className="checkout-portlets">
                {portlets.map((p, pIndex) => (
                    <CheckoutPortlet
                        index={p.index}
                        id={p.id}
                        title={p.title}
                        status={p.status}
                        nextStepButtonTitle={p.nextStepButtonTitle}
                        showNextStepButton={p.showNextStepButton}
                        key={`checkout-portlet-${pIndex}`}
                    >
                        {p.children}
                    </CheckoutPortlet>
                ))}
            </div>
        </CheckoutPortletsContext.Provider>
    );
}

function CheckoutPortlet({
    id,
    title,
    status = "pending",
    nextStepButtonTitle = t("continue", "Continuar"),
    children,
    showNextStepButton = true,
    ...otherProps
}) {
    const [isClosed, setIsClosed] = useState(status === "pending" ? true : false);
    const [isCorrect, setIsCorrect] = useState(true);
    const { markPortletAsCurrent } = useContext(CheckoutPortletsContext);

    useEffect(() => {
        if (status !== "pending") {
            if (isClosed) {
                setIsClosed(false);
            }
        } else {
            if (!isClosed) {
                setIsClosed(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    return (
        <CheckoutPortletContext.Provider value={{ id, status, showNextStepButton, nextStepButtonTitle, isCorrect, setIsCorrect }}>
            <section className={`checkout-portlet checkout-portlet--${status}`}>
                <div className="checkout-portlet-header">
                    {(status === "completed" && (
                        <>
                            <div className="checkout-step-counter">
                                <span className="checkout-step-check">
                                    <IconCheck />
                                </span>
                            </div>

                            <h2 className="checkout-step-title">{title}</h2>
                            <Button onClick={() => markPortletAsCurrent(id)} variant="link" className="ms-auto fw-medium">
                                {t("edit", "Editar")}
                            </Button>
                        </>
                    )) || (
                        <>
                            <div className="checkout-step-counter">
                                <span className="checkout-step__number">{otherProps.index}</span>
                            </div>

                            <h2 className="checkout-step-title">{title}</h2>
                        </>
                    )}
                </div>
                <SlideDown closed={isClosed} unMountOnClose={true}>
                    <div className="checkout-portlet-body">{children}</div>
                </SlideDown>
            </section>
        </CheckoutPortletContext.Provider>
    );
}

function CheckoutPortletEdit({ children }) {
    const { markPortletAsCompleted } = useContext(CheckoutPortletsContext);
    const { id, status, showNextStepButton, nextStepButtonTitle, isCorrect } = useContext(CheckoutPortletContext);
    const [isClosed, setIsClosed] = useState(status === "current" ? false : true);

    useEffect(() => {
        if (status === "current") {
            setIsClosed(false);
        } else {
            setIsClosed(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    return (
        <SlideDown closed={isClosed} unMountOnClose={true}>
            <div className="checkout-portlet--body--edit">
                <div>{children}</div>
                {(showNextStepButton && (
                    <Button size="large" className="btn-checkout-next-step mb-3 mt-3" isDisabled={!isCorrect} onClick={() => markPortletAsCompleted(id)}>
                        {nextStepButtonTitle}
                    </Button>
                )) ||
                    ""}
            </div>
        </SlideDown>
    );
}

function CheckoutPortletDone({ children }) {
    const { status } = useContext(CheckoutPortletContext);
    const [isClosed, setIsClosed] = useState(status === "completed" ? false : true);

    useEffect(() => {
        if (status === "completed") {
            setIsClosed(false);
        } else {
            setIsClosed(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    return (
        <SlideDown closed={isClosed} unMountOnClose={true}>
            <div className="checkout-portlet--body--done">{children}</div>
        </SlideDown>
    );
}
CheckoutPortlet.Edit = CheckoutPortletEdit;
CheckoutPortlet.Done = CheckoutPortletDone;
CheckoutPortlets.Portlet = CheckoutPortlet;

export default CheckoutPortlets;
