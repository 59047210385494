const key = "shoppingCartKey";
const currency = "shoppingCartCurrency";

const storeKey = (cartKey) => {
    localStorage.setItem(key, cartKey);
};

const getKey = () => {
    return localStorage.getItem(key);
};

const removeKey = () => {
    localStorage.removeItem(key);
};

const storeCurrency = (cartCurrency) => {
    localStorage.setItem(currency, cartCurrency);
};

const getCurrency = () => {
    return localStorage.getItem(currency);
};

const removeCurrency = () => {
    localStorage.removeItem(currency);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { getKey, removeKey, storeKey, getCurrency, removeCurrency, storeCurrency };
