import client from "./clients/cms";

const getAll = async () => {
    return client.get("/addresses/");
};

const get = async (id) => {
    return client.get("/addresses/" + id + "/");
};

const add = async (data) => {
    return client.post("/addresses/", data);
};

const update = async (id, data) => {
    return client.put("/addresses/" + id + "/", data);
};

const remove = async (id) => {
    return client.delete("/addresses/" + id + "/");
};

export { get, getAll, update, remove, add };
