import React from "react";
import "./Form.scss";
function Form({ children, action = "#", ...props }) {
    return (
        <form action={action} {...props}>
            {children}
        </form>
    );
}

export default Form;
