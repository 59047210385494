import { useContext } from "react";
import AnalyticsContext from "./context";

const useAnalytics = () => {
    const { gtmId, uxId } = useContext(AnalyticsContext) || { gtmId: null, uxId: null };

    const init = () => {
        initUX();
        initGTM();
    };

    const getUXAddress = () =>
        process.env.NODE_ENV === "development"
            ? "//loc.ux.cmz.com/cmza.loc.js"
            : process.env.NODE_ENV === "testing"
            ? "//test.ux.cmz.com/cmza.test.js"
            : "//ux.cmz.com/cmza.js";

    const initGTM = () => {
        if (window.___gtmLoaded) return;
        window.___gtmLoaded = true;

        /* eslint-disable */
        (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
            var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != "dataLayer" ? "&l=" + l : "";
            j.async = true;
            j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
            f.parentNode.insertBefore(j, f);
        })(window, document, "script", "dataLayer", gtmId);
        /* eslint-enable */
    };

    const initUX = () => {
        if (window.___uxLoaded) return;
        window.___uxLoaded = true;

        /* eslint-disable */
        (function (c, m, z, l, a, t, h, e, s) {
            c["CMZAnalyticsObject"] = a;
            (c[a] =
                c[a] ||
                function () {
                    (c[a].q = c[a].q || []).push(arguments), c[a].handle_queue && c[a].handle_queue();
                }),
                (c[a].l = 1 * new Date());
            (t = m.createElement(z)), (h = m.getElementsByTagName(z)[0]);
            t.async = 1;
            t.src = l;
            h.parentNode.insertBefore(t, h);
        })(window, document, "script", getUXAddress(), "_cmza");
        _cmza("create", uxId);
        /* eslint-enable */
    };

    const trackPageView = () => {
        trackPageViewUX();
        trackPageViewGTM();
    };

    const trackPageViewGTM = () => {
        /* eslint-disable */
        if ("undefined" === typeof window.dataLayer) return;
        window.dataLayer.push({
            event: "pageview",
        });
        /* eslint-enable */
    };

    const trackPageViewUX = () => {
        /* eslint-disable */
        if ("undefined" === typeof _cmza) return;
        _cmza("send", "pageview");
        /* eslint-enable */
    };

    const trackAddToCart = (product, quantity = 1) => {
        trackAddToCartUX(product, quantity);
        trackAddToCartGTM(product, quantity);
    };

    const trackAddToCartGTM = (product, quantity) => {
        /* eslint-disable */
        if ("undefined" === typeof window.dataLayer) return;
        window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        window.dataLayer.push({
            event: "add_to_cart",
            ecommerce: {
                currency: "EUR",
                value: product.unitTaxBase * quantity,
                items: [
                    {
                        item_id: product.sku,
                        item_name: product.title,
                        discount: product.unitRegularTaxBase - product.unitTaxBase,
                        item_category: product.category ? product.category.name : null,
                        price: product.unitTaxBase,
                        quantity,
                    },
                ],
            },
        });
        /* eslint-enable */
    };

    const trackAddToCartUX = (product, quantity) => {
        /* eslint-disable */
        if ("undefined" === typeof _cmza) return;
        _cmza("send", "event", "Ecommerce", "Añadir al Carrito", `${quantity} x ${product.sku} - ${product.title}`, product.unitTaxBase * quantity);
        /* eslint-enable */
    };

    return {
        init,
        trackPageView,
        trackAddToCart,
    };
};

export default useAnalytics;
