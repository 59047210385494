import { useContext } from "react";
import ShoppingCartContext from "./context";
//import { IProduct, ICartLine } from "./models";
import * as api from "../../api/cart";
import cartStorage from "./storage";
import storage from "./storage";
import useAnalytics from "../analytics/useAnalytics";

const useShoppingCart = () => {
    const {
        updateCartData,
        checkoutData,
        lines,
        linesSet,
        currencies,
        currency,
        currencySet,
        numItems,
        offCanvasOpen,
        offCanvasOpenSet,
        modalOpen,
        modalOpenSet,
        endpoints,
        endpointsSet,
        isCartLoading,
        isCartLoadingSet,
    } = useContext(ShoppingCartContext);

    const { trackAddToCart } = useAnalytics();

    const restoreCart = async () => {
        if (cartStorage.getKey()) {
            isCartLoadingSet(true);
            const result = await api.get();
            if (result.ok && result.data) {
                updateCartData(result.data);
                if (result.data.cartLines) {
                    linesSet(result.data.cartLines);
                }
                if (result.data.endpoints) {
                    endpointsSet(result.data.endpoints);
                }
            } else {
                await createCart();
            }
            isCartLoadingSet(false);
        }
    };

    const openCartOffCanvas = () => offCanvasOpenSet(true);
    const closeCartOffCanvas = () => offCanvasOpenSet(false);
    const toggleCartOffCanvas = () => offCanvasOpenSet(!offCanvasOpen);

    const openCartModal = () => modalOpenSet(true);
    const closeCartModal = () => modalOpenSet(false);
    const toggleCartModal = () => modalOpenSet(!modalOpen);

    const getLines = () => (typeof lines !== "object" ? [] : lines);

    const getCartUrl = () => (endpoints && endpoints.cart) || null;
    const getMyAccountUrl = () => (endpoints && endpoints.myAccount) || null;
    const getCheckoutUrl = () => (endpoints && endpoints.checkout) || null;
    const getLegalUrl = () => (endpoints && endpoints.legal) || null;

    const getEtalonCurrency = () => currencies.find((c) => c.isEtalon);

    const changeCurrency = (code) => {
        const newCurrency = currencies.find((c) => c.code === code);
        if (newCurrency) {
            currencySet(newCurrency);
            storage.storeCurrency(newCurrency.code);
            api.setCurrency(newCurrency.code);
        }
    };

    // const getCartLineKey = (sku, args = {}) => {
    //     const indexes = [sku];
    //     for (let key of Object.keys(args)) {
    //         indexes.push((key + "").trim() + "_" + (args[key] + "").trim());
    //     }
    //     return btoa(indexes.join("__")).replace("==", "");
    // };

    // const getCartNumItems = (cartLines) => {
    //     if (!cartLines) {
    //         cartLines = [...getLines()];
    //     }
    //     let count = 0;
    //     for (let line of cartLines) {
    //         count += line.quantity;
    //     }
    //     return count;
    // };

    // const updateLine = (lineKey, quantity) => {
    //     let line = getLines().find((l) => l.lineKey === lineKey);
    //     if (line) {
    //         const lineIndex = getLines().indexOf(line);
    //         let targetLines = [...getLines()];
    //         targetLines[lineIndex].quantity = quantity;
    //         linesSet(targetLines);
    //         numItemsSet(getCartNumItems(targetLines));
    //     } else throw "Unable to update cart line. Key " + lineKey + " not found";
    // };

    // const removeLine = (lineKey) => {
    //     let line = getLines().find((l) => l.lineKey === lineKey);
    //     if (line) {
    //         const lineIndex = getLines().indexOf(line);
    //         const targetLines = getLines().filter((l) => l.lineKey !== lineKey);
    //         linesSet(targetLines);
    //         numItemsSet(getCartNumItems(targetLines));
    //     } else throw "Unable to delete cart line. Key " + lineKey + " not found";
    // };

    // const addLine = async (item, quantity = 1) => {
    //     let lineIndex = getLines().length;
    //     let lineKey = getCartLineKey(item.sku);
    //     let targetQuantity = quantity;
    //     let line = getLines().find((l) => l.lineKey === lineKey);
    //     if (line) {
    //         lineIndex = getLines().indexOf(line);
    //         targetQuantity = line.quantity + quantity;
    //     }

    //     let targetLines = [...getLines()];
    //     targetLines[lineIndex] = {
    //         lineKey,
    //         item,
    //         quantity: targetQuantity,
    //     };
    //     linesSet(targetLines);
    //     numItemsSet(getCartNumItems(targetLines));
    // };

    const addToCart = async (product, quantity = 1, retryOnCartError = true) => {
        await maybeCreateCart();
        let line = getLines().find((l) => l.type === "items" && l.product && l.product.sku === product.sku);
        if (line) {
            const result = await api.updateLineQuantity(line.key, quantity + line.quantity);
            if (result.ok && result.data) {
                trackAddToCart(product, quantity);
                updateCartData(result.data);
                return true;
            } else if (retryOnCartError && result.status && 404 === result.status) {
                await restoreCart();
                trackAddToCart(product, quantity);
                return await addToCart(product, quantity, false);
            }
        } else {
            const result = await api.addLine(product.sku, quantity);
            if (result.ok && result.data) {
                trackAddToCart(product, quantity);
                updateCartData(result.data);
                return true;
            } else if (retryOnCartError && result.status && 404 === result.status) {
                await restoreCart();
                trackAddToCart(product, quantity);
                return await addToCart(product, quantity, false);
            }
        }
        return false;
    };

    const updateLineQuantity = async (lineKey, quantity) => {
        const result = await api.updateLineQuantity(lineKey, quantity);
        if (result.ok && result.data) {
            updateCartData(result.data);
        }
        return result;
    };

    const removeFromCart = async (lineKey) => {
        const result = await api.removeLine(lineKey);
        if (result.ok && result.data) {
            updateCartData(result.data);
        }
        return result;
    };

    // const createCart = async () => {
    //     if (!cartStorage.getKey()) {
    //         const result = await api.post();
    //         if (result.ok && result.data) {
    //             cartStorage.storeKey(result.data);
    //         }
    //     }
    // };

    const maybeCreateCart = async () => {
        if (!cartStorage.getKey()) {
            await createCart();
        }
    };

    const createCart = async () => {
        const result = await api.post();
        if (result.ok && result.data) {
            cartStorage.storeKey(result.data);
            const cartResult = await api.get();
            if (cartResult.ok && cartResult.data) {
                updateCartData(cartResult.data);
                if (cartResult.data.cartLines) {
                    linesSet(cartResult.data.cartLines);
                }
                if (cartResult.data.endpoints) {
                    endpointsSet(cartResult.data.endpoints);
                }
            }
        }
    };

    const getItemsTaxBase = () => {
        let total = 0;

        for (let line of getLines()) {
            total += line.taxBase;
        }

        return total;
    };

    const getItemsTotalTax = () => {
        let total = 0;

        for (let line of getLines()) {
            total += ((line.product.unitTaxBase * getLineTaxRate(line)) / 100) * line.quantity;
            // total += ((line.product.unitTaxBase * line.product.tax) / 100) * line.quantity;
        }

        return total;
    };

    const getLineTaxRate = (line) => {
        let taxRate = 0;
        if (line && line.taxes && line.taxes.length) {
            for (let tax of line.taxes) {
                taxRate += tax.rate;
            }
        }
        return taxRate;
    };

    const getItemsTotal = () => getItemsTaxBase() + getItemsTotalTax();

    const getBillingAddress = () => {
        return (checkoutData && checkoutData.billingAddress) || null;
    };

    const setBillingAddress = async (id, addressType) => {
        const result = await api.setBillingAddress(id, addressType);
        if (result.ok && result.data) {
            updateCartData(result.data);
            return true;
        }
        return false;
    };

    const getBillingAddressIncorrect = () => {
        return checkoutData && checkoutData.billingAddressIncorrect ? true : false;
    };

    const setBillingAddressIncorrect = async (billingAddressIncorrect = false) => {
        const result = await api.setBillingAddressIncorrect(billingAddressIncorrect);
        if (result.ok && result.data) {
            updateCartData(result.data);
            return true;
        }
        return false;
    };

    const getShippingAddress = () => {
        return (checkoutData && checkoutData.shippingAddress) || null;
    };

    const setShippingAddress = async (id, addressType) => {
        const result = await api.setShippingAddress(id, addressType);
        if (result.ok && result.data) {
            updateCartData(result.data);
            return true;
        }
        return false;
    };

    const getShippingService = () => {
        return (checkoutData && checkoutData.shippingService) || null;
    };

    const setShippingService = async (slug) => {
        const result = await api.setShippingService(slug);
        if (result.ok && result.data) {
            updateCartData(result.data);
            return true;
        }
        return false;
    };

    const getPaymentGateway = () => {
        return (checkoutData && checkoutData.paymentGateway) || null;
    };

    const setPaymentGateway = async (slug) => {
        const result = await api.setPaymentGateway(slug);
        if (result.ok && result.data) {
            updateCartData(result.data);
            return true;
        }
        return false;
    };

    const enablePartialShipping = async () => {
        const result = await api.enablePartialShipping();
        if (result.ok && result.data) {
            updateCartData(result.data);
            return true;
        }
        return false;
    };

    const disablePartialShipping = async () => {
        const result = await api.disablePartialShipping();
        if (result.ok && result.data) {
            updateCartData(result.data);
            return true;
        }
        return false;
    };

    const isPartialShippingAllowed = () => (checkoutData && checkoutData.allowPartialShipping ? true : false);
    const isPartialShippingCandidate = () => (checkoutData && checkoutData.partialShippingCandidate ? true : false);

    const getShippingComments = () => {
        return (checkoutData && checkoutData.shippingComments) || "";
    };

    const updateShippingComments = async (comments) => {
        const result = await api.updateShippingComments(comments);
        if (result.ok && result.data) {
            updateCartData(result.data);
            return true;
        }
        return false;
    };

    const getCustomOrderNumber = () => {
        return (checkoutData && checkoutData.customOrderNumber) || "";
    };

    const getAccountPositionId = () => {
        return (checkoutData && checkoutData.accountPositionId) || null;
    };

    const updateCustomOrderNumber = async (customOrderNumber) => {
        const result = await api.updateCustomOrderNumber(customOrderNumber);
        if (result.ok && result.data) {
            updateCartData(result.data);
            return true;
        }
        return false;
    };

    const getShippingPackages = () =>
        (checkoutData && checkoutData.shippingPackages && checkoutData.shippingPackages.length && checkoutData.shippingPackages) || [];

    const getNumShippingPackages = () => getShippingPackages().length;

    const getShippingTaxBase = () => {
        let total = 0;

        for (let pack of getShippingPackages()) {
            if (pack.taxBase) {
                total += pack.taxBase;
            }
        }

        return total;
    };

    const getShippingTax = () => {
        let total = 0;

        for (let pack of getShippingPackages()) {
            let taxRate = 0;
            if (pack.taxes && pack.taxes.length) {
                for (let tax of pack.taxes) {
                    taxRate += tax.rate;
                }
            }
            total += (pack.taxBase * taxRate) / 100;
        }
        return total;
    };

    const getShippingTotal = () => getShippingTaxBase() + getShippingTax();

    const getTotalTaxBase = () => getTotalTaxBase() + getShippingTaxBase();

    const getTotalTax = () => getItemsTotalTax() + getShippingTax();

    const getTotal = () => getItemsTotal() + getShippingTotal();

    return {
        isCartLoading,
        lines,
        linesSet,
        offCanvasOpen,
        openCartOffCanvas,
        closeCartOffCanvas,
        toggleCartOffCanvas,
        modalOpen,
        openCartModal,
        closeCartModal,
        toggleCartModal,
        numItems,
        // addLine,
        // updateLine,
        removeFromCart,
        restoreCart,
        addToCart,
        updateLineQuantity,
        getCartUrl,
        getCheckoutUrl,
        getMyAccountUrl,
        getLegalUrl,
        currency,
        currencies,
        changeCurrency,
        getEtalonCurrency,
        setShippingAddress,
        getShippingAddress,
        setBillingAddress,
        getBillingAddress,
        getBillingAddressIncorrect,
        setBillingAddressIncorrect,
        checkoutData,
        enablePartialShipping,
        disablePartialShipping,
        isPartialShippingAllowed,
        isPartialShippingCandidate,
        getShippingService,
        setShippingService,
        getPaymentGateway,
        setPaymentGateway,
        getShippingComments,
        updateShippingComments,
        getCustomOrderNumber,
        updateCustomOrderNumber,
        getShippingPackages,
        getNumShippingPackages,
        getAccountPositionId,

        // Prices
        getItemsTaxBase,
        getItemsTotal,
        getItemsTotalTax,

        getShippingTaxBase,
        getShippingTax,
        getShippingTotal,

        getTotalTaxBase,
        getTotalTax,
        getTotal,
    };
};

export default useShoppingCart;
