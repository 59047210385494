import React from "react";
import BaseIconSVG from "./BaseIconSVG";

function IconTwitter(props) {
    return (
        <BaseIconSVG
            Icon={({ ...otherProps }) => (
                <svg {...otherProps}>
                    <path d="m22 5.8c-.74.33-1.53.55-2.36.64.85-.51 1.5-1.31 1.8-2.27-.79.47-1.67.81-2.6 1-.75-.8-1.82-1.3-2.99-1.3-2.27 0-4.1 1.84-4.1 4.1 0 .32.04.63.11.93-3.41-.17-6.44-1.8-8.46-4.29-.35.61-.56 1.31-.56 2.06 0 1.42.72 2.68 1.83 3.41-.67-.02-1.31-.21-1.86-.51v.05c0 1.99 1.41 3.65 3.29 4.02-.35.09-.71.15-1.08.15-.26 0-.52-.03-.77-.07.52 1.63 2.04 2.82 3.83 2.85-1.4 1.1-3.17 1.75-5.09 1.75-.33 0-.66-.02-.98-.06 1.82 1.17 3.97 1.85 6.29 1.85 7.55 0 11.68-6.25 11.68-11.68 0-.18 0-.35-.01-.53.8-.58 1.5-1.3 2.05-2.12z" />
                </svg>
            )}
            {...props}
        />
    );
}

export default IconTwitter;
