import React from "react";
import Slider from "../../abstract/Slider/Slider";
import "./ProductsSlider.scss";
import CardProduct from "../../abstract/CardProduct/CardProduct";
import Button from "../../abstract/Button/Button";
import LazyImg from "../../abstract/LazyImg/LazyImg";
import { getImagePropsFromDto } from "../../../helpers/misc";

function ProductsSlider({ title, subtitle, buttonLabel, url, products = [], headerImage }) {
    const getImageProps = (sizes, alt) => ({ ...sizes.find((s) => s.name === "full"), alt });

    return (
        (products.length && (
            <>
                <div className="section-split-header bg-gray-900 text-white">
                    <div className="container">
                        <div className="section-split-header__content">
                            {title && <h2 className="section-title text-white">{title}</h2>}
                            {subtitle && <p>{subtitle}</p>}
                            {buttonLabel && url && (
                                <Button variant="outline" color="white" to={url}>
                                    {buttonLabel}
                                </Button>
                            )}
                        </div>
                        <div className="section-split-header__img">
                            {headerImage &&
                                ((headerImage.props && <LazyImg className="img-fluid" {...getImagePropsFromDto(headerImage.props)} />) || (
                                    <LazyImg className="img-fluid" {...getImageProps(headerImage.sizes, headerImage.alt || "")} />
                                ))}
                        </div>
                    </div>
                </div>

                <Slider style={{ marginTop: "-6rem" }}>
                    <Slider.PreviousButton size="large" />
                    <Slider.Stage>
                        {products.map((product, productIndex) => (
                            <div className="col-6 col-md-4 col-lg-4 col-xl-3" key={productIndex}>
                                <CardProduct product={product} />
                            </div>
                        ))}
                    </Slider.Stage>
                    <Slider.NextButton size="large" />
                </Slider>
            </>
        )) ||
        ""
    );
}

export default ProductsSlider;
