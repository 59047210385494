import React, { useRef, useEffect, useState, useContext } from "react";
import Button from "../Button/Button";
import IconArrowBack from "../icons/IconArrowBack";
import IconArrowForward from "../icons/IconArrowForward";

import "./Slider.scss";

const SliderContext = React.createContext();

function Slider({ className, children, ...otherProps }) {
    const wrapperRef = useRef(null);
    const stageRef = useRef(null);
    const [wrapperClasses, wrapperClassesSet] = useState([]);

    const previous = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const firstChild = stageRef.current.querySelector(":first-child");
        const sourceScrollLeft = stageRef.current.scrollLeft;
        const deltaScrollLeft = firstChild.offsetWidth * Math.floor(wrapperRef.current.offsetWidth / firstChild.offsetWidth);
        let targetScrollLeft = sourceScrollLeft - deltaScrollLeft;
        if (targetScrollLeft < 0) {
            targetScrollLeft = 0;
        }

        if (sourceScrollLeft !== targetScrollLeft) {
            stageRef.current.scrollTo(targetScrollLeft, 0);
        }
    };

    const next = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const firstChild = stageRef.current.querySelector(":first-child");
        const sourceScrollLeft = stageRef.current.scrollLeft;
        const deltaScrollLeft = firstChild.offsetWidth * Math.floor(wrapperRef.current.offsetWidth / firstChild.offsetWidth);
        let targetScrollLeft = sourceScrollLeft + deltaScrollLeft;
        if (targetScrollLeft > stageRef.current.scrollWidth - targetScrollLeft) {
            targetScrollLeft = stageRef.current.scrollWidth - deltaScrollLeft;
        }

        if (sourceScrollLeft !== targetScrollLeft) {
            stageRef.current.scrollTo(targetScrollLeft, 0);
        }
    };

    const onScroll = (e) => classesSet();

    const classesSet = () => {
        wrapperClassesSet([
            ...(isBeginOverflow() || isEndOverflow() ? ["js-overflowing"] : []),
            ...(isBeginOverflow() ? ["js-begin-overflow"] : []),
            ...(isEndOverflow() ? ["js-end-overflow"] : []),
        ]);
    };

    const isBeginOverflow = () => stageRef.current && stageRef.current.scrollLeft > 0;

    const isEndOverflow = () => !(stageRef.current && stageRef.current.scrollLeft >= stageRef.current.scrollWidth - wrapperRef.current.offsetWidth);

    useEffect(() => {
        classesSet();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [children]);

    return (
        <SliderContext.Provider
            value={{
                isBeginOverflow,
                isEndOverflow,
                previous,
                next,
                onScroll,
                stageRef,
            }}
        >
            <div className={["carousel", ...(className ? [className] : []), ...wrapperClasses].join(" ")} {...otherProps} ref={wrapperRef}>
                {children}
            </div>
        </SliderContext.Provider>
    );
}

function SliderStage({ className, children, ...otherProps }) {
    const { stageRef, onScroll } = useContext(SliderContext);

    return (
        <div className={["carousel-stage", ...(className ? [className] : [])].join(" ")} {...otherProps} ref={stageRef} onScroll={onScroll}>
            <div className="row">{children}</div>
        </div>
    );
}

function SliderPreviousButton({ className, children, ...otherProps }) {
    const { isBeginOverflow, previous, size } = useContext(SliderContext);

    return (
        isBeginOverflow() && (
            <Button
                size={size}
                color="secondary"
                iconBefore={<IconArrowBack />}
                className={["carousel-control-prev", "opacity-75", ...(className ? [className] : [])].join(" ")}
                {...otherProps}
                onClick={previous}
            >
                {children}
            </Button>
        )
    );
}

function SliderNextButton({ className, children, ...otherProps }) {
    const { isEndOverflow, next, size } = useContext(SliderContext);

    return (
        isEndOverflow() && (
            <Button
                size={size}
                color="secondary"
                iconBefore={<IconArrowForward />}
                className={["carousel-control-next", "opacity-75", ...(className ? [className] : [])].join(" ")}
                {...otherProps}
                onClick={next}
            >
                {children}
            </Button>
        )
    );
}

Slider.Stage = SliderStage;
Slider.PreviousButton = SliderPreviousButton;
Slider.NextButton = SliderNextButton;

export default Slider;
