import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";

function CategoriesNav({ categories, className }) {
    return <ul className={classNames(["flex-column", className])}>{CategoriesNavTreeNode(categories)}</ul>;
}

function CategoriesNavTreeNode(items) {
    return Object.keys(items).map((i) => (
        <li key={`cat-${items[i].id}`} className={classNames(["nav-item", items[i].active ? "active" : null])}>
            <Link className={classNames(["nav-link", items[i].active ? "active" : null])} aria-current="page" to={items[i].url}>
                {items[i].name}
            </Link>
            {(items[i].children && Object.keys(items[i].children).length && <ul>{CategoriesNavTreeNode(items[i].children)}</ul>) || ""}
        </li>
    ));
}

export default CategoriesNav;
