import React from "react";
import PropTypes from "prop-types";

function FormHelpText(props) {
    const { children } = props;
    return children && <div className="form-text text-muted">{children}</div>;
}

FormHelpText.propTypes = {
    children: PropTypes.node,
};

export default FormHelpText;
