import classNames from "classnames";
import React, { useState } from "react";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import useShoppingCart from "../../../contexts/shoppingCart/useShoppingCart";
import { formatDate } from "../../../helpers/misc";
import Button from "../../abstract/Button/Button";
import { IconTrash } from "../../abstract/icons";
import LazyImg from "../../abstract/LazyImg/LazyImg";
import SlideDown from "../../abstract/SlideDown/SlideDown";
import InputQuantity from "../../forms/InputQuantity/InputQuantity";
import CurrencyFormat from "../CurrencyFormat/CurrencyFormat";
import DateFormat from "../DateFormat/DateFormat";
import ProductStockData from "../ProductStockData/ProductStockData";

import "./CartLine.scss";

function CartLine({ lineKey, order, product, quantity, regularTaxBase, regularTotal, showInCartTable, taxBase, taxes = [], total, type = "items" }) {
    const { removeFromCart, updateLineQuantity, closeCartModal, closeCartOffCanvas } = useShoppingCart();
    const [isRemoving, setIsRemoving] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    const getImageProps = (sizes, alt) => ({ ...sizes.find((s) => s.name === "thumbnail__2x"), alt });

    const updateQuantity = async (newQuantity) => {
        setIsUpdating(true);
        await updateLineQuantity(lineKey, newQuantity);
        setIsUpdating(false);
    };

    const deleteLineClick = async (e) => {
        setIsRemoving(true);
        const result = await removeFromCart(lineKey);
        if (!result.ok) {
            //setIsRemoving(false);
        }
    };

    const productUrlClick = () => {
        closeCartModal();
        closeCartOffCanvas();
    };

    return (
        <SlideDown unMountOnClose={true} closed={isRemoving}>
            <div className="cart-line">
                <div className={classNames("cart-line__img", product.hasOldMainImage ? "cart-line-old-main-image" : null)}>
                    <Link to={product.url} onClick={productUrlClick}>
                        {product.image && <LazyImg className="img-fluid" {...getImageProps(product.image.sizes, product.image.alt || "")} />}
                    </Link>
                </div>
                <div className="cart-line__info">
                    <Link to={product.url} className="cart-line-title" onClick={productUrlClick}>
                        {product.title}
                    </Link>

                    <span className="cart-line-sku">{product.sku}</span>
                    <div className="d-flex align-items-center justify-content-between mt-1">
                        <InputQuantity disabled={isUpdating ? true : false} size="small" value={quantity} setValue={(newValue) => updateQuantity(newValue)} />
                        <span className="cart-line-price">
                            {(regularTaxBase !== taxBase && (
                                <>
                                    <del>
                                        <CurrencyFormat value={regularTaxBase} />
                                    </del>
                                    <ins>
                                        <CurrencyFormat value={taxBase} />
                                    </ins>
                                </>
                            )) || <CurrencyFormat value={taxBase} />}
                            {/* <CurrencyFormat value={taxBase} /> */}
                        </span>
                    </div>

                    <ProductStockData stock={product.stock} sku={product.sku} showRestockDate={true} />
                    {/* {(product.stock && product.stock >= 1 && (
                        <>
                            <div className={classNames("cart-line-stock stock-info mt-1", product.stock < 4 ? "few-stock" : "in-stock")}>
                                <UnitsInStockTrans stockCount={product.stock} />
                            </div>
                        </>
                    )) || (
                        <>
                            <div className="cart-line-stock stock-info out-of-stock mt-1">
                                <Trans i18nKey="noStockWillShipASP">
                                    <strong>Sin Stock</strong>. Te lo enviaremos cuando entre en Stock.
                                </Trans>
                            </div>
                            {(product.restockDate && (
                                <div className="stock fs-xs mt-1">
                                    <EstimatedReceptionDateTrans estimatedDate={formatDate(product.restockDate)} />
                                    <br />
                                </div>
                            )) ||
                                ""}
                        </>
                    )} */}
                </div>
                <Button
                    className="btn-cart-line-delete"
                    iconBefore={<IconTrash />}
                    color="secondary"
                    variant="ghost"
                    size="small"
                    onClick={deleteLineClick}
                ></Button>
            </div>
        </SlideDown>
    );
}

function UnitsInStockTrans({ stockCount }) {
    return stockCount > 1 ? (
        <Trans i18nKey="xxxUnitsInStock">
            <strong>{{ stockCount }}</strong> unidades en Stock
        </Trans>
    ) : (
        <Trans i18nKey="oneUnitInStock">
            <strong>1</strong> unidad en Stock
        </Trans>
    );
}

function EstimatedReceptionDateTrans({ estimatedDate }) {
    return (
        <Trans>
            Fecha estimada de entrada el <strong>{{ estimatedDate }}</strong>
        </Trans>
    );
}

export default CartLine;
