import { useContext } from "react";
import FormikSelectContext from "./context";

const useFormikSelectContext = () => {
    const { defaultOptions, setDefaultOptions, closeDropdown, getValue, setIsLoading } = useContext(FormikSelectContext);

    return { defaultOptions, setDefaultOptions, closeDropdown, getValue, setIsLoading };
};

export default useFormikSelectContext;
