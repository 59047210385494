import React, { useEffect, useState } from "react";
import AnimateHeight from "react-animate-height";

function SlideDown({ closed = false, duration = 300, unMountOnClose = true, children }) {
    const [isClosed, setIsClosed] = useState(closed);
    const [contentHidden, setContentHidden] = useState(unMountOnClose ? closed : false);

    useEffect(() => {
        if (!closed) {
            setContentHidden(false);
            setIsClosed(false);
        } else {
            setIsClosed(true);
        }
    }, [closed]);

    const onHeightAnimationEnd = () => {
        if (unMountOnClose && isClosed) {
            setContentHidden(true);
        }
    };

    return (
        <AnimateHeight duration={duration} height={isClosed ? 0 : "auto"} onHeightAnimationEnd={onHeightAnimationEnd}>
            {(!contentHidden && children) || ""}
        </AnimateHeight>
    );
}

export default SlideDown;
