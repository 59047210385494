import React from "react";
import ContentListPaginationClientSide from "./ClientSide/Pagination";
import ContentListPaginationServerSide from "./ServerSide/Pagination";

function ContentListPagination() {
    const canUseDOM = !!(typeof window !== "undefined" && window.document && window.document.createElement);

    return (!canUseDOM && <ContentListPaginationServerSide />) || <ContentListPaginationClientSide />;
}

export default ContentListPagination;
