import React, { useEffect } from "react";
import { t } from "i18next";
import Badge from "../../abstract/Badge/Badge";
import Button from "../../abstract/Button/Button";
import CurrencyFormat from "../CurrencyFormat/CurrencyFormat";
import DateFormat from "../DateFormat/DateFormat";

function OrdersTable({ title, orders }) {
    return orders.length ? (
        <>
            {(title && <h2>{title}</h2>) || ""}

            <div className="table-responsive mt-4">
                <table className="order-list table align-middle">
                    <OrdersTableHead />
                    <OrdersTableBody orders={orders} />
                </table>
            </div>
        </>
    ) : null;
}

function OrdersTableHead() {
    return (
        <thead>
            <tr>
                <th className="order-number fs-sm">{t("orderNumberTableLabel", "Nº pedido")}</th>
                <th className="order-date fs-sm">{t("orderDataTableLabel", "Fecha")}</th>
                <th className="order-payment fs-sm">{t("orderPaymentTableLabel", "Pago")}</th>
                <th className="order-status fs-sm">{t("orderStatusTableLabel", "Estado")}</th>
                <th className="order-total fs-sm">{t("orderAmountTableLabel", "Importe")}</th>
                <th className="order-actions fs-sm"></th>
            </tr>
        </thead>
    );
}

function OrdersTableBody({ orders }) {
    return (
        <tbody>
            {orders.map((order, orderIndex) => (
                <OrdersTableRow {...order} key={`order-${orderIndex}`} />
            ))}
        </tbody>
    );
}

function OrdersTableRow({ id, endpoints, orderNumber, orderDate, status, currency, total, paymentGateway }) {
    const getStatusName = (slug) =>
        slug === "on-hold" || slug === "pending"
            ? t("orderStatusPaymentPending", "Pendiente de Pago")
            : slug === "completed"
            ? t("orderStatusSent", "Enviado")
            : slug === "processing"
            ? t("orderStatusProcessing", "Procesando")
            : slug === "partially-sent"
            ? t("orderStatusPartiallySent", "Enviado Parcialmente")
            : null;

    const getStatusBadge = (slug) =>
        slug === "on-hold" || slug === "pending"
            ? "danger"
            : slug === "completed" || slug === "partially-sent"
            ? "success"
            : slug === "processing"
            ? "warning"
            : null;

    const getEndpointButtonColor = (slug) => {
        switch (slug) {
            case "pay":
                return "danger";
            default:
                return "secondary";
        }
    };

    const getEndpointButtonVariant = (slug) => {
        switch (slug) {
            case "pay":
                return "solid";
            default:
                return "light";
        }
    };

    return (
        <tr>
            <td className="order-number">
                <svg
                    className="icon-svg me-2"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ fill: "#9ca3af" }}
                >
                    <path
                        opacity="0.5"
                        d="M18 21.6C16.3 21.6 15 20.3 15 18.6V2.50001C15 2.20001 14.6 1.99996 14.3 2.19996L13 3.59999L11.7 2.3C11.3 1.9 10.7 1.9 10.3 2.3L9 3.59999L7.70001 2.3C7.30001 1.9 6.69999 1.9 6.29999 2.3L5 3.59999L3.70001 2.3C3.50001 2.1 3 2.20001 3 3.50001V18.6C3 20.3 4.3 21.6 6 21.6H18Z"
                        fill="inherit"
                    />
                    <path
                        d="M12 12.6H11C10.4 12.6 10 12.2 10 11.6C10 11 10.4 10.6 11 10.6H12C12.6 10.6 13 11 13 11.6C13 12.2 12.6 12.6 12 12.6ZM9 11.6C9 11 8.6 10.6 8 10.6H6C5.4 10.6 5 11 5 11.6C5 12.2 5.4 12.6 6 12.6H8C8.6 12.6 9 12.2 9 11.6ZM9 7.59998C9 6.99998 8.6 6.59998 8 6.59998H6C5.4 6.59998 5 6.99998 5 7.59998C5 8.19998 5.4 8.59998 6 8.59998H8C8.6 8.59998 9 8.19998 9 7.59998ZM13 7.59998C13 6.99998 12.6 6.59998 12 6.59998H11C10.4 6.59998 10 6.99998 10 7.59998C10 8.19998 10.4 8.59998 11 8.59998H12C12.6 8.59998 13 8.19998 13 7.59998ZM13 15.6C13 15 12.6 14.6 12 14.6H10C9.4 14.6 9 15 9 15.6C9 16.2 9.4 16.6 10 16.6H12C12.6 16.6 13 16.2 13 15.6Z"
                        fill="inherit"
                    />
                    <path
                        d="M15 18.6C15 20.3 16.3 21.6 18 21.6C19.7 21.6 21 20.3 21 18.6V12.5C21 12.2 20.6 12 20.3 12.2L19 13.6L17.7 12.3C17.3 11.9 16.7 11.9 16.3 12.3L15 13.6V18.6Z"
                        fill="inherit"
                    />
                </svg>

                {(endpoints && endpoints.find((e) => e.slug === "view") && (
                    <Button variant="link" color="secondary" className="fw-semibold" to={endpoints.find((e) => e.slug === "view").url}>
                        {orderNumber}
                    </Button>
                )) || <span>{orderNumber}</span>}
            </td>
            <td className="order-date fs-sm">
                <DateFormat value={orderDate} />
            </td>
            <td className="payment-gateway fs-sm">
                <div className="order-status-wrapper"> {paymentGateway.name} </div>
            </td>
            <td className="order-status">
                <Badge color={getStatusBadge(status)} variant="light" className={`order-status-wrapper status-${status}`}>
                    {getStatusName(status)}
                </Badge>
            </td>
            <td className="order-total fs-sm">
                <CurrencyFormat value={total} currency={currency} />
            </td>
            <td className="order-actions">
                <div className="d-flex align-items-center gap-2">
                    {endpoints.map((endpoint, endpointIndex) => (
                        <Button
                            color={getEndpointButtonColor(endpoint.slug)}
                            variant={getEndpointButtonVariant(endpoint.slug)}
                            size="small"
                            className="rounded-1"
                            key={`order-${id}-endpoint--${endpointIndex}`}
                            to={endpoint.url}
                        >
                            {endpoint.name}
                        </Button>
                    ))}
                </div>
            </td>
        </tr>
    );
}

export default OrdersTable;
