import React, { useContext, useEffect, useState } from "react";
import { Formik } from "formik";
import { t } from "i18next";
import classnames from "classnames";
import * as Yup from "yup";
import CheckoutPortletContext from "../../context";
import useShoppingCart from "../../../../../contexts/shoppingCart/useShoppingCart";
import Button from "../../../../abstract/Button/Button";
import { IconInfo, IconInfoFilled, IconPlus } from "../../../../abstract/icons";
import FormRadio from "../../../../forms/FormRadio/FormRadio";
import * as api from "../../../../../api/addresses";

import "./AddressPortlet.scss";
import Modal from "../../../../abstract/Modal/Modal";
import FormikInput from "../../../../abstract/forms/formik/FormikInput";
import FormikTextarea from "../../../../abstract/forms/formik/FormikTextarea";
import CountryDropdown from "../../../../abstract/forms/formik/dropdowns/CountryDropdown";
import StateDropdown from "../../../../abstract/forms/formik/dropdowns/StateDropdown";
import FormikInputTel from "../../../../abstract/forms/formik/FormikInputTel";
import Form from "../../../../abstract/forms/Form";
// import SweetAlert2 from "react-sweetalert2";
import SlideDown from "../../../../abstract/SlideDown/SlideDown";
import Dialog from "../../../../abstract/Dialog/Dialog";
import Skeleton from "../../../../abstract/Skeleton/Skeleton";
import FormCheckbox from "../../../../forms/FormCheckbox/FormCheckbox";
import { Trans } from "react-i18next";
import Alert from "../../../../abstract/Alert/Alert";

const AddressPortletContext = React.createContext();

function AddressPortlet({ type, mode = "edit" }) {
    return mode === "view" ? <AddressPortletView type={type} /> : <AddressPortletEdit type={type} />;
}

function AddressPortletView({ type }) {
    const { getBillingAddress, getShippingAddress, getBillingAddressIncorrect } = useShoppingCart();

    return (
        <div>
            <AddressPortletCardText mode="view" type={type} address={type === "billing" ? getBillingAddress() || {} : getShippingAddress() || {}} />
            {(type === "billing" && getBillingAddressIncorrect() && (
                <Alert color="info" className="mt-3 p-2">
                    <IconInfo size="small" className="me-2" />
                    <small>
                        <Trans i18nKey="wrongBillingAddress">Mi dirección de facturación es errónea.</Trans>
                    </small>
                </Alert>
            )) ||
                ""}
        </div>
    );
}

function AddressPortletEdit({ type = "shipping" }) {
    const [editModalId, setEditModalId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdatingBillingAddressIncorrect, setIsUpdatingBillingAddressIncorrect] = useState(false);
    const [isSelecting, setIsSelecting] = useState(false);
    const [addresses, setAddresses] = useState([]);
    const { setIsCorrect } = useContext(CheckoutPortletContext);
    const {
        getAccountPositionId,
        setBillingAddress,
        setShippingAddress,
        getBillingAddress,
        getShippingAddress,
        getBillingAddressIncorrect,
        setBillingAddressIncorrect,
        checkoutData,
    } = useShoppingCart();
    const isViesIncorrect = () => getBillingAddress() && getBillingAddress().vatNumber && 3 === getAccountPositionId();
    const [wrongBillingAddressExplanationOpen, setWrongBillingAddressExplanationOpen] = useState(getBillingAddressIncorrect() ? true : false);
    const [viesIncorrect, setViesIncorrect] = useState(isViesIncorrect());

    const loadAddresses = async () => {
        setIsLoading(true);
        setIsCorrect(false);
        const response = await api.getAll();
        let newAddresses = [];
        if (response.ok && response.data && response.data.length) {
            for (let add of response.data) {
                if (add.companyId || type === "shipping") {
                    newAddresses.push(add);
                }
            }
            setAddresses(newAddresses);
        }
        if (!newAddresses.length) {
            if ("shipping" === type) {
                await setShippingAddress(null, "company");
            } else {
                setViesIncorrect(false);
                await setBillingAddress(null, "company");
            }
        }
        setIsLoading(false);
    };

    const autoSelectAddresses = async () => {
        if (!addresses || !addresses.length) {
            return;
        }

        const firstAddress = addresses[0];
        if (firstAddress) {
            setAddress(firstAddress);
        }
    };

    const setAddress = async (add, skipIfNotEmpty = true) => {
        setIsSelecting(true);
        switch (type) {
            case "shipping":
                if (!skipIfNotEmpty || !getShippingAddress()) {
                    await setShippingAddress(add.id || add.companyId, add.id ? "address" : "company");
                }
                break;
            case "billing":
                if (!skipIfNotEmpty || !getBillingAddress()) {
                    await setBillingAddress(add.companyId, "company");
                }
                break;
            default:
                break;
        }
        setIsSelecting(false);
    };

    useEffect(() => {
        autoSelectAddresses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addresses]);

    useEffect(() => {
        if (isViesIncorrect()) {
            setIsCorrect(false);
        }
        loadAddresses();
        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setViesIncorrect(isViesIncorrect());
        if (getBillingAddress() && getBillingAddress().vatNumber && !isViesIncorrect()) {
            setIsCorrect(true);
        } else {
            setIsCorrect(false);
        }
    }, [checkoutData]);

    return (
        <AddressPortletContext.Provider value={{ setAddress }}>
            {(!isLoading && !addresses.length && <>{"shipping" === type && <p>{t("addAddressPeriod", "Añade una dirección:")}</p>}</>) ||
                (!isLoading && "billing" === type && <p>{t("selectBillingAddress", "Selecciona una dirección de facturación:")}</p>) ||
                (!isLoading && <p>{t("selectShippingAddress", "Selecciona una dirección de envío:")}</p>) || <Skeleton maxWidth="240px" height="1.875rem" />}
            <div className="billing-address-list mt-4 mb-3">
                <SlideDown>
                    {(isLoading && (
                        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-1 row-cols-xl-2 g-3">
                            <div className="col">
                                <div className="card card-check card-check--active">
                                    <div className="card-body">
                                        <div className="d-flex gap-2">
                                            <Skeleton variant="circular" height="1.25rem" width="1.25rem" className="mt-1" />
                                            <div className="flex-grow-1 d-flex flex-column">
                                                <Skeleton maxWidth="230px" height="1.875rem" />
                                                <Skeleton maxWidth="200px" />
                                                <Skeleton maxWidth="200px" />
                                                <Skeleton maxWidth="180px" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )) || (
                        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-1 row-cols-xl-2 g-3">
                            {addresses.map((ad, adIndex) => (
                                <div className="col" key={adIndex}>
                                    <AddressPortletCard
                                        address={ad}
                                        disabled={isSelecting ? true : false}
                                        selected={
                                            type === "shipping"
                                                ? getShippingAddress() && getShippingAddress().id === ad.id && getShippingAddress().companyId === ad.companyId
                                                : getBillingAddress() && getBillingAddress().id === ad.id && getBillingAddress().companyId === ad.companyId
                                        }
                                        type={type}
                                        onEdit={
                                            ad.id
                                                ? (e) => {
                                                      e.preventDefault();
                                                      e.stopPropagation();
                                                      setEditModalId(ad.id);
                                                  }
                                                : null
                                        }
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                </SlideDown>
            </div>
            {(type === "shipping" && (
                <>
                    <Button isDisabled={isLoading} variant="ghost" iconBefore={<IconPlus />} onClick={() => setEditModalId(-1)} className="mb-4">
                        {t("addAddress", "Añadir dirección")}
                    </Button>

                    <EditAddressModal
                        onSuccess={(newAddress) => {
                            setAddresses(editModalId ? addresses.map((a) => (a.id === editModalId ? newAddress : a)) : [...addresses, newAddress]);
                            setEditModalId(null);
                        }}
                        id={editModalId}
                        show={editModalId !== null}
                        onHide={() => setEditModalId(null)}
                    />
                </>
            )) ||
                (getBillingAddress() && (
                    <>
                        {(getBillingAddress().vatNumber && (
                            <>
                                <Button variant="link" size="small" iconBefore={<IconInfo />} onClick={() => setWrongBillingAddressExplanationOpen(true)}>
                                    {t("wrongBillingAddressQuestion", "¿Necesitas modificar la dirección de facturación?")}
                                </Button>
                                <SlideDown closed={!wrongBillingAddressExplanationOpen}>
                                    <Alert color="secondary">
                                        <small>
                                            <Trans i18nKey="wrongBillingAddressExplanation">
                                                No es posible modificar la dirección de facturación mediante la web. Si necesitas actualizarla o añadir una
                                                nueva marca esta casilla y nuestro Departamento de Atención al cliente se pondrá en contacto contigo para
                                                modificar la factura una vez completes tu pedido.
                                            </Trans>
                                        </small>
                                        <div
                                            className="d-flex align-items-center mt-3"
                                            onClick={async () => {
                                                setIsUpdatingBillingAddressIncorrect(true);
                                                await setBillingAddressIncorrect(!getBillingAddressIncorrect());
                                                setIsUpdatingBillingAddressIncorrect(false);
                                            }}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <FormCheckbox
                                                disabled={isUpdatingBillingAddressIncorrect}
                                                checked={getBillingAddressIncorrect()}
                                                style={{ cursor: "pointer" }}
                                            />
                                            <Trans i18nKey="wrongBillingAddress">Mi dirección de facturación es errónea.</Trans>
                                        </div>
                                    </Alert>
                                </SlideDown>
                                {viesIncorrect && (
                                    <Alert color="danger" className="mt-3">
                                        <Trans i18nKey="viesIncorrectOnCheckout">
                                            Lo sentimos pero el CIF de tu dirección de facturación no aparece dado de alta como Operador Intracomunitario en la{" "}
                                            <a href="https://ec.europa.eu/taxation_customs/vies/#/vat-validation" target="_blank">
                                                web de la comisión Europea
                                            </a>
                                            . Para poder completar tu pedido debes estar dado de alta necesariamente. Si necesitas ayuda escríbenos a{" "}
                                            <a href="mailto:store@cmz.com">store@cmz.com</a> o llámanos al
                                            <a href="tel:+34946826580">(+34) 94 682 65 80</a>.
                                        </Trans>
                                    </Alert>
                                )}
                            </>
                        )) || (
                            <>
                                <Alert color="danger" className="mt-4">
                                    <small>
                                        <Trans i18nKey="billingAddressWithEmptyVAT">
                                            Lo sentimos pero no tenemos registrado el CIF de la empresa de facturación seleccionada. Antes de continuar con tu
                                            pedido, facilítanos el CIF por correo electrónico en <a href="mailto:store@cmz.com">store@cmz.com</a> o por teléfono
                                            en el <a href="tel:+34946826580">(+34) 94 682 65 80</a>.
                                        </Trans>
                                    </small>
                                </Alert>
                            </>
                        )}
                    </>
                )) || (
                    <>
                        {(!isLoading && !addresses.length && (
                            <Alert color="danger" className="mt-4">
                                <small>
                                    <Trans i18nKey="nobillingAddressOnCheckout">
                                        Lo sentimos pero no tienes asignada ninguna dirección de facturación. Antes de continuar con tu pedido, facilítanosla
                                        por correo electrónico en <a href="mailto:store@cmz.com">store@cmz.com</a> o por teléfono en el{" "}
                                        <a href="tel:+34946826580">(+34) 94 682 65 80</a>.
                                    </Trans>
                                </small>
                            </Alert>
                        )) ||
                            ""}
                    </>
                )}
        </AddressPortletContext.Provider>
    );
}

function AddressPortletCard({ type = "shipping", address, disabled = false, selected = false, onEdit }) {
    const { setAddress } = useContext(AddressPortletContext);

    return (
        <div
            className={classnames(["card card-check", selected ? "card-check--active" : null])}
            onClick={(e) => {
                e.preventDefault();
                setAddress(address, false);
            }}
            style={!disabled ? { cursor: "pointer" } : null}
        >
            <div className="card-body">
                <div className="d-flex gap-2">
                    <FormRadio checked={selected} disabled={disabled} />
                    <div className="flex-grow-1 d-flex align-items-start gap-3">
                        <address className="fs-sm mb-0">
                            <AddressPortletCardText mode="edit" type={type} address={address} />
                        </address>
                        {(onEdit && (
                            <Button size="small" variant="link" className="ms-auto flex-shrink-0" onClick={onEdit}>
                                {t("edit", "Editar")}
                            </Button>
                        )) ||
                            ""}
                    </div>
                </div>
            </div>
        </div>
    );
}

function AddressPortletCardText({ mode = "edit", type = "shipping", address }) {
    const { address: fullAddress, city, companyName, country, firstName, lastName, postalCode, state, vatNumber } = address;

    return (
        <>
            {("shipping" === type && (firstName || lastName) && (
                <>
                    <span className="fw-semibold ">
                        {firstName} {lastName}
                    </span>
                    <br />
                </>
            )) ||
                ""}
            {(companyName && (
                <>
                    {("shipping" !== type && (
                        <>
                            <span className="fw-semibold ">{companyName}</span>
                            {(vatNumber && (
                                <>
                                    <br />
                                    <span className="fw-semibold ">{vatNumber}</span>
                                </>
                            )) ||
                                ""}
                        </>
                    )) ||
                        companyName}
                    <br />
                </>
            )) ||
                ""}
            {(fullAddress && (
                <>
                    {fullAddress}
                    <br />
                </>
            )) ||
                ""}
            {((city || postalCode) && (
                <>
                    {[...(city ? [city] : []), ...(postalCode ? [postalCode] : [])].join(", ")}
                    <br />
                </>
            )) ||
                ""}
            {((state || country) && <>{[...(state && state.name ? [state.name] : []), ...(country && country.name ? [country.name] : [])].join(", ")}</>) || ""}
        </>
    );
}

function EditAddressModal({ id, show, onHide, onSuccess }) {
    const [currentValue, setCurrentValue] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [initialValues, setInitialValues] = useState(null);
    const [swalProps, setSwalProps] = useState({});

    useEffect(() => {
        const refreshValues = async () => {
            if (!id || -1 === id) {
                setCurrentValue(null);
            } else {
                setIsLoading(true);
                const result = await api.get(id);
                if (result.ok) {
                    setCurrentValue(result.data);
                }
                setIsLoading(false);
            }
        };

        if (id && -1 !== id) {
            refreshValues();
        } else {
            setCurrentValue(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        setInitialValues({
            companyName: "",
            vatNumber: "",
            country: { id: "" },
            state: { id: "" },
            address: "",
            city: "",
            postalCode: "",
            firstName: "",
            lastName: "",
            phoneNumber: "",
            ...currentValue,
        });
    }, [currentValue]);

    const saveValues = async (values, formikHelpers) => {
        setIsLoading(true);
        const response = await (-1 !== id ? api.update(id, values) : api.add(values));
        setIsLoading(false);
        if (response.ok && response.data) {
            onSuccess(response.data);
        } else {
            setSwalProps({
                show: true,
                title: t("error", "Error"),
                text:
                    -1 !== id
                        ? t(
                              "errorEditingAddress",
                              "Se ha producido un error actualizando la dirección. Por favor, inténtalo de nuevo o contacta con nuestro Departamento de Atención al Cliente."
                          )
                        : t(
                              "errorAddingAddress",
                              "Se ha producido un error añadiendo la dirección. Por favor, inténtalo de nuevo o contacta con nuestro Departamento de Atención al Cliente."
                          ),
                icon: "error",
            });
        }
    };

    const FormSchema = Yup.object().shape({
        companyName: Yup.string().min(2).required(t("thisFieldIsRequired", "Este campo es requerido")),
        vatNumber: Yup.string().required(t("thisFieldIsRequired", "Este campo es requerido")),
        country: Yup.object().shape({
            id: Yup.number()
                // .typeError(t("thisFieldIsRequired", "Este campo es requerido"))
                .required(t("thisFieldIsRequired", "Este campo es requerido")),
        }),
        state: Yup.object().when("country", {
            is: (country) => country && country.id,
            then: (schema) =>
                schema.shape({
                    id: Yup.number().required(t("thisFieldIsRequired", "Este campo es requerido")),
                }),
        }),
        address: Yup.string().required(t("thisFieldIsRequired", "Este campo es requerido")),
        city: Yup.string().required(t("thisFieldIsRequired", "Este campo es requerido")),
        postalCode: Yup.string().required(t("thisFieldIsRequired", "Este campo es requerido")),
        firstName: Yup.string().required(t("thisFieldIsRequired", "Este campo es requerido")),
        lastName: Yup.string().required(t("thisFieldIsRequired", "Este campo es requerido")),
    });

    return (
        <>
            <Modal show={show} onHide={onHide} className="modal-address" size="large" fullscreen="md-down">
                <Formik initialValues={initialValues} onSubmit={saveValues} validationSchema={FormSchema} enableReinitialize={true}>
                    {({ handleSubmit, isSubmitting, validateForm, values }) => (
                        <Form onSubmit={handleSubmit}>
                            <Modal.Header title={-1 !== id ? t("editAddress", "Editar Dirección") : t("addAddress", "Añadir Dirección")} />
                            <Modal.Body className={isLoading ? "loading" : "loaded"}>
                                <h2 className="h5 fw-bold pb-3">{t("address", "Dirección")}</h2>
                                <div className="row">
                                    <div className="col-md-6">
                                        <FormikInput name="companyName" label={t("companyName", "Nombre de Empresa")} required />
                                    </div>
                                    <div className="col-md-6">
                                        <FormikInput name="vatNumber" label={t("vatNumber", "CIF / Número de IVA")} required />
                                    </div>
                                    <div className="col-md-6">
                                        <CountryDropdown name="country.id" label={t("country", "País")} required />
                                    </div>
                                    <div className="col-md-6">
                                        <StateDropdown
                                            countryId={(values && values.country && values.country.id && parseInt(values.country.id)) || null}
                                            name="state.id"
                                            label={t("state", "Provincia")}
                                            required
                                        />
                                    </div>
                                    <div className="col-12">
                                        <FormikTextarea rows="3" name="address" label={t("address", "Dirección")} required />
                                    </div>
                                    <div className="col-md-6">
                                        <FormikInput name="city" label={t("city", "Ciudad")} required />
                                    </div>
                                    <div className="col-md-6">
                                        <FormikInput name="postalCode" label={t("postalCode", "Código Postal")} required />
                                    </div>
                                </div>
                                <h2 className="h5 fw-bold pb-3 mt-6">{t("contactData", "Datos de Contacto")}</h2>
                                <div className="row">
                                    <div className="col-md-6">
                                        <FormikInput name="firstName" label={t("firstName", "Nombre")} required />
                                    </div>
                                    <div className="col-md-6">
                                        <FormikInput name="lastName" label={t("lastName", "Apellidos")} required />
                                    </div>
                                    <div className="col-md-6">
                                        <FormikInputTel
                                            countryId={(values && values.country && values.country.id && parseInt(values.country.id)) || null}
                                            name="phoneNumber"
                                            label={t("phoneNumner", "Número de Teléfono")}
                                        />
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button color="secondary" variant="outline" onClick={onHide}>
                                    {t("cancel", "Cancelar")}
                                </Button>
                                <Button color="primary" type="submit">
                                    {t("save", "Guardar")}
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
            <Dialog onConfirm={() => setSwalProps({ show: false })} {...swalProps} />
        </>
    );
}

export default AddressPortlet;
