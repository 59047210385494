import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { t } from "i18next";
import useLocalization from "../../../contexts/localization/useLocalization";
import Dropdown from "../../abstract/Dropdown/Dropdown";
import "./LanguageSelector.scss";

function LanguageSelector({ selectedLanguage, items }) {
    const [dropdownOpen, dropdownOpenSet] = useState(false);
    const { changeLanguage } = useLocalization();

    useEffect(() => {
        changeLanguage(selectedLanguage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLanguage]);

    return (
        selectedLanguage && (
            <div className="language-selector d-flex align-items-center gap-1">
                <span className="label fs-sm">{t("languagePeriod", "Idioma:")}</span>
                <Dropdown buttonAnchor={selectedLanguage.toUpperCase()} setShow={dropdownOpenSet} show={dropdownOpen} size="small" buttonToggleClassName="px-0">
                    <nav className="language-selector-nav">
                        <ul className="list-unstyled mb-0">
                            {items.map(
                                (l, i) =>
                                    l.code.toUpperCase() !== selectedLanguage.toUpperCase() && (
                                        <li key={`language-${i}`}>
                                            <Link to={l.url} title={l.name} className="dropdown-item" onClick={() => dropdownOpenSet(false)}>
                                                {/* {l.code.toUpperCase()} */}
                                                {l.name}
                                            </Link>
                                        </li>
                                    )
                            )}
                        </ul>
                    </nav>
                </Dropdown>
            </div>
        )
    );
}

LanguageSelector.propTypes = {};

export default LanguageSelector;
