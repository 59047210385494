import React, { useEffect, useState } from "react";
import { t } from "i18next";
import Button from "../../components/abstract/Button/Button";

import CardProduct from "../../components/abstract/CardProduct/CardProduct";
import Breadcrumbs from "../../components/abstract/Breadcrumbs/Breadcrumbs";
import InputQuantity from "../../components/forms/InputQuantity/InputQuantity";

import "./ProductPage.scss";
import Accordion from "../../components/abstract/Accordion/Accordion";
import useShoppingCart from "../../contexts/shoppingCart/useShoppingCart";

import TagLatheSeries from "../../components/concret/TagLatheSeries/TagLatheSeries";
import Slider from "../../components/abstract/Slider/Slider";
import LazyImg from "../../components/abstract/LazyImg/LazyImg";
import useLayout from "../../contexts/layout/useLayout";

// import "react-image-gallery/styles/scss/image-gallery.scss";
import ProductImageGallery from "../../components/concret/ProductImageGallery/ProductImageGallery";
import CurrencyFormat from "../../components/concret/CurrencyFormat/CurrencyFormat";
import Badge from "../../components/abstract/Badge/Badge";
import DateFormat from "../../components/concret/DateFormat/DateFormat";
import classNames from "classnames";
import Alert from "../../components/abstract/Alert/Alert";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import ProductStockData from "../../components/concret/ProductStockData/ProductStockData";

function ProductPage({ id, title, header, footer, content, breadcrumbs = [], product }) {
    const { addToCart, openCartModal } = useShoppingCart();
    const [isAddingToCart, setIsAddingToCart] = useState(false);
    const [formQuantity, formQuantitySet] = useState(1);
    const { headerSet, footerSet } = useLayout();

    useEffect(
        () => headerSet(header),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [header]
    );
    useEffect(
        () => footerSet(footer),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [footer]
    );

    const getImageProps = (sizes, alt) => ({ ...sizes.find((s) => s.name === "full"), alt });

    return (
        <main className="main product-page">
            <div className="container">
                <div className="pp-layout">
                    <div className="pp-layout__left">
                        <Breadcrumbs className="mt-3 mb-3 mb-lg-6 position-relative" breadcrumbs={breadcrumbs} />
                        {(product.galleryImages.length && (
                            <ProductImageGallery images={product.galleryImages} productImages={product.productGalleryImages} />
                        )) || (
                            <div className="pp-image">
                                <LazyImg {...getImageProps(product.image.sizes, product.image.alt || "")} className="img-fluid" />
                            </div>
                        )}
                    </div>

                    <div className="product-information">
                        <div className="product-heading">
                            {product.isNew && (
                                <Badge color="secondary" variant="solid">
                                    {t("newBadge", "NEW!")}
                                </Badge>
                            )}
                            <h1>{product.title}</h1>
                            <div
                                className="product-description"
                                dangerouslySetInnerHTML={{
                                    __html: product.description,
                                }}
                            />
                        </div>
                        <div className="product-features">
                            <div className="product-features__item">
                                <span className="label">{t("productSku", "Código")}</span>
                                <span className="value">{product.sku}</span>
                            </div>
                            {product.machines.length > 0 && (
                                <div className="product-features__item">
                                    <span className="label">{t("validForMachines", "Válido para máquinas")}</span>
                                    <span className="value">
                                        <div className="tag-group d-flex flex-wrap gap-2 my-2">
                                            {product.machines.map((machine, machineIndex) => (
                                                <TagLatheSeries key={machineIndex}>{machine.shortName}</TagLatheSeries>
                                            ))}
                                        </div>
                                    </span>
                                </div>
                            )}
                            {product.toolHolderTypes.length > 0 && (
                                <div className="product-features__item">
                                    <span className="label">{t("standardTitle", "Tipo de Portaherramientas")}</span>
                                    <span className="value">
                                        {product.toolHolderTypes.map((toolHolderType, toolHolderTypeIndex) => (
                                            <span style={{ marginRight: "0.5rem" }} key={toolHolderTypeIndex}>
                                                {toolHolderType.name}
                                            </span>
                                        ))}
                                    </span>
                                </div>
                            )}
                        </div>
                        {(product.isOffCatalogue && (
                            <Alert color="info" className="mt-4 fs-sm">
                                <Trans i18nKey="productOffCatalogue">
                                    Este producto se encuentra descatalogado. Te recomendamos echar un vistazo a los productos relacionados.
                                </Trans>
                            </Alert>
                        )) ||
                            ""}
                        {(product.purchasable && (
                            <>
                                <div className="product-add-to-cart mt-4">
                                    <form className="d-flex flex-column gap-5">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <InputQuantity value={formQuantity} setValue={formQuantitySet} size="small" />
                                            <div className="price product-price">
                                                {(product.unitRegularTaxBase !== product.unitTaxBase && (
                                                    <>
                                                        <del>
                                                            <CurrencyFormat value={formQuantity * product.unitRegularTaxBase} />
                                                        </del>
                                                        <ins>
                                                            <CurrencyFormat value={formQuantity * product.unitTaxBase} />
                                                        </ins>
                                                    </>
                                                )) || <CurrencyFormat value={formQuantity * product.unitTaxBase} />}
                                            </div>
                                        </div>
                                        <div className="product-add-to-cart__actions">
                                            <Button
                                                className="w-100"
                                                size="large"
                                                isDisabled={isAddingToCart}
                                                isLoading={isAddingToCart}
                                                onClick={async () => {
                                                    setIsAddingToCart(true);
                                                    await addToCart(product, formQuantity);
                                                    setIsAddingToCart(false);
                                                    openCartModal();
                                                }}
                                            >
                                                {t("addToCart", "Añadir al carrito")}
                                            </Button>
                                        </div>
                                    </form>
                                </div>
                                <div className="product-stock-info">
                                    <ProductStockData stock={product.stock} sku={product.sku} showRestockDate={true} restockDate={product.restockDate} />
                                    {/* {(product.stock && product.stock >= 1 && (
                                        <>
                                            <div className={classNames("stock-info fs-sm", product.stock < 4 ? "few-stock" : "in-stock")}>
                                                {t("stockCountPeriod", "Piezas en Stock:")} <strong>{product.stock}</strong>
                                                <br />
                                            </div>
                                            {(product.restockDate && (
                                                <div className="stock fs-sm mt-1">
                                                    {t("restockDatePeriod", "Más unidades disponibles para:")}{" "}
                                                    <strong>{<DateFormat value={product.restockDate} />}</strong>
                                                    <br />
                                                </div>
                                            )) ||
                                                ""}
                                        </>
                                    )) || (
                                        <>
                                            <div className="stock-info out-of-stock fs-sm text-danger">
                                                {t("noStock", "Sin Stock. Te lo enviaremos cuando entre en Stock.")}
                                                <br />
                                            </div>
                                            {(product.restockDate && (
                                                <div className="stock fs-sm mt-1">
                                                    {t("restockDateEstimatedPeriod", "Fecha estimada de entrada:")}
                                                    <strong>{<DateFormat value={product.restockDate} />}</strong>
                                                    <br />
                                                </div>
                                            )) ||
                                                ""}
                                        </>
                                    )} */}
                                </div>
                            </>
                        )) || <></>}
                        {(product.remarks.length && (
                            <div className="product-content-info">
                                <Accordion title={t("technicalData", "Características técnicas")} buttonClassName="ps-0">
                                    <ul>
                                        {product.remarks.map((b, i) => (
                                            <li
                                                key={`product-remark-${i}`}
                                                dangerouslySetInnerHTML={{
                                                    __html: b,
                                                }}
                                            ></li>
                                        ))}
                                    </ul>
                                </Accordion>
                            </div>
                        )) ||
                            ""}
                    </div>
                </div>
            </div>
            {(product.relatedProducts.length && (
                <section className="section section-related-products py-4 py-md-6 py-lg-12">
                    <div className="container">
                        <h2 className="section-title">{t("relatedProducts", "Productos relacionados")}</h2>
                    </div>
                    <Slider>
                        <Slider.PreviousButton size="large" />
                        <Slider.Stage>
                            {product.relatedProducts.map((relatedProduct, relatedProductIndex) => (
                                <div className="col-6 col-md-4 col-lg-4 col-xl-3" key={`related-product-${relatedProductIndex}`}>
                                    <CardProduct product={relatedProduct} />
                                </div>
                            ))}
                        </Slider.Stage>
                        <Slider.NextButton size="large" />
                    </Slider>
                </section>
            )) ||
                ""}
        </main>
    );
}

export default ProductPage;
