import React from "react";
import PropTypes from "prop-types";

import "./IconLoading.scss";

function IconLoading({ color = "default", size = "medium", className = null, ...otherProps }) {
    return (
        <svg
            viewBox="0 0 50 50"
            className={[
                "circle-loader-spinner",
                ...(size === "medium" ? [""] : []),
                ...(size === "large" ? ["circle-loader--lg"] : []),
                ...(size === "small" ? ["circle-loader--sm"] : []),
                ...(size === "xsmall" ? ["circle-loader--xs"] : []),
                ...(color === "primary" ? ["text-primary"] : []),
                ...(color === "secondary" ? ["text-secondary"] : []),
                ...(color === "white" ? ["text-white"] : []),
                ...(className ? [className.trim()] : []),
            ].join(" ")}
            {...otherProps}
        >
            <circle className="circle-loader-spinner-path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
        </svg>
    );
}

IconLoading.propTypes = {
    color: PropTypes.oneOf(["primary", "secondary", "white"]),
    size: PropTypes.oneOf(["xsmall", "small", "medium", "large"]),
};

export default IconLoading;
