import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import * as api from "../../../api/products";
import Button from "../../abstract/Button/Button";
import { IconSearch } from "../../abstract/icons";
import "./SearchForm.scss";
import CardProduct from "../../abstract/CardProduct/CardProduct";
// import SweetAlert2 from "react-sweetalert2";
import useClickOutside from "../../../contexts/clickOutside/useClickOutside";
import useVirtualPageHandler from "../../../contexts/virtualPageHandler/useVirtualPageHandler";
import classnames from "classnames";
import Dialog from "../../abstract/Dialog/Dialog";

function SearchForm({ className, machines = [] }) {
    const wrapperRef = useRef(null);
    const [swalProps, setSwalProps] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [selectedMachine, setSelectedMachine] = useState(null);
    const [querystring, setQuerystring] = useState("");
    const [showResults, setShowResults] = useState(false);
    const [results, setResults] = useState([]);

    const searchProducts = async () => {
        setIsLoading(true);
        const response = await api.search(querystring, selectedMachine);
        if (response.ok) {
            // console.log(response);
            // console.log(response.dataitems);
            setResults(response.data.items);
            setShowResults(true);
        } else {
            setResults([]);
            setShowResults(false);
            setSwalProps({
                show: true,
                title: t("errorTitle", "Error"),
                text: t("errorSearchingForProducts", "Se ha producido un error en la búsqueda de productos"),
                icon: "error",
            });
        }
        setIsLoading(false);
    };

    const resetSearch = () => {
        setResults([]);
        setShowResults(false);
        setQuerystring("");
        // setSelectedMachine(null);
        setIsLoading(false);
        setSwalProps({});
    };

    const { pageData } = useVirtualPageHandler();

    useClickOutside(wrapperRef, resetSearch);

    useEffect(() => {
        resetSearch();
    }, [pageData]);

    return (
        <>
            {/* <div
                className="search-form-wrapper d-flex flex-grow-1"
                style={{ flexDirection: "column", width: "100%", maxWidth: "900px", position: "relative" }}
                ref={wrapperRef}
            > */}
            <div className={classnames(["search-form", className])}>
                <div className="search-input-group d-none d-md-flex">
                    <div className="d-flex flex-grow-1">
                        <div className="form-floating placeholder-shown">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t("searchInputPlaceholder", "Busca por código, producto...")}
                                value={querystring}
                                onChange={(e) => setQuerystring(e.target.value)}
                                onKeyUp={(e) => (e.key === "Enter" || e.keyCode === 13) && searchProducts()}
                            />
                            <label className="form-label" htmlFor="test">
                                {t("searchInputLabel", "Búsqueda flexible")}
                            </label>
                        </div>

                        {(machines.length && (
                            <>
                                <div className="vr"></div>
                                <div className="form-floating placeholder-shown">
                                    <select
                                        value={selectedMachine || ""}
                                        className="form-control"
                                        style={{ cursor: "pointer" }}
                                        onChange={(e) => setSelectedMachine(parseInt(e.target.value))}
                                    >
                                        <option>{t("searchLathePlaceholder", "Busca por torno")}</option>
                                        {machines.map((m, mIndex) => (
                                            <option key={`machine-${mIndex}`} value={m.trId}>
                                                {m.name}
                                            </option>
                                        ))}
                                    </select>
                                    <label className="form-label" htmlFor="test">
                                        {t("searchLatheLabel", "Modelo de máquina")}
                                    </label>
                                </div>
                            </>
                        )) ||
                            ""}
                    </div>
                    <Button iconBefore={<IconSearch />} onClick={searchProducts} isDisabled={isLoading || querystring.length < 1}>
                        {t("searchButtonLabel", "Buscar")}
                    </Button>
                </div>
                <div className="mobile-search-input-group d-md-none">
                    <label htmlFor="test" className="form-label visually-hidden">
                        Search
                    </label>
                    <div className="form-input-group input-type-text">
                        <div className="input-group__prefix">
                            <span>
                                <IconSearch />
                            </span>
                        </div>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={t("searchInputPlaceholder", "Busca por código, producto...")}
                            value={querystring}
                            onChange={(e) => setQuerystring(e.target.value)}
                            onKeyUp={(e) => (e.key === "Enter" || e.keyCode === 13) && searchProducts()}
                        />
                        <div className="fake-form-control"></div>
                    </div>
                </div>
            </div>

            {showResults && (
                <div className="search-form-results" ref={wrapperRef}>
                    {/* <div className="row g-3 g-md-4 row-cols-2 row-cols-sm-2 row-cols-md-4">
                        {(results.length &&
                            results.map((p, pIndex) => (
                                <div className="col">
                                    <CardProduct product={p} key={`search-result--${pIndex}`} />
                                </div>
                            ))) || (
                            <p>
                                {t(
                                    "noProductsMatchSearch",
                                    "Ningún producto coincide con la búsqueda que has realizado. Por favor, inténtalo de nuevo con otros términos de búsqueda."
                                )}
                            </p>
                        )}
                    </div> */}

                    {(results.length && (
                        <div className="row g-3 g-md-4 row-cols-2 row-cols-md-3 row-cols-lg-4">
                            {results.length &&
                                results.map((p, pIndex) => (
                                    <div className="col" key={`search-result--${pIndex}`}>
                                        <CardProduct product={p} />
                                    </div>
                                ))}
                        </div>
                    )) || (
                        <p className="text-center">
                            {t(
                                "noProductsMatchSearch",
                                "Ningún producto coincide con la búsqueda que has realizado. Por favor, inténtalo de nuevo con otros términos de búsqueda."
                            )}
                        </p>
                    )}
                </div>
            )}
            <Dialog onConfirm={() => setSwalProps({ show: false })} {...swalProps} />
        </>
    );
}

export default SearchForm;
