import classnames from "classnames";
import React from "react";

function IllustrationLogin({ className }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="152" height="140" fill="none" className={classnames([className])}>
            <circle cx="76" cy="60" r="52" fill="url(#a)" />
            <circle cx="21" cy="19" r="5" fill="#E1EAFA" />
            <circle cx="18" cy="109" r="7" fill="#E1EAFA" />
            <circle cx="144" cy="35" r="7" fill="#E1EAFA" />
            <circle cx="133" cy="8" r="4" fill="#E1EAFA" />
            <g filter="url(#b)">
                <path
                    fill="url(#c)"
                    d="M103.102 75.72h-7.477V51.022c0-10.78-8.78-19.55-19.574-19.55-10.793 0-19.574 8.77-19.574 19.55v24.7H49v-24.7C48.998 36.122 61.133 24 76.05 24c14.916 0 27.052 12.122 27.052 27.021v24.7Z"
                />
            </g>
            <g filter="url(#d)">
                <path
                    fill="#709BE6"
                    d="M109.288 64H42.712C41.214 64 40 65.297 40 66.897v46.206c0 1.6 1.214 2.897 2.712 2.897h66.576c1.498 0 2.712-1.297 2.712-2.897V66.897c0-1.6-1.214-2.897-2.712-2.897Z"
                />
            </g>
            <path
                fill="#F7F9FD"
                d="M112 113.122V85c-21.786 19.69-53.199 27.089-71 29.804.49.722 1.28 1.196 2.178 1.196h66.128c1.487 0 2.692-1.288 2.692-2.876l.002-.002Z"
                opacity=".2"
            />
            <path
                fill="#fff"
                d="M82.863 82.17a6.36 6.36 0 0 0-6.364-6.357 6.36 6.36 0 0 0-6.363 6.356 6.34 6.34 0 0 0 2.579 5.102l-2.025 14.372h11.617l-2.024-14.372a6.336 6.336 0 0 0 2.579-5.102h.001Z"
                opacity=".5"
            />
            <path fill="#C2D8FF" d="M42 69.36V79c.245-4.093 2.142-13 14-13H45.235C43.455 66 42 67.512 42 69.36Z" />
            <path fill="#F2F4F7" d="M70.691 101.641s8.623-2.434 9.593-14.372l2.025 14.372H70.69Z" />
            <defs>
                <linearGradient id="a" x1="76" x2="76" y1="8" y2="138.5" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#C2D8FF" />
                    <stop offset="1" stop-color="#C2D8FF" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="c" x1="76.55" x2="79.55" y1="31" y2="84.5" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#fff" />
                    <stop offset="1" stop-color="#fff" stop-opacity="0" />
                </linearGradient>
                <filter id="b" width="78.103" height="75.721" x="37" y="24" color-interpolation-filters="sRGB" filterUnits="userSpaceOnUse">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                    <feMorphology in="SourceAlpha" radius="2" result="effect1_dropShadow_408_996" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="3" />
                    <feColorMatrix values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0" />
                    <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_408_996" />
                    <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                    <feMorphology in="SourceAlpha" radius="4" result="effect2_dropShadow_408_996" />
                    <feOffset dy="12" />
                    <feGaussianBlur stdDeviation="8" />
                    <feColorMatrix values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0" />
                    <feBlend in2="effect1_dropShadow_408_996" result="effect2_dropShadow_408_996" />
                    <feBlend in="SourceGraphic" in2="effect2_dropShadow_408_996" result="shape" />
                </filter>
                <filter id="d" width="104" height="84" x="24" y="56" color-interpolation-filters="sRGB" filterUnits="userSpaceOnUse">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="3" />
                    <feColorMatrix values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0" />
                    <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_408_996" />
                    <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                    <feOffset dy="8" />
                    <feGaussianBlur stdDeviation="8" />
                    <feColorMatrix values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0" />
                    <feBlend in2="effect1_dropShadow_408_996" result="effect2_dropShadow_408_996" />
                    <feBlend in="SourceGraphic" in2="effect2_dropShadow_408_996" result="shape" />
                </filter>
            </defs>
        </svg>
    );
}

export default IllustrationLogin;
