import { useContext } from "react";
import jwtDecode from "jwt-decode";

import AuthContext from "./context";
//import authStorage from "./storage";

const useAuth = () => {
    const { user, setUser, userRoles, setUserRoles, userLoaded } = useContext(AuthContext);

    const canUseDOM = !!(typeof window !== "undefined" && window.document && window.document.createElement);

    const getLogoutUrl = () =>
        canUseDOM ? process.env.REACT_APP_AUTH_LOGOUT_URL + "?next=" + encodeURIComponent(window.location.href) : process.env.REACT_APP_AUTH_LOGOUT_URL;

    const getLoginUrl = (url = null) =>
        canUseDOM
            ? process.env.REACT_APP_AUTH_LOGIN_URL + "?next=" + encodeURIComponent(url || window.location.href)
            : process.env.REACT_APP_AUTH_LOGIN_URL + (url ? "?next=" + encodeURIComponent(url) : "");

    const getSignupUrl = (url = null) =>
        canUseDOM
            ? `${process.env.REACT_APP_AUTH_SIGNUP_URL ? process.env.REACT_APP_AUTH_SIGNUP_URL : process.env.REACT_APP_AUTH_LOGIN_URL}?next=` +
              encodeURIComponent(url || window.location.href)
            : process.env.REACT_APP_AUTH_SIGNUP_URL + (url ? "?next=" + encodeURIComponent(url) : "");

    const logIn = (authToken, userRoles) => {
        const user = jwtDecode(authToken);
        setUser(user);
        setUserRoles(userRoles);
        //authStorage.storeToken(authToken);
    };

    const logOut = () => {
        setUser(null);
        setUserRoles([]);
        //authStorage.removeToken();
    };

    const userCan = (permission, appSlug = "cmz-sales") => {
        if (!userRoles || !userRoles.length) {
            return false;
        }
        const appRole = userRoles.find((r) => r.application.slug === appSlug);
        if (appRole && appRole.permissions && appRole.permissions.length && appRole.permissions.find((p) => p === permission)) {
            return true;
        }
        return false;
    };

    return { user, userRoles, logIn, logOut, userCan, getLoginUrl, getLogoutUrl, getSignupUrl, userLoaded };
};

export default useAuth;
