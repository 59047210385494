import React from "react";
import BaseIconSVG from "./BaseIconSVG";

function IconFilterList(props) {
    return (
        <BaseIconSVG
            Icon={({ ...otherProps }) => (
                <svg {...otherProps}>
                    <path d="m0 0h24v24h-24z" fill="none" />
                    <path d="m11 18h2c.55 0 1-.45 1-1s-.45-1-1-1h-2c-.55 0-1 .45-1 1s.45 1 1 1zm-8-11c0 .55.45 1 1 1h16c.55 0 1-.45 1-1s-.45-1-1-1h-16c-.55 0-1 .45-1 1zm4 6h10c.55 0 1-.45 1-1s-.45-1-1-1h-10c-.55 0-1 .45-1 1s.45 1 1 1z" />
                </svg>
            )}
            {...props}
        />
    );
}

export default IconFilterList;
