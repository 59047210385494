import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import classNames from "classnames";
import ContentListPagination from "./Pagination/Pagination";
import ContentListContext from "./context";
import Button from "../../abstract/Button/Button";
import ContentListToolbar from "./Toolbar/Toolbar";
import Offcanvas from "../../abstract/Offcanvas/Offcanvas";
import { Link } from "react-router-dom";
import FormCheckbox from "../../forms/FormCheckbox/FormCheckbox";

function ContentList({
    className,
    gap = 3,
    cols = { default: 2, sm: 2, md: 3, xxxl: 4 },
    totalCount,
    nextPageLink,
    previousPageLink,
    page: currentPage = 1,
    itemsPerPage,
    children,
    filters,
    numFilters,
    orderBy,
    orderByOptions,
    resetFiltersUrl,
    search = "",
}) {
    const [items, setItems] = useState(children);
    const [endReach, setEndReach] = useState(false);
    const [resetList, setResetList] = useState(false);
    const [paginationState, setPaginationState] = useState({
        page: currentPage,
        itemsPerPage,
        totalCount,
        totalPages: itemsPerPage ? Math.ceil(totalCount / itemsPerPage) : 0,
        loading: false,
        nextPageLink,
        previousPageLink,
        orderBy,
        orderByOptions,
        search,
    });
    const [mobileFiltersOffcanvasShow, setMobileFiltersOffcanvasShow] = useState(false);
    const navigate = useNavigate();

    const toggleMobileFiltersOffCanvas = () => {
        setMobileFiltersOffcanvasShow(!mobileFiltersOffcanvasShow);
    };

    useEffect(() => {
        setItems(children);
    }, [children]);

    useEffect(() => {
        setEndReach(false);
        setResetList(true);
    }, [currentPage, itemsPerPage, currentPage, totalCount, nextPageLink, previousPageLink]);

    useEffect(() => {
        if (resetList) {
            setPaginationState({
                page: currentPage,
                itemsPerPage,
                totalCount,
                totalPages: itemsPerPage ? Math.ceil(totalCount / itemsPerPage) : 0,
                loading: false,
                nextPageLink,
                previousPageLink,
                orderBy,
                orderByOptions,
                search,
            });
        }
        setResetList(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetList]);

    return (
        <ContentListContext.Provider
            value={{
                resetList,
                endReach,
                setEndReach,
                items,
                setItems,
                numFilters,
                paginationState,
                setPaginationState,
                toggleMobileFiltersOffCanvas,
            }}
        >
            <ContentListToolbar />
            <div
                className={classNames([
                    "product-list",
                    "row",
                    parseInt(gap) ? `g-${parseInt(gap)}` : null,
                    Object.keys(cols).map((col) => (col === "default" ? `row-cols-${cols[col]}` : `row-cols-${col}-${cols[col]}`)),
                    className,
                ])}
            >
                {(items.length &&
                    items.map((item, itemIndex) => (
                        <div className="col" key={`content-list-item--${itemIndex}`}>
                            {item}
                        </div>
                    ))) || <div>{t("noResult", "No se han encontrado resultados que coincidan con tu búsqueda.")}</div>}
            </div>
            <ContentListPagination />
            <Offcanvas
                position="end"
                className="offcanvas-filters"
                show={mobileFiltersOffcanvasShow}
                onHide={toggleMobileFiltersOffCanvas}
                hideOnBackdropClick={false}
            >
                <Offcanvas.Header
                    title={t("filters", "Filters")}
                    subtitle={
                        numFilters > 0 ? (
                            <Button variant="link" size="small" className="ms-auto me-4" to={resetFiltersUrl}>
                                {t("removeFilters", "Eliminar filtros")}
                            </Button>
                        ) : null
                    }
                />
                <Offcanvas.Body className="px-4">
                    {filters &&
                        filters.map(
                            (filter, filterIndex) =>
                                (filter.items.length && (
                                    <div
                                        className="sidebar-filters"
                                        key={`advanced-filter-${filterIndex}`}
                                        // style={filterIndex === 0 ? { borderTop: "none" } : null}
                                    >
                                        <h3>{filter.name}</h3>

                                        <div className="form-check-options filter-options">
                                            {filter.items.map((filterItem, filterItemIndex) => (
                                                <React.Fragment key={`advanced-filter-${filterIndex}-option-${filterItemIndex}`}>
                                                    {(!filterItem.links.grouped.follow && (
                                                        <span style={{ cursor: "pointer" }} onClick={() => navigate(filterItem.links.grouped.url)}>
                                                            <FormCheckbox
                                                                label={filterItem.name}
                                                                size="medium"
                                                                state={filterItem.selected ? "checked" : "unchecked"}
                                                            />
                                                        </span>
                                                    )) || (
                                                        <Link to={filterItem.links.grouped.url} rel={!filterItem.links.grouped.follow ? "nofollow" : null}>
                                                            <FormCheckbox
                                                                label={filterItem.name}
                                                                size="medium"
                                                                state={filterItem.selected ? "checked" : "unchecked"}
                                                            />
                                                        </Link>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                        </div>
                                    </div>
                                )) ||
                                ""
                        )}
                </Offcanvas.Body>
                <Offcanvas.Footer>
                    {(totalCount > 0 && (
                        <Button color="secondary" size="large" className="w-100" onClick={toggleMobileFiltersOffCanvas}>
                            {totalCount === 1 ? t("viewProduct", "Ver producto") : t("viewXProducts", "Ver {{count}} productos", { count: totalCount })}
                        </Button>
                    )) || (
                        <Button isDisabled={true} color="secondary" size="large" className="w-100" onClick={toggleMobileFiltersOffCanvas}>
                            {t("noResults", "Sin Resultados")}
                        </Button>
                    )}
                </Offcanvas.Footer>
            </Offcanvas>
        </ContentListContext.Provider>
    );
}

export default ContentList;
