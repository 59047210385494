import React from "react";
import BaseIconSVG from "./BaseIconSVG";

function IconFacebook(props) {
    return (
        <BaseIconSVG
            Icon={({ ...otherProps }) => (
                <svg {...otherProps}>
                    <path d="m14.68 2c-2.82 0-4.47 1.49-4.47 4.88v2.98h-3.57v3.57h3.57v8.57h3.57v-8.57h2.86l.71-3.57h-3.57v-2.38c0-1.28.42-1.91 1.61-1.91h1.96v-3.42c-.34-.05-1.33-.15-2.68-.15z" />
                </svg>
            )}
            {...props}
        />
    );
}

export default IconFacebook;
