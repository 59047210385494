import React from "react";
import { t } from "i18next";
import { Trans } from "react-i18next";
import classNames from "classnames";

function ContentListToolbarCaption({ totalCount, className }) {
    return (
        <div className={classNames("toolbar__total-count fs-sm d-flex align-items-center text-muted", className)}>
            {(totalCount && (
                <span>
                    <Trans i18nKey="showingXXXProducts" totalCount={totalCount}>
                        Mostrando <span className="fw-medium">{{ totalCount }}</span> productos
                    </Trans>
                </span>
            )) ||
                (null !== totalCount && <span>{t("noResults", "Sin resultados")}</span>) || <span className="text-muted">{t("loading", "Cargando...")}</span>}
        </div>
    );
}

export default ContentListToolbarCaption;
