import React, { useEffect, useRef, useState } from "react";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import * as countriesApi from "../../../../api/countries";
import FormLabel from "../FormLabel";
import FormHelpText from "../FormHelpText";
import FormErrorMessage from "../FormErrorMessage";
import { getProperty } from "../../../../helpers/misc";
import { getCountryPrefix, getCountryTemplate } from "../../../../helpers/countries";
import CountryFlag from "../../../abstract/CountryFlag/CountryFlag";
import { IconCloseCircleFilled } from "../../icons";
import Dropdown from "../../Dropdown/Dropdown";

function FormikInputTel({
    countryId = null,
    label,
    labelHelp,
    id,
    name,
    size = "medium",
    required = false,
    validOnSubmit = false,
    validIfNoErrors = false,
    isClearable = false,
    helpText = null,
    className,
    classNameInput,
    autoComplete = false,
    disabled = false,
    ...otherProps
}) {
    // console.log("Executing Component...");
    const { setFieldTouched, setFieldValue, errors, touched, values, dirty, submitCount } = useFormikContext();
    const [dropdownOpen, dropdownOpenSet] = useState(false);
    const [countries, setCountries] = useState([]);
    // const [phoneCountry, setPhoneCountry] = useState(null);
    // const [unPrefixedNumber, setUnPrefixedNumber] = useState("");
    const phoneCountry = useRef(null);
    const unPrefixedNumber = useRef("");

    const setPhoneCountry = (c) => {
        phoneCountry.current = c;
    };
    const setUnPrefixedNumber = (n) => {
        unPrefixedNumber.current = n;
    };

    const loadCountries = async () => {
        const response = await countriesApi.getAll();
        if (response.ok) {
            const loadedCountryOptions = [];
            for (let country of response.data) {
                loadedCountryOptions.push({
                    id: country.id,
                    name: country.name,
                    code: country.code.toLowerCase(),
                    prefix: getCountryPrefix(country.code),
                    template: getCountryTemplate(country.code),
                });
            }
            setCountries(loadedCountryOptions);
        }
    };

    const getValue = () => {
        if (getProperty(name, values)) {
            if (!dirty) {
                let retVal = getProperty(name, values);
                //let changed = false;
                while (retVal.substring(0, 1) === "0") {
                    retVal = retVal.substring(1);
                    //changed = true;
                }
                // if (changed) {
                //     retVal = `+${retVal}`;
                //     setFieldValue(name, retVal);
                // }
                return retVal;
            }
            return getProperty(name, values);
        }

        return null;
    };

    // const setFormikFieldValue = (varName, varValue) => {
    //     const filteredNewValue = varValue.length ? (varValue[0] !== "+" ? "+" : "") + varValue.trim() : "";
    //     // console.log("[INPUT TEL] Old value: " + getProperty(varName, values));
    //     // console.log("[INPUT TEL] New value (Unfiltered): " + varValue);
    //     // console.log("[INPUT TEL] New value (Filtered): " + filteredNewValue);
    //     setFieldValue(varName, filteredNewValue);
    // };

    useEffect(() => {
        const fixValue = () => {
            if (getProperty(name, values)) {
                if (!dirty) {
                    let retVal = getProperty(name, values);
                    let changed = false;
                    while (retVal.substring(0, 1) === "0") {
                        retVal = retVal.substring(1);
                        changed = true;
                    }
                    if (changed) {
                        retVal = `+${retVal}`;
                        setFieldValue(name, retVal);
                        //setFormikFieldValue(name, retVal);
                    }
                }
            }
        };

        loadCountries();
        fixValue();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!getValue() && countries.length && countryId) {
            let foundCountry = countries.find((c) => c.id === countryId);
            if (foundCountry) {
                setPhoneCountry(foundCountry);
                changeFormikValue();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countries, countryId]);

    const onChange = (e) => {
        const dirtyFieldValue = e.currentTarget.value;
        updateValue(dirtyFieldValue);
    };

    const updateValue = (dirtyFieldValue, defaultCountryId) => {
        //console.log("Updating value", { dirtyFieldValue, defaultCountryId });
        const fieldValue = dirtyFieldValue.replace(/\s/g, "");
        let newCountry = defaultCountryId ? countries.find((c) => c.id === defaultCountryId) : null;
        let newUnprefixedNumber = dirtyFieldValue;

        // Detect prefix.
        if ("+" === fieldValue.trim().substr(0, 1)) {
            for (let prefixLength of [1, 2, 3, 4, 5]) {
                let newPrefix = fieldValue.trim().substr(1, prefixLength);
                let newCountryCandidate = countries.find((c) => c.prefix === newPrefix);
                if (newCountryCandidate) {
                    newCountry = newCountryCandidate;
                    newUnprefixedNumber = dirtyFieldValue.replace(`+${newCountry.prefix}`, ""); /*fieldValue.trim().substr(prefixLength + 1).trim();*/
                    //setPhoneCountry(newCountryCandidate);
                    // setUnPrefixedNumber(fieldValue.trim().substr(prefixLength+1).trim());
                    break;
                }
            }
        }

        setPhoneCountry(newCountry);
        setUnPrefixedNumber(newCountry ? newUnprefixedNumber.replace("+", "") : newUnprefixedNumber);
        changeFormikValue();
        // if (newUnprefixedNumber.length) {
        //     setFieldValue(name, `${newCountry && newCountry.prefix ? `+${newCountry.prefix}` : ""}${newUnprefixedNumber}`);
        // }
        // else {
        //     setFieldValue(name, "");
        // }
    };

    const changeFormikValue = () => {
        //  console.log(`CHANGING FORMIK VALUE for ${name}!!!`, { phoneCountry, unPrefixedNumber });
        if (unPrefixedNumber.current.length) {
            setFieldValue(name, `${phoneCountry.current && phoneCountry.current.prefix ? `+${phoneCountry.current.prefix}` : ""}${unPrefixedNumber.current}`);
        } else {
            setFieldValue(name, "");
        }
    };

    // useEffect(() => {
    //     console.log("internal vars changed, CHANGING FORMIK VALUE!!!", { phoneCountry, unPrefixedNumber });
    //     if (unPrefixedNumber.current.length) {
    //         setFieldValue(name, `${phoneCountry.current && phoneCountry.current.prefix ? `+${phoneCountry.current.prefix}` : ""}${unPrefixedNumber.current}`);
    //     } else {
    //         setFieldValue(name, "");
    //     }
    // }, [phoneCountry, unPrefixedNumber]);

    const getPlaceholder = () => {
        if (phoneCountry.current && phoneCountry.current.template) {
            const chars = [];
            let numberIndex = 0;
            for (let char of phoneCountry.current.template) {
                if ("." === char) {
                    chars.push([1, 2, 3, 4, 5, 6, 7, 8, 9, 0][numberIndex++]);
                    if (numberIndex >= [1, 2, 3, 4, 5, 6, 7, 8, 9, 0].length) {
                        numberIndex = 0;
                    }
                } else {
                    chars.push(char);
                }
            }
            return chars.join("");
        }

        return "";
    };

    const getOutputValue = () => `${phoneCountry.current && phoneCountry.current.prefix ? `+${phoneCountry.current.prefix}` : ""}${unPrefixedNumber.current}`;
    const getCalculatedValue = () =>
        (unPrefixedNumber.current.length &&
            `${phoneCountry.current && phoneCountry.current.prefix ? `+${phoneCountry.current.prefix}` : ""}${unPrefixedNumber.current}`) ||
        "";

    const handleClearClick = () => {
        if (!isClearable) {
            return;
        }

        setFieldValue(name, "");
        setPhoneCountry(null);
        setUnPrefixedNumber("");
        changeFormikValue();
    };

    const hasError = () =>
        errors && (getProperty(name, touched) || submitCount) && getProperty(name, errors) && "string" === typeof getProperty(name, errors) ? true : false;
    const showValid = () => (errors && ((submitCount && validOnSubmit) || validIfNoErrors) && !getProperty(name, errors) ? true : false);

    useEffect(() => {
        // *** Detect value changes
        if (getValue() !== getCalculatedValue() && (getValue() || getCalculatedValue())) {
            //console.log(`value changed for ${name}`, { new: getValue(), calculated: getCalculatedValue() });
            // console.log(`Outside Value Change for ${name}. Value: ${getValue()} || Calculated: ${getCalculatedValue()}`);
            updateValue(getValue() || "", countryId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    return (
        <div className={["form-group", ...[className || ""], ...(hasError() ? ["is-invalid"] : []), ...(showValid() ? ["is-valid"] : [])].join(" ")}>
            <FormLabel id={id} required={required} labelHelp={labelHelp}>
                {label}
            </FormLabel>
            <div className={["form-input-group", "form-input-tel", ...(disabled ? ["is-disabled"] : [])].join(" ")}>
                <div className="input-group__prefix">
                    <Dropdown
                        size="small"
                        align="start"
                        buttonAnchor={
                            (!phoneCountry.current && <div className="input-tel-placeholder">{/* <IconPhone /> */}</div>) || (
                                <CountryFlag countryCode={phoneCountry.current.code} />
                            )
                        }
                        setShow={dropdownOpenSet}
                        show={dropdownOpen}
                    >
                        <nav className="currency-selector-nav">
                            <ul className="list-unstyled mb-0">
                                {countries.map((c, i) => (
                                    <li key={`country-tel-${i}`}>
                                        <button
                                            className="dropdown-item"
                                            onClick={() => {
                                                setPhoneCountry({ ...c });
                                                changeFormikValue();
                                            }}
                                        >
                                            <div className="d-flex align-items-center gap-2">
                                                <CountryFlag countryCode={c.code} />
                                                <span>{c.name}</span>
                                                <span className="text-muted">+{c.prefix}</span>
                                            </div>
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </Dropdown>
                </div>
                <input
                    type="text"
                    className={[
                        "form-control",
                        "ps-0",
                        ...[classNameInput || ""],
                        ...(hasError() ? ["is-invalid"] : []),
                        ...(showValid() ? ["is-valid"] : []),
                        ...[size === "small" ? "form-control--sm" : ""],
                        ...[size === "large" ? "form-control--lg" : ""],
                        // ...[variant === "filled" ? "form-control--filled" : ""],
                    ].join(" ")}
                    id={id}
                    name={name}
                    autoComplete={(!autoComplete && "off") || autoComplete}
                    disabled={disabled}
                    onBlur={() => setFieldTouched(name, true, false)}
                    onChange={onChange}
                    placeholder={getPlaceholder()}
                    value={getOutputValue()}
                    {...otherProps}
                />
                {(isClearable && getValue() !== "" && (
                    <div className="input-group__suffix">
                        <div className="btn btn-clear" onClick={handleClearClick}>
                            <IconCloseCircleFilled />
                        </div>
                    </div>
                )) ||
                    ""}
                <div className="fake-form-control"></div>
            </div>

            {(helpText && (
                <FormHelpText>
                    <span className="help-text">{helpText}</span>
                </FormHelpText>
            )) ||
                ""}

            <FormErrorMessage visible={hasError()}>{getProperty(name, errors)}</FormErrorMessage>
        </div>
    );
}

FormikInputTel.propTypes = {
    countryId: PropTypes.number,
    label: PropTypes.string,
    labelHelp: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    size: PropTypes.oneOf(["small", "medium", "large"]),
    required: PropTypes.bool,
    validOnSubmit: PropTypes.bool,
    validIfNoErrors: PropTypes.bool,
    isClearable: PropTypes.bool,
    helpText: PropTypes.string,
    // variant: PropTypes.oneOf(["outlined", "filled"]),
    className: PropTypes.string,
    classNameInput: PropTypes.string,
    autoComplete: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default FormikInputTel;
