import React, { useState, useEffect } from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { BrowserRouter as Router } from "react-router-dom";

import * as authApi from "./api/auth";
import * as cartApi from "./api/cart";
import AuthContext from "./contexts/auth/context";
import LocalizationContext from "./contexts/localization/context";
import localizationStorage from "./contexts/localization/storage";
import history from "./ui-events/history";
import translationsEs from "./i18n/translations/es.json";
import translationsEn from "./i18n/translations/en.json";
import translationsFr from "./i18n/translations/fr.json";
import translationsDe from "./i18n/translations/de.json";
import translationsIt from "./i18n/translations/it.json";
import AppRoutes from "./AppRoutes";
import ShoppingCartContext from "./contexts/shoppingCart/context";
import ShoppingCartModal from "./components/modals/ShoppingCartModal/ShoppingCartModal";
import ShoppingCartOffcanvas from "./components/offcanvas/ShoppingCartOffcanvas/ShoppingCartOffcanvas";
import cartStorage from "./contexts/shoppingCart/storage";
import MainSearchContext from "./contexts/mainSearch/context";
import AnalyticsContext from "./contexts/analytics/context";
import { QueryClient, QueryClientProvider } from "react-query";

function App() {
    const retrieveLanguage = () => {
        if (!window || !window.location) {
            return null;
        }
        const pathname = window.location.pathname;
        const language = pathname.split("/")[1];

        return language || localizationStorage.getStoredLanguage() || navigator.language || navigator.userLanguage || "en";
    };
    const [user, setUser] = useState(null);
    const [userLoaded, setUserLoaded] = useState(false);
    const [userRoles, setUserRoles] = useState([]);
    const [currentLanguage, setCurrentLanguage] = useState(retrieveLanguage());
    const [availableLanguages, setAvailableLanguages] = useState(["en", "es", "fr", "de", "it"]);

    // *** Shopping Cart
    const [isCartLoading, isCartLoadingSet] = useState(true);
    const [endpoints, endpointsSet] = useState({});
    const [currency, currencySet] = useState(null);
    const [currencies, currenciesSet] = useState([]);
    const [checkoutData, setCheckoutData] = useState({});
    const [lines, linesSet] = useState([]);
    const [numItems, numItemsSet] = useState(0);
    const [offCanvasOpen, offCanvasOpenSet] = useState(false);
    const [modalOpen, modalOpenSet] = useState(false);

    // *** Main Search
    const [mainSearchModalOpen, mainSearchModalOpenSet] = useState(false);
    const [machines, machinesSet] = useState([]);

    const restoreLanguage = () => {
        let lang = retrieveLanguage();
        if (!lang) {
            let lang = localizationStorage.getStoredLanguage() || navigator.language || navigator.userLanguage || "en";
            lang = lang.substring(0, 2);
            if (!availableLanguages.find((l) => l === lang)) {
                lang = availableLanguages.find((l) => true);
            }

            setCurrentLanguage(lang);
        }
        localizationStorage.storeLanguage(lang);
        i18n.use(initReactI18next).init({
            resources: {
                es: { translation: translationsEs },
                en: { translation: translationsEn },
                fr: { translation: translationsFr },
                de: { translation: translationsDe },
                it: { translation: translationsIt },
            },
            lng: lang,
            fallbackLng: "es",
            interpolation: { escapeValue: false },
        });
    };

    if (!window["_CMZStoreLangInit"]) {
        window["_CMZStoreLangInit"] = true;
        restoreLanguage();
    }

    const restoreUser = async () => {
        const response = await authApi.me();
        if (response.ok) {
            const rolesResponse = await authApi.myRoles();
            if (rolesResponse.ok) {
                setUser(response.data);
                setUserRoles(rolesResponse.data);
                setUserLoaded(true);
                return true;
            }
        }
        setUserLoaded(true);

        return false;
    };

    const updateCartData = (data) => {
        if (!data) {
            return;
        }

        if (data.cartLines) {
            linesSet(data.cartLines);
        }
        if (data.endpoints) {
            endpointsSet(data.endpoints);
        }
        const checkoutData = {
            accountPositionId: data.accountPositionId || null,
            allowPartialShipping: data.allowPartialShipping || false,
            partialShippingCandidate: data.partialShippingCandidate || null,
            shippingPackages: data.shippingPackages || [],
            billingAddress: data.billingAddress || null,
            billingAddressIncorrect: data.billingAddressIncorrect || false,
            paymentGateway: data.paymentGateway || null,
            shippingAddress: data.shippingAddress || null,
            shippingService: data.shippingService || null,
            shippingComments: data.shippingComments || "",
            customOrderNumber: data.customOrderNumber || "",
        };

        setCheckoutData(checkoutData);
    };

    const createCart = async () => {
        const result = await cartApi.post();
        if (result.ok && result.data) {
            cartStorage.storeKey(result.data);
            const cartResult = await cartApi.get();
            if (cartResult.ok && cartResult.data) {
                updateCartData(cartResult.data);
                if (cartResult.data.cartLines) {
                    linesSet(cartResult.data.cartLines);
                }
                if (cartResult.data.endpoints) {
                    endpointsSet(cartResult.data.endpoints);
                }
            }
        }
    };

    const restoreCart = async () => {
        // console.log("Restoring Cart.");
        if (cartStorage.getKey()) {
            isCartLoadingSet(true);
            const result = await cartApi.get();
            if (result.ok && result.data) {
                updateCartData(result.data);
                if (result.data.cartLines) {
                    linesSet(result.data.cartLines);
                }
                if (result.data.endpoints) {
                    endpointsSet(result.data.endpoints);
                }
            } else {
                // console.log("Cant restore cart. Creating new one.");
                await createCart();
            }
            isCartLoadingSet(false);
        }
    };

    const loadCurrencies = async () => {
        const selectedCurrencyCode = cartStorage.getCurrency();
        const result = await cartApi.getCurrencies();
        if (result.ok && result.data && result.data.length) {
            const loadedCurrencies = result.data;
            currenciesSet(loadedCurrencies);
            let selectedCurrency = loadedCurrencies.find((c) => (selectedCurrencyCode ? c.code === selectedCurrencyCode : c.isEtalon));
            if (!selectedCurrency) {
                selectedCurrency = loadedCurrencies.find((c) => c.isEtalon);
            }
            if (selectedCurrency) {
                currencySet(selectedCurrency);
                cartStorage.storeCurrency(selectedCurrency.code);
            }
        }
    };

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                retry: false,
                staleTime: 10 * 1000, // 10s
            },
        },
    });

    useEffect(() => {
        const initApp = async () => {
            restoreUser();
            restoreCart();
            loadCurrencies();
        };
        initApp();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let count = 0;
        for (let line of lines) {
            count += line.quantity;
        }
        numItemsSet(count);
    }, [lines]);

    return (
        <QueryClientProvider client={queryClient}>
            <AnalyticsContext.Provider value={{ gtmId: "GTM-PGTMN6P", uxId: "CMZ-7467" }}>
                <LocalizationContext.Provider value={{ currentLanguage, setCurrentLanguage, availableLanguages, setAvailableLanguages }}>
                    <AuthContext.Provider value={{ user, setUser, userRoles, setUserRoles, userLoaded }}>
                        <Router history={history}>
                            <ShoppingCartContext.Provider
                                value={{
                                    updateCartData,
                                    checkoutData,
                                    setCheckoutData,
                                    currency,
                                    currencySet,
                                    currencies,
                                    currenciesSet,
                                    lines,
                                    linesSet,
                                    numItems,
                                    numItemsSet,
                                    offCanvasOpen,
                                    offCanvasOpenSet,
                                    modalOpen,
                                    modalOpenSet,
                                    endpoints,
                                    endpointsSet,
                                    isCartLoading,
                                    isCartLoadingSet,
                                }}
                            >
                                <MainSearchContext.Provider
                                    value={{ modalOpen: mainSearchModalOpen, modalOpenSet: mainSearchModalOpenSet, machines, machinesSet }}
                                >
                                    <AppRoutes />
                                    <ShoppingCartOffcanvas />
                                    <ShoppingCartModal />
                                </MainSearchContext.Provider>
                            </ShoppingCartContext.Provider>
                        </Router>
                    </AuthContext.Provider>
                </LocalizationContext.Provider>
            </AnalyticsContext.Provider>
        </QueryClientProvider>
    );
}

export default App;
