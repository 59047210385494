import React, { useContext, useEffect, useState } from "react";
import { t } from "i18next";
import ContentListContext from "../context";
import Button from "../../../abstract/Button/Button";
import Counter from "../../../abstract/Counter/Counter";
import { IconFilterList } from "../../../abstract/icons";
import ContentListToolbarCaption from "./ToolbarCaption";
import ContentListToolbarSearch from "./ToolbarSearch";
import ContentListToolbarOrder from "./ToolbarOrder";
import "./Toolbar.scss";

function ContentListToolbar() {
    const { paginationState, setPaginationState, resetList, toggleMobileFiltersOffCanvas, numFilters } = useContext(ContentListContext);
    const [searchValue, setSearchValue] = useState(paginationState.search);

    useEffect(() => {
        if (resetList) {
            setSearchValue("");
        }
    }, [resetList]);

    const submitSearch = () => setPaginationState({ ...paginationState, search: searchValue });
    const submitOrderBy = (newOrderSlug) => setPaginationState({ ...paginationState, orderBy: newOrderSlug });

    return (
        <div className="mb-4">
            <div className="toolbar d-flex mb-3">
                {/* <div className="d-none d-md-flex">
                    <ContentListToolbarCaption totalCount={paginationState.totalCount} />
                </div> */}

                <ContentListToolbarSearch searchValue={searchValue} setSearchValue={setSearchValue} submitSearch={submitSearch} />

                {(paginationState.orderByOptions.length && (
                    <ContentListToolbarOrder
                        orderByOptions={paginationState.orderByOptions}
                        selectedOrderSlug={paginationState.orderBy}
                        submitOrderBy={submitOrderBy}
                    />
                )) ||
                    ""}
                <div className="mobile-filters-toggle d-flex d-lg-none flex-grow-1 justify-content-end">
                    <Button
                        onClick={toggleMobileFiltersOffCanvas}
                        variant="outline"
                        color="secondary"
                        size="small"
                        iconBefore={<IconFilterList />}
                        className="border"
                    >
                        {t("filters", "Filtros")}

                        {(numFilters > 0 && (
                            <Counter color="primary" className="ms-1">
                                {numFilters}
                            </Counter>
                        )) ||
                            ""}
                    </Button>
                </div>
            </div>
            {/* <div className="d-flex d-md-none flex-column mt-2">
                <ContentListToolbarCaption totalCount={paginationState.totalCount} />
            </div> */}

            <ContentListToolbarCaption totalCount={paginationState.totalCount} />
        </div>
    );
}

export default ContentListToolbar;
