import React, { useEffect, useState } from "react";
import { t } from "i18next";
import useShoppingCart from "../../../contexts/shoppingCart/useShoppingCart";
import Button from "../../abstract/Button/Button";
import Offcanvas from "../../abstract/Offcanvas/Offcanvas";
import CartLine from "../../concret/CartLine/CartLine";
import CurrencyFormat from "../../concret/CurrencyFormat/CurrencyFormat";
import "./ShoppingCartOffcanvas.scss";
import useLocalization from "../../../contexts/localization/useLocalization";
import EmptyCart from "../../concret/EmptyCart/EmptyCart";
import useLayout from "../../../contexts/layout/useLayout";
import Modal from "../../abstract/Modal/Modal";
import { Trans } from "react-i18next";
import { IconInfo } from "../../abstract/icons";

function ShoppingCartOffcanvas() {
    const { lines, offCanvasOpen, closeCartOffCanvas, numItems, getItemsTaxBase, getItemsTotal, getItemsTotalTax, getCartUrl, restoreCart } = useShoppingCart();
    const { currentLanguage } = useLocalization();
    const [showTaxesModal, setShowTaxesModal] = useState(false);

    useEffect(() => {
        restoreCart();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLanguage]);

    return (
        <>
            <Offcanvas className="offcanvas-shopping-cart" show={offCanvasOpen} onHide={closeCartOffCanvas}>
                <Offcanvas.Header
                    title={t("shoppingCartOffcanvasTitle", "Mi carrito ({{cartItemsCount}})", {
                        cartItemsCount: numItems,
                    })}
                />
                <Offcanvas.Body>
                    {(numItems && (
                        <div className="cart-list">
                            {lines.map((line, lineIndex) => (
                                <CartLine {...line} lineKey={line.key} key={line.key || `cart-line-${lineIndex}`} />
                            ))}
                        </div>
                    )) || <EmptyCart />}
                </Offcanvas.Body>
                {(numItems && (
                    <Offcanvas.Footer>
                        <div className="cart-total fs-sm">
                            <div className="cart-total__line">
                                <span className="label">{t("subtotal", "Subtotal")}</span>
                                <span className="value">
                                    <CurrencyFormat value={getItemsTaxBase()} />
                                </span>
                            </div>
                            <div className="cart-total__line">
                                <span className="label">{t("shippingCosts", "Gastos de envío")}</span>
                                <span className="value">{t("calculatedUponCheckout", "Calculados en el checkout")}</span>
                            </div>
                            <div className="cart-total__line">
                                <span className="label">{t("taxes", "Impuestos")}</span>
                                <span className="value">
                                    <span className="value">{t("calculatedUponCheckout", "Calculados en el checkout")}</span>
                                    {/* <CurrencyFormat value={getItemsTotalTax()} /> */}
                                </span>
                            </div>
                            {/* {(getItemsTotalTax() > 0 && (
                                <div style={{ textAlign: "right" }}>
                                    <Button variant="link" size="small" iconBefore={<IconInfo />} onClick={() => setShowTaxesModal(true)}>
                                        {t("whyISeeTaxesOnCartTitle", "¿Por qué se me aplican impuestos?")}
                                    </Button>
                                </div>
                            )) ||
                                ""} */}
                            <div className="cart-total__line cart-total__line--grand-total gap-3">
                                <span className="label">
                                    {t("total", "TOTAL")}
                                    <span className="d-flex fs-xs text-muted fw-normal">
                                        {t("NoTaxesIncludedText", "Impuestos y gastos de envío no incluidos")}
                                    </span>
                                </span>
                                <span className="value">
                                    <CurrencyFormat value={getItemsTaxBase()} />
                                    {/* <CurrencyFormat value={getItemsTotal()} /> */}
                                </span>
                            </div>
                            <Button to={getCartUrl()} size="large" className="w-100" onClick={closeCartOffCanvas}>
                                {t("goToCart", "Ir al Carrito")}
                            </Button>
                        </div>
                    </Offcanvas.Footer>
                )) ||
                    ""}
            </Offcanvas>
            <Modal show={showTaxesModal} onHide={() => setShowTaxesModal(false)}>
                <Modal.Header title={t("whyISeeTaxesOnCartTitle", "¿Por qué se me aplican impuestos?")} />
                <Modal.Body>
                    <Trans i18nKey="whyISeeTaxesOnCartDescription">
                        <p>
                            Mostramos los impuestos correspondientes durante el proceso de compra <strong>a modo informativo</strong>.
                        </p>
                        <p>
                            Una vez selecciones tu dirección de facturación en la pantalla de <strong>Finalizar Compra</strong> se aplicarán, en el caso de que
                            proceda, los impuestos correspondientes.
                        </p>
                    </Trans>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ShoppingCartOffcanvas;
