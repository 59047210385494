import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import logoCMZ from "./images/logo-cmz-store-w.svg";

import logoCMZDark from "./images/logo-cmz-store.svg";

import "./SplashScreen.scss";
import classNames from "classnames";

function SplashScreen({ show = true, light = false }) {
    const [isHidding, setIsHidding] = useState(false);
    const [isHidden, setIsHidden] = useState(false);

    const animationTime = 600;

    useEffect(() => {
        if (show) {
            setIsHidden(false);
            setIsHidding(false);
        } else {
            setIsHidding(true);
            setIsHidden(false);
            window.setTimeout(() => {
                setIsHidden(true);
                setIsHidding(false);
            }, animationTime);
        }
    }, [show]);

    return (
        <>
            <div
                className={classNames("splashscreen", isHidding ? "splashscreen-hidding" : null, light ? "splashscreen-light" : null, isHidden ? "hidden" : "")}
            >
                <div className="fade-in-image">
                    <img alt="CMZ" src={light ? logoCMZDark : logoCMZ} width="130" />
                </div>
            </div>
        </>
    );
}

SplashScreen.propTypes = {
    show: PropTypes.bool,
};

export default SplashScreen;
