import React from "react";
import BaseIconSVG from "./BaseIconSVG";

function IconBankTransfer(props) {
    return (
        <BaseIconSVG
            Icon={({ ...otherProps }) => (
                <svg {...otherProps}>
                    <path d="m0 0h24v24h-24z" fill="none" />
                    <path d="m12.89 11.1c-1.78-.59-2.64-.96-2.64-1.9 0-1.02 1.11-1.39 1.81-1.39 1.31 0 1.79.99 1.9 1.34l1.58-.67c-.15-.45-.82-1.92-2.54-2.24v-1.24h-2v1.26c-2.48.56-2.49 2.86-2.49 2.96 0 2.27 2.25 2.91 3.35 3.31 1.58.56 2.28 1.07 2.28 2.03 0 1.13-1.05 1.61-1.98 1.61-1.82 0-2.34-1.87-2.4-2.09l-1.66.67c.63 2.19 2.28 2.78 2.9 2.96v1.29h2v-1.24c.4-.09 2.9-.59 2.9-3.22 0-1.39-.61-2.61-3.01-3.44zm-9.89 9.9h-2v-6h6v2h-2.48c1.61 2.41 4.36 4 7.48 4 4.97 0 9-4.03 9-9h2c0 6.08-4.92 11-11 11-3.72 0-7.01-1.85-9-4.67zm-2-9c0-6.08 4.92-11 11-11 3.72 0 7.01 1.85 9 4.67v-2.67h2v6h-6v-2h2.48c-1.61-2.41-4.36-4-7.48-4-4.97 0-9 4.03-9 9z" />
                </svg>
            )}
            {...props}
        />
    );
}

export default IconBankTransfer;
